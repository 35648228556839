import React from "react"
import { ModalBody } from "reactstrap"
import { BaseModal, IModalBase, IModalControlProps, ModalFooter, ModalFooterButton } from "swiipe.portal.shared"
import "./ModalDialog.scss"

export interface IModalDialog extends IModalBase {
    type: "dialog"
    message: string
    cancelBtn: ModalFooterButton
    confirmBtn: ModalFooterButton
}

const ModalError = ({ title, message, cancelBtn, confirmBtn, callback, ...otherProps }: IModalDialog & IModalControlProps) => {
    return (
        <BaseModal {...otherProps} modalClassName="dialog-modal" callback={callback} noBordersInside centered title={title}>
            <ModalBody>
                <div>
                    <p className="mb-0 text-center">{message}</p>
                </div>
            </ModalBody>
            <ModalFooter
                leftButton={{ ...cancelBtn, onClick: async () => callback("declined") }}
                rightButton={{ ...confirmBtn, onClick: async () => callback("accepted") }}
            />
        </BaseModal>
    )
}

export default ModalError
