import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import {
    IMerchantOnboardingServicesSubForm,
    IMerchantPreOnboarding,
    IMerchantPreOnboardingEvent,
    IMerchantPreOnboardingInternalUser,
    IMerchantPreOnboardingOwner,
    getPagesCount,
    getRecordsForPageNumber,
    prepareFormData,
    requestThunk,
} from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { IMerchantPreOnboardingForm } from "../../services/merchantPreOnboardingService"
import { StoreState } from "../StoreState"
import { merchantPreOnboardingActions, merchantPreOnboardingSelectors } from "../reducers/merchantPreOnboardingSlice"

export const getMerchantPreOnboardingsThunk =
    (pageNumber: number, perPage: number, force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentPreOnboardings = merchantPreOnboardingSelectors.preOnboardings(getState())
        const currentForPage = getRecordsForPageNumber(pageNumber, perPage, currentPreOnboardings)

        const continuationToken = merchantPreOnboardingSelectors.continuationToken(getState())
        const pagesCount = getPagesCount(perPage, currentPreOnboardings)

        const shouldFetchNew = !!continuationToken && pagesCount === pageNumber

        if (currentForPage && !force && !shouldFetchNew) {
            return
        }

        const filters = merchantPreOnboardingSelectors.filters(getState())

        const sorting = {
            sortByProperty: filters.sortByProperty,
            isOrderByDescending: filters.isOrderByDescending,
        }

        const res = await dispatch(
            requestThunk<{
                preOnboardings: IMerchantPreOnboarding[]
                pagesCount: number
                continuationToken: string
            }>(endpoints.Identity.getMerchantPreOnboardings, {
                params: {
                    perPage,
                    fetchPagesCount: pageNumber == 1 || pagesCount === pageNumber ? null : pageNumber,
                    sorting: sorting,
                    continuationToken: shouldFetchNew ? continuationToken : null,
                    filterShowOnlyOwn: filters.onlyMyPreOnboardings,
                    filterShowOnlyAssignedMe: filters.onlyAssignedToMe,
                    filterStatus: filters.status,
                    sortByProperty: filters.sortByProperty,
                    isOrderByDescending: filters.isOrderByDescending,
                },
            })
        )

        dispatch(
            merchantPreOnboardingActions.setMerchantPreOnboardings({
                preOnboardings: res.preOnboardings.map((po) => ({
                    ...po,
                    servicesParsed: po.servicesJson ?? "{}",
                })),
                continuationToken: res.continuationToken,
            })
        )
        res.preOnboardings.forEach((p) => {
            dispatch(
                refetchMerchantPreOnboardingDataThunk({
                    type: "onlyIfAlreadyFetched",
                    preOnboardingId: p.preOnboardingId,
                    swMerchantId: p.swMerchantId,
                })
            )
        })
    }

export const saveDraftMerchantPreOnboardingsThunk =
    (data: {
        merchantName: string
        preOnboardingId?: string
        estimatedValue: number
        estimatedValueCurrency: string
        draftData: string
    }): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(
            requestThunk<{
                preOnboardings: IMerchantPreOnboarding[]
                pagesCount: number
                continuationToken: string
            }>(endpoints.Identity.saveDraftMerchantPreOnboarding, {
                data: {
                    ...data,
                },
            })
        )
        await dispatch(
            refetchMerchantPreOnboardingDataThunk({
                type: "onlyIfAlreadyFetched",
                preOnboardingId: data.preOnboardingId,
            })
        )
    }

export const removeMerchantPreOnboardingThunk =
    (preOnboardingId: string): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(
            requestThunk(endpoints.Identity.removeMerchantPreOnboarding, {
                params: {
                    preOnboardingId,
                },
            })
        )
    }

export const createMerchantPreOnboardingThunk =
    (
        form: IMerchantPreOnboardingForm,
        preOnboardingId: string | undefined
    ): ThunkAction<
        Promise<{ swMerchantId: string; isExistingMerchant: boolean; webshopIds: string[] }>,
        StoreState,
        null,
        AnyAction
    > =>
    async (dispatch, getState) => {
        const preparedForm = prepareFormData(form, [])
        const res = await dispatch(
            requestThunk<{ swMerchantId: string; isExistingMerchant: boolean; webshopIds: string[] }>(
                endpoints.Identity.preOnboardMerchant,
                {
                    data: {
                        preOnboardingId: preOnboardingId,
                        userDetails: {
                            email: preparedForm.contact.email,
                            firstName: preparedForm.contact.firstName,
                            lastName: preparedForm.contact.lastName,
                            phone: preparedForm.contact.phone,
                            country: preparedForm.company.country,
                            language: preparedForm.language,
                        },
                        webshops: form.webshops.map((w) => ({ hostname: w.domain, platform: w.platform, mcc: w.mcc })),
                        companyDetails: {
                            ...form.company,
                        },
                        companyRegistrationNumber: preparedForm.company.companyNumber,
                        merchantKycMessage: preparedForm.merchantKycMessage,
                        merchantEmailMessage: preparedForm.merchantEmailMessage,
                        merchantOfferMessage: preparedForm.merchantOfferMessage,

                        estimatedValue: form.turnover.monthly,
                        estimatedValueCurrency: form.turnover.transactionsCurrency,
                        servicesJson: JSON.stringify(form.services),
                        requiresAcquiring: form.services.useAcquiringClearhaus,
                    },
                }
            )
        )
        await dispatch(
            refetchMerchantPreOnboardingDataThunk({
                type: "onlyIfAlreadyFetched",
                preOnboardingId: preOnboardingId,
                swMerchantId: res.swMerchantId,
            })
        )
        return res
    }

export const getMerchantPreOnboardingDetailsThunk =
    (props: {
        preOnboardingId?: string
        swMerchantId?: string
        force?: "force"
    }): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const preOnboardingDetails = props.preOnboardingId
            ? merchantPreOnboardingSelectors.details(getState(), props.preOnboardingId)
            : merchantPreOnboardingSelectors.detailsBySwMerchantId(getState(), props.swMerchantId ?? "")

        if (preOnboardingDetails && props.force !== "force") {
            return
        }

        const response = await dispatch(
            requestThunk<
                | {
                      preOnboarding: IMerchantPreOnboarding
                      creator: IMerchantPreOnboardingInternalUser
                      assigned?: IMerchantPreOnboardingInternalUser
                      owner: IMerchantPreOnboardingOwner
                      events: IMerchantPreOnboardingEvent[]
                  }
                | undefined
            >(endpoints.Identity.getMerchantPreOnboardingDetails, {
                params: {
                    merchantPreOnboardingId: props.preOnboardingId,
                    swMerchantId: props.swMerchantId,
                },
            })
        )

        if (!response) {
            return
        }

        dispatch(
            merchantPreOnboardingActions.setMerchantPreOnboardingDetails({
                preOnboardingId: response.preOnboarding.preOnboardingId,
                swMerchantId: response.preOnboarding.swMerchantId,
                preOnboarding: response.preOnboarding,
                creator: response.creator,
                assigned: response.assigned,
                owner: response.owner,
                events: response.events,
                services: JSON.parse(response.preOnboarding.servicesJson ?? "{}") as IMerchantOnboardingServicesSubForm,
            })
        )
    }

const refetchMerchantPreOnboardingDataThunk =
    (data: {
        preOnboardingId?: string
        swMerchantId?: string
        type: "onlyIfAlreadyFetched" | "alwaysRefetch"
    }): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (data.swMerchantId) {
            const details = merchantPreOnboardingSelectors.detailsBySwMerchantId(getState(), data.swMerchantId)
            if (details || data.type === "alwaysRefetch") {
                await dispatch(
                    getMerchantPreOnboardingDetailsThunk({
                        swMerchantId: data.swMerchantId,
                        preOnboardingId: details?.preOnboarding?.preOnboardingId,
                        force: "force",
                    })
                )
                return
            }
        }
        if (data.preOnboardingId) {
            const details = merchantPreOnboardingSelectors.details(getState(), data.preOnboardingId)
            if (details || data.type === "alwaysRefetch") {
                await dispatch(
                    getMerchantPreOnboardingDetailsThunk({
                        swMerchantId: details?.preOnboarding.swMerchantId,
                        preOnboardingId: data.preOnboardingId,
                        force: "force",
                    })
                )
                return
            }
        }
    }
