import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import {
    ISharedServerConfiguration,
    requestThunk,
    sharedConfigurationReducerActions,
    sharedConfigurationSelectors,
} from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { authService } from "../../services/authService"
import { navigationService } from "../../services/navigationService"
import { IUserRelationFlattened } from "../../type/user/IUserRelation"
import { StoreState } from "../StoreState"
import { userRelationReducerActions } from "../reducers/userRelationReducer"
import { checkCookieThunk } from "./loginThunks"
import { fetchMerchantDataThunk } from "./merchantThunks"
import { addModalThunk } from "./modalThunks"
import { ensurePartnerDataThunk } from "./partnerThunks"
import {
    addLastUsedRelationThunk,
    ensureSwitchUserRelationsFromUrl,
    ensureUserRelationsThunk,
    fetchRelationSearchData,
} from "./userRelationThunks"
import { fetchUserDataThunk } from "./userThunks"

export const initializeThunk = (): ThunkAction<void, StoreState, null, AnyAction> => async (dispatch, getState) => {
    const serverConfiguration = await dispatch(requestThunk<ISharedServerConfiguration>(endpoints.Self.getConfiguration))

    dispatch(sharedConfigurationReducerActions.setServerConfiguration(serverConfiguration))

    const authEndpoint = sharedConfigurationSelectors.endpointConfig(getState(), "Auth")

    authService.setup(serverConfiguration, authEndpoint)

    if (navigationService.tvMode) {
        return
    }
    const authorized = await dispatch(checkCookieThunk())
    authService.setIsLoggedIn(authorized)
    if (authorized) {
        dispatch(fetchUserDataThunk(false))
        authService.setAuthChecked(false)
        await dispatch(ensureUserRelationsThunk())

        const didSwitchUser = await dispatch(ensureSwitchUserRelationsFromUrl())

        dispatch(sharedConfigurationReducerActions.setInitialized(true))

        if (!didSwitchUser) {
            dispatch(onUserSwitchThunk())
        }

        dispatch(fetchRelationSearchData(false))
    } else {
        dispatch(sharedConfigurationReducerActions.setInitialized(true))
    }
}

export const signOutThunk = (): ThunkAction<Promise<void>, StoreState, null, AnyAction> => async (dispatch, getState) => {
    const answer = await dispatch(addModalThunk({ type: "logOut" }))
    if (answer.type !== "accepted") {
        return
    }
    const authEndpoint = sharedConfigurationSelectors.endpointConfig(getState(), "Auth")
    const serverConfiguration = sharedConfigurationSelectors.serverConfiguration(getState())
    window.location.replace(authEndpoint + "/Account/Logout" + "?=" + serverConfiguration.clientUrl + "/login")
}

export const switchUserThunk =
    (userRelation: IUserRelationFlattened): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        dispatch(userRelationReducerActions.setCurrentRelation(userRelation))
        dispatch(addLastUsedRelationThunk(userRelation))
        dispatch(onUserSwitchThunk())
    }

const onUserSwitchThunk = (): ThunkAction<void, StoreState, null, AnyAction> => async (dispatch, getState) => {
    dispatch(fetchUserDataThunk(false))
    dispatch(fetchMerchantDataThunk(false))
    dispatch(ensurePartnerDataThunk(false))
}
