import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import { FloatingLabelInput, useSelector } from "swiipe.portal.shared"
import ShowErrorMessages from "../../components/form/ShowErrorMessages"
import { ITimeTrackCounterState, getElapsedTimeForTimeTrackInMs } from "../../services/timeTrackService"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import { addOrUpdateTrackedTimeThunk } from "../../store/thunks/trackedTimeThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { ETrackedTimeType, allTrackedTimeTypes, defaultTrackedTimeType } from "../../type/trackedTime/EtrackedTimeType"
import { IGetTrackedTimeDto } from "../../type/trackedTime/IGetTrackedTimeDto"
import { addHours, addMinutes, addYears, formatShortDateWithFormat, parseDateShortFormat } from "../../util/dateUtil"
import { valFuncRequired } from "../../util/validationUtil"
import SubmitButton from "../buttons/SubmitButton"
import DateInput from "../form/input/DateInput"
import DropDownList from "../form/input/DropDownList"
import WebshopSelect from "../merchant/WebshopSelect"
import "./TrackedTimeEdit.scss"

interface ITrackedTimeEdit {
    organizationId: string
    updateExisting?: IGetTrackedTimeDto
    initWithState?: ITimeTrackCounterState
    triggerSubmit?: boolean
    hideSubmitButton?: boolean
    onSubmitted?: () => void
    initialSelectedWebshopId?: string
}

interface ITrackedTimeEditForm {
    date: string
    comment: string
    hours: number
    minutes: number
    type: ETrackedTimeType
}

function formatToShortDateStr(dateStr: string | undefined) {
    if (!dateStr) {
        return ""
    }
    return formatShortDateWithFormat(new Date(dateStr), "yyyy-MM-dd")
}

const TrackedTimeEdit = ({
    organizationId,
    updateExisting,
    triggerSubmit,
    hideSubmitButton,
    onSubmitted,
    initWithState,
    initialSelectedWebshopId,
}: ITrackedTimeEdit) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const { handleSubmit, register, errors, formState, setValue, control } = useForm<ITrackedTimeEditForm>()

    const defaultAllWebshopId = "all"
    const allRelations = useSelector(userRelationSelectors.userRelationsFlattened)
    const usedRelation = allRelations?.find((r) => r.id === organizationId)

    useEffect(() => {
        if (updateExisting) {
            setValue("date", formatToShortDateStr(updateExisting.startTime))
            setValue("hours", Math.floor(updateExisting.seconds / (60 * 60)))
            setValue("minutes", Math.floor((updateExisting.seconds / 60) % 60))
            setValue("comment", updateExisting.comment)
            setValue("type", updateExisting.type)
        } else {
            const seconds = initWithState ? Math.floor(getElapsedTimeForTimeTrackInMs(initWithState) / 1000) : 0
            setValue("date", formatToShortDateStr(new Date().toISOString()))
            setValue("hours", Math.floor(seconds / (60 * 60)))
            setValue("minutes", Math.floor((seconds / 60) % 60))
            setValue("comment", "")
            setValue("type", defaultTrackedTimeType)
        }
    }, [updateExisting, initWithState])

    useEffect(() => {
        if (triggerSubmit) {
            handleSubmit(onSubmit)()
        }
    }, [triggerSubmit])

    const swMerchant = useSelector(merchantSelectors.merchantDetails)
    const [selectedWebshopId, setSelectedWebshopId] = useState(
        initialSelectedWebshopId ?? swMerchant?.webshops[0].webshopId ?? defaultAllWebshopId
    )

    useEffect(() => {
        console.log(selectedWebshopId)
    }, [selectedWebshopId])

    const onSubmit: SubmitHandler<ITrackedTimeEditForm> = async (data, e) => {
        let startTime = data.date
        let endTime = addMinutes(addHours(parseDateShortFormat(data.date) ?? new Date(), data.hours), data.minutes).toISOString()
        let eventsStr = ""
        let newSeconds = data.hours * 3600 + data.minutes * 60

        const roundSecondsToWholeMinutes = (seconds: number) => {
            return Math.floor(seconds / 60) * 60
        }

        if (updateExisting?.startTime && formatToShortDateStr(updateExisting?.startTime) === data.date) {
            startTime = updateExisting.startTime
            endTime =
                newSeconds === roundSecondsToWholeMinutes(updateExisting?.seconds ?? 0)
                    ? updateExisting.endTime
                    : addMinutes(addHours(new Date(updateExisting.startTime), data.hours), data.minutes).toISOString()
            if (endTime === updateExisting.endTime) {
                newSeconds = Math.floor((new Date(endTime).getTime() - new Date(startTime).getTime()) / 1000)
                eventsStr = updateExisting.eventsData
            }
        }
        if (initWithState?.startTime && initWithState.endTime && formatToShortDateStr(initWithState.startTime) === data.date) {
            startTime = initWithState.startTime
            const stateSeconds = Math.floor(getElapsedTimeForTimeTrackInMs(initWithState) / 1000)
            endTime =
                newSeconds === roundSecondsToWholeMinutes(stateSeconds ?? 0)
                    ? initWithState.endTime
                    : addMinutes(addHours(new Date(initWithState.startTime), data.hours), data.minutes).toISOString()
            if (endTime === initWithState.endTime) {
                newSeconds = stateSeconds
                eventsStr = JSON.stringify(initWithState.breaks)
            }
        }

        try {
            await dispatch(
                addOrUpdateTrackedTimeThunk({
                    comment: data.comment,
                    startTime: startTime,
                    endTime: endTime,
                    eventsData: eventsStr,
                    type: data.type,
                    organizationId: organizationId,
                    id: updateExisting?.id,
                    seconds: newSeconds,
                    webshopId: selectedWebshopId === defaultAllWebshopId ? undefined : selectedWebshopId,
                })
            )
        } catch (err) {
            // Catch to stop showing spinner
        }
        onSubmitted?.()
    }

    return (
        <div className="tracked-time-edit">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <h4>{usedRelation?.name}</h4>
                <div className="input-container">
                    {"Date"}
                    <DateInput
                        name="date"
                        required
                        formControl={control}
                        minDate={addYears(new Date(), -100)}
                        maxDate={new Date()}
                    />
                </div>
                <div className="input-container multiple">
                    <div>
                        {"Hours"}
                        <FloatingLabelInput
                            name="hours"
                            defaultValue={0}
                            innerRef={register(valFuncRequired("Hours is required"))}
                            className="input"
                            type="number"
                        />
                    </div>
                    <div>
                        {"Minutes"}
                        <FloatingLabelInput
                            name="minutes"
                            defaultValue={0}
                            innerRef={register(valFuncRequired("Minutes is required"))}
                            className="input"
                            type="number"
                        />
                    </div>
                </div>
                <div className="input-container mt-3">
                    {"Type"}
                    <DropDownList
                        className="mb-1"
                        options={allTrackedTimeTypes.map((v) => ({ text: v, value: v }))}
                        name="type"
                        innerRef={register}
                        defaultValue={defaultTrackedTimeType}
                    ></DropDownList>
                </div>
                {swMerchant && (
                    <div className="input-container mt-3">
                        {"Select webshop"}
                        <WebshopSelect
                            className="mb-1"
                            skipDataDispatch
                            hideTitle
                            merchantDetails={swMerchant}
                            onWebshopChange={(selectedWebshopId: string) => {
                                setSelectedWebshopId(selectedWebshopId)
                            }}
                            initialWebshopId={selectedWebshopId ?? defaultAllWebshopId}
                            customOptions={[
                                {
                                    text: "All",
                                    value: defaultAllWebshopId,
                                },
                            ]}
                        />
                    </div>
                )}
                <div className="input-container mt-3">
                    {"Comment"}
                    <FloatingLabelInput name="comment" innerRef={register()} className="input" type="textarea" multiple />
                </div>
                <ShowErrorMessages<ITrackedTimeEditForm> errors={errors} />
                {!hideSubmitButton && (
                    <div className="buttons">
                        <div className="save-delete">
                            <SubmitButton
                                disabled={!formState.isDirty}
                                dark
                                isBlue
                                noBorder
                                noBorderRadius
                                formState={formState}
                                containerClass="delete-button"
                            >
                                Add
                            </SubmitButton>
                        </div>
                    </div>
                )}
            </Form>
        </div>
    )
}

export default TrackedTimeEdit
