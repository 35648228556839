import { getKeys } from "./objectUtils"

export function getUrlSchemeAndHost() {
    return document.location.protocol + "//" + document.location.host
}

export function getQueryValueFromCurrentUrl(queryToLookFor: string) {
    const pairs = (document.location.search ?? "").replace("?", "").split("&")
    for (let i = 0; i < pairs.length; i++) {
        const values = pairs[i].split("=")
        if (values[0] === queryToLookFor) {
            return values.length > 1 ? values[1] : ""
        }
    }
    return ""
}

export function getCurrentUrlPath(includeSchemeAndHost: boolean, queryParams?: { [key: string]: string }) {
    const queryString = !queryParams
        ? ""
        : Object.keys(queryParams).reduce((qStr, key) => {
              const part = key + "=" + queryParams[key]
              return qStr.length === 0 ? part : qStr + "&" + part
          }, "")

    return (
        (includeSchemeAndHost ? getUrlSchemeAndHost() : "") +
        document.location.pathname +
        (queryString.length === 0 ? "" : "?" + queryString)
    )
}

export function removeQueryValueFromCurrentUrl(queryToDelete: string) {
    const query = new URLSearchParams(document.location.search)
    query.delete(queryToDelete)
    const updatedQuery = !query.toString() ? "" : "?" + query.toString()
    window.history.replaceState(null, document.title, getUrlSchemeAndHost() + document.location.pathname + updatedQuery)
}

export function removeOriginFromUrl(urlStr: string) {
    const url = new URL(urlStr)
    return `${url.pathname}${url.search}${url.hash}`
}

export function addQueryParamsToUrl(
    url: string,
    params: { [key: string]: string | number | boolean | undefined },
    removeExistingParams = false
) {
    const query = removeExistingParams
        ? new URLSearchParams()
        : new URLSearchParams(new URL(url.startsWith("http") ? url : "https://dummy.com" + url).search)
    getKeys(params).forEach((key) => {
        query.delete(key + "")
        if (typeof params[key] !== "undefined") {
            query.append(key + "", params[key] + "")
        }
    })
    const updatedQuery = !query.toString() ? "" : "?" + query.toString()
    return url.split(/\?|#/)[0] + updatedQuery
}
