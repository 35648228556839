import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { Form } from "reactstrap"
import { FloatingLabelInput, PageWrapper, useSelector } from "swiipe.portal.shared"
import SubmitButton from "../../components/buttons/SubmitButton"
import FieldsetFormGroup from "../../components/form/FieldsetFormGroup"
import ShowErrorMessages from "../../components/form/ShowErrorMessages"
import WebshopSelect from "../../components/merchant/WebshopSelect"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { recalculateOrderThunk } from "../../store/thunks/merchantThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { valFuncRequired } from "../../util/validationUtil"
import "./OrderRecalculationPage.scss"

interface IOrderRecalculationPageProps {}

interface IFormModel {
    orderId: string
}

const OrderRecalculationPage = ({}: IOrderRecalculationPageProps) => {
    const dispatch = useReduxDispatch()

    const { handleSubmit, register, errors, formState } = useForm<IFormModel>()

    useRequiredUserRelations([{ requiredType: "Merchant" }])

    const merchantDetails = useSelector(merchantSelectors.merchantDetails)

    const [selectedWebshopId, setSelectedWebshopId] = useState("")

    if (!merchantDetails) {
        return null
    }

    const onSubmit: SubmitHandler<IFormModel> = async (data) => {
        try {
            await dispatch(recalculateOrderThunk(data.orderId, selectedWebshopId))
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    return (
        <>
            <WebshopSelect
                merchantDetails={merchantDetails}
                onWebshopChange={(selectedWebshopId: string) => setSelectedWebshopId(selectedWebshopId)}
            />

            <PageWrapper disableMinHeight className="recalculate-page-wrapper">
                <div className="recalculate-form-container">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <FieldsetFormGroup field>
                            <FloatingLabelInput
                                name="orderId"
                                innerRef={register(valFuncRequired("Please enter order id"))}
                                placeholder="Order Id"
                            />
                        </FieldsetFormGroup>
                        <ShowErrorMessages<IFormModel> errors={errors} />
                        <SubmitButton className="btn-submit" dark noBorder noBorderRadius formState={formState}>
                            Recalculate
                        </SubmitButton>
                    </Form>
                </div>
            </PageWrapper>
        </>
    )
}

export default OrderRecalculationPage
