import cn from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { PageContainer, PageWrapper, getPlatformColor, getPlatformShortName, useSelector } from "swiipe.portal.shared"
import SpinnerContainer from "../../components/buttons/SpinnerContainer"
import { testResultSelectors } from "../../store/reducers/testResultReducer"
import { fetchTestRunsThunk } from "../../store/thunks/testResultsThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { TPlatformValue } from "../../type/merchant/webshopdetails"
import { ITestRun } from "../../type/testResults/ITestRun"
import { sortAscending, sortDescending } from "../../util/arrayUtil"
import { getTimeSinceShortString, isMoreThanMinuteOld } from "../../util/dateUtil"
import "./TestRunDashboardPage.scss"

interface ITestRunDashboardPageProps {
    isTvMode?: boolean
}

const TestRunDashboardPage = ({ isTvMode }: ITestRunDashboardPageProps) => {
    const dispatch = useReduxDispatch()
    const [forceUpdate, setForceUpdate] = useState(0)
    const updateTimeout = useRef<NodeJS.Timeout | undefined>(undefined)

    const testRuns = useSelector(testResultSelectors.testRuns)

    useEffect(() => {
        dispatch(fetchTestRunsThunk(isTvMode ?? false, true))

        const interval = setInterval(() => {
            dispatch(fetchTestRunsThunk(isTvMode ?? false, true))
        }, 1000 * 60)
        return () => {
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        const updatePerSecond = testRuns?.find((tr) => !isMoreThanMinuteOld(new Date(tr.created)))
        if (updateTimeout.current) {
            clearTimeout(updateTimeout.current)
        }
        updateTimeout.current = setTimeout(
            () => {
                setForceUpdate(forceUpdate + 1)
            },
            updatePerSecond ? 1000 : 1000 * 60
        )
    })

    const byCategories = (testRuns ?? []).reduce<ITestRun[][]>((res, testRun) => {
        let cat = res.find((cat) => cat[0].category === testRun.category)
        if (!cat) {
            cat = []
            res.push(cat)
        }
        cat.push(testRun)
        return res
    }, [])

    return (
        <PageWrapper fullWidth>
            <PageContainer id="test-run-dashboard-page">
                <SpinnerContainer showSpinner={!testRuns}>
                    <div className="outer">
                        {sortAscending(byCategories, (cat) => cat[0].name).map((cat) => (
                            <div key={cat[0].category} className="category">
                                <h2>{cat[0].category}</h2>
                                {sortDescending(
                                    cat ?? [],
                                    (tr) => (tr.buildFailed || tr.percentSuccess !== 100 ? "B" : "A") + tr.created
                                ).map((tr, index) => (
                                    <TestRun key={index} testRun={tr} />
                                ))}
                            </div>
                        ))}
                    </div>
                </SpinnerContainer>
            </PageContainer>
        </PageWrapper>
    )
}

interface ITestRunProps {
    testRun: ITestRun
}
const TestRun = ({ testRun }: ITestRunProps) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div
            className={cn("test-run-container", { failed: testRun.buildFailed || testRun.percentSuccess !== 100, open: isOpen })}
        >
            <div className="closed-content" onClick={() => setIsOpen(!isOpen)}>
                <div className="time">{getTimeSinceShortString(new Date(testRun.created))}</div>
                <div className="name">{testRun.name}</div>
                <div
                    className="platform"
                    style={{ backgroundColor: getPlatformColor(testRun.platform as TPlatformValue) ?? "white" }}
                >
                    {getPlatformShortName(testRun.platform as TPlatformValue) ?? testRun.platform}
                </div>
                <div className="space"></div>
                <div className="reason">{testRun.buildFailed ? "BUILD FAILED" : testRun.passed + "/" + testRun.executed}</div>
                <div className="percent">{testRun.percentSuccess}%</div>
            </div>
            {isOpen && (
                <div className="open-content">
                    <TestRunDetailsProperty id="percentSuccess" name="Percent success" value={testRun.percentSuccess + ""} />
                    <TestRunDetailsProperty id="buildFailed" name="Build failed" value={testRun.buildFailed ? "Yes" : "No"} />
                    <div className="column-container">
                        <div className="column">
                            <TestRunDetailsProperty id="total" name="Total" value={testRun.total + ""} />
                            <TestRunDetailsProperty id="executed" name="Executed" value={testRun.executed + ""} />
                            <TestRunDetailsProperty id="passed" name="Passed" value={testRun.passed + ""} />
                            <TestRunDetailsProperty id="failed" name="Failed" value={testRun.failed + ""} />
                        </div>
                        <div className="column">
                            <TestRunDetailsProperty
                                id="failFromError"
                                name="Fail from error"
                                value={testRun.failFromError + ""}
                            />
                            <TestRunDetailsProperty
                                id="failFromTimeout"
                                name="Fail from timeout"
                                value={testRun.failFromTimeout + ""}
                            />
                            <TestRunDetailsProperty
                                id="failFromAborted"
                                name="Fail from aborted"
                                value={testRun.failFromAborted + ""}
                            />
                        </div>
                    </div>
                    <TestRunDetailsProperty id="subject" name="Subject" value={testRun.subject} />
                    <TestRunDetailsProperty id="platform" name="Platform" value={testRun.platform} />
                    <TestRunDetailsProperty id="environment" name="Environment" value={testRun.environment} />
                    {testRun.buildUrl && (
                        <TestRunDetailsProperty
                            id="buildUrl"
                            name="Build Details Url"
                            value={testRun.buildUrl}
                            isLink
                            linkText="Show"
                        />
                    )}
                </div>
            )}
        </div>
    )
}

interface ITestRunDetailsPropertyProps {
    id: string
    name: string
    value: string
    isLink?: boolean
    linkText?: string
}
const TestRunDetailsProperty = ({ id, name, value, isLink, linkText }: ITestRunDetailsPropertyProps) => {
    return (
        <div className={cn(id, "test-run-details-property")}>
            <div className="name">{name}</div>
            {!isLink && <div className="value">{value}</div>}
            {isLink && (
                <a href={value ?? ""} target="_blank" rel="noreferrer" className="value">
                    {linkText ?? value}
                </a>
            )}
        </div>
    )
}

export default TestRunDashboardPage
