import { getCulture, hasValue, sortDescending } from "swiipe.portal.shared"
import { padZero } from "./numberUtils"

export const formatDate = (d: Date, type: TDateFormatType) => {
    if (type === "verbose-culture" || type === "verbose-culture-with-time") {
        return d.toLocaleDateString(getCulture(), {
            year: "numeric",
            month: "long",
            day: "2-digit",
            ...(type === "verbose-culture-with-time" && {
                hour: "numeric",
                minute: "2-digit",
                second: "2-digit",
            }),
        })
    }

    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d)
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d)
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d)

    const hh = new Intl.DateTimeFormat("en", { hour: "2-digit", hour12: false }).format(d)
    const mm = ("0" + new Intl.DateTimeFormat("en", { minute: "2-digit" }).format(d).toString()).slice(-2)
    const ss = ("0" + new Intl.DateTimeFormat("en", { second: "2-digit" }).format(d).toString()).slice(-2)

    switch (type) {
        case "dots":
            return `${da}.${mo}.${ye} ${hh}:${mm}`
        case "slashes":
            return `${da}/${mo}/${ye} ${hh}:${mm}`
        case "dash-international":
            return `${ye}-${mo}-${da} ${hh}:${mm}`
        case "forfile":
            return `${da}.${mo}.${ye}`
        case "time-detailed":
            return `${hh}:${mm}:${ss}`
    }
}

export const getFormattedDate = (date: string | Date | undefined, type: TDateFormatType) =>
    !date ? "" : formatDate(new Date(date), type)

export type TDateFormatType =
    | "dots"
    | "slashes"
    | "forfile"
    | "dash-international"
    | "time-detailed"
    | "verbose-culture"
    | "verbose-culture-with-time"

export function getTimeUntilShortString(date: Date) {
    const secondsUntil = Math.ceil((date.getTime() - new Date().getTime()) / 1000)

    if (secondsUntil < 60) {
        return secondsUntil + "s"
    }
    if (secondsUntil < 60 * 60) {
        return Math.floor(secondsUntil / 60) + "m"
    }
    if (secondsUntil < 60 * 60 * 24) {
        return Math.floor(secondsUntil / (60 * 60)) + "t"
    }

    return Math.floor(secondsUntil / (60 * 60 * 24)) + "d"
}

export function getTimeSinceShortString(date: Date) {
    const secondsSince = Math.ceil((new Date().getTime() - date.getTime()) / 1000)

    if (secondsSince < 60) {
        return secondsSince + "s"
    }
    if (secondsSince < 60 * 60) {
        return Math.floor(secondsSince / 60) + "m"
    }
    if (secondsSince < 60 * 60 * 24) {
        return Math.floor(secondsSince / (60 * 60)) + "t"
    }

    return Math.floor(secondsSince / (60 * 60 * 24)) + "d"
}

export function getDaysSince(date: Date, sinceDate: Date) {
    const secondsSince = Math.ceil((date.getTime() - sinceDate.getTime()) / 1000)
    return Math.floor(secondsSince / (60 * 60 * 24))
}

export function isMoreThanMinuteOld(date: Date) {
    const secondsSince = Math.floor((new Date().getTime() - date.getTime()) / 1000)
    return secondsSince >= 60
}

export function addMinutes(date: Date, minutes: number) {
    date = new Date(date.valueOf())
    date.setUTCMinutes(date.getUTCMinutes() + minutes)
    return date
}

export function addHours(date: Date, hours: number) {
    date = new Date(date.valueOf())
    date.setUTCHours(date.getUTCHours() + hours)
    return date
}

export function addDays(date: Date, days: number) {
    date = new Date(date.valueOf())
    date.setUTCDate(date.getUTCDate() + days)
    return date
}

export function addMonths(date: Date, months: number) {
    date = new Date(date.valueOf())
    date.setUTCMonth(date.getUTCMonth() + months)
    return date
}

export function addYears(date: Date, years: number) {
    date = new Date(date.valueOf())
    date.setUTCFullYear(date.getUTCFullYear() + years)
    return date
}

export function formatShortDateWithFormat(date: Date, format: string) {
    const dayOfMonth = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    let result = format
    if (format.includes("dd")) {
        result = result.replace("dd", padZero(dayOfMonth, 2))
    }
    if (format.includes("d")) {
        result = result.replace("d", dayOfMonth + "")
    }
    if (format.includes("MM")) {
        result = result.replace("MM", padZero(month, 2))
    }
    if (format.includes("M")) {
        result = result.replace("M", month + "")
    }
    if (format.includes("yyyy")) {
        result = result.replace("yyyy", year + "")
    }
    if (format.includes("yy")) {
        result = result.replace("yy", (year + "").substring(2, 4))
    }
    return result
}

let cachedStringDateFormat: string | undefined = undefined
export function getStringLocaleDateFormat(): string {
    if (cachedStringDateFormat) {
        return cachedStringDateFormat
    }

    let testDate = new Date(2031, 5, 7).toLocaleDateString(getCulture())
    testDate = testDate.replace("2031", "yyyy")
    testDate = testDate.replace("31", "yy")
    testDate = testDate.replace("06", "MM")
    testDate = testDate.replace("6", "M")
    testDate = testDate.replace("07", "dd")
    testDate = testDate.replace("7", "d")

    cachedStringDateFormat = testDate

    return testDate
}

export function parseDateShortFormat(date: string) {
    if (!date) {
        return undefined
    }
    const parts = date.split("-")
    return new Date(Date.UTC(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2])))
}

export function setTime(targetDate: Date, fromDate: Date) {
    if (!fromDate || !targetDate) {
        return targetDate
    }

    const newDate = new Date(targetDate.toISOString())
    newDate.setUTCHours(fromDate.getUTCHours())
    newDate.setUTCMinutes(fromDate.getUTCMinutes())
    newDate.setUTCSeconds(fromDate.getUTCSeconds())
    newDate.setUTCMilliseconds(fromDate.getUTCMilliseconds())
    return newDate
}

export function getLatestDate(dates: (string | undefined | Date)[]): Date | undefined {
    const resolvedDates = dates.map((d) => (!d ? undefined : typeof d === "string" ? new Date(d) : d)).filter(hasValue)
    if (resolvedDates.length === 0) {
        return undefined
    }
    return sortDescending(resolvedDates, (d) => d.getTime())[0]
}

export function formatLocalDate(dateOrStr: Date | string | undefined, dateFormat: "full" | "long" | "medium" | "short") {
    const date = typeof dateOrStr === "string" ? new Date(dateOrStr) : dateOrStr
    if (!date) {
        return ""
    }
    return date.toLocaleDateString(getCulture(), {
        dateStyle: dateFormat,
    })
}
