import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { StoreState } from "../StoreState"
import { quickpayDataReducerActions, quickpayDataSelectors } from "../reducers/quickpayReducer"

export const getQuickpayMerchantDataForWebshopThunk =
    (merchantId: string, webshopId: string, force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!merchantId || !webshopId) return

        const quickpayData = quickpayDataSelectors.getQuickpayData(getState(), merchantId, webshopId)

        if (quickpayData && !force) {
            return
        }

        const response = await dispatch(
            requestThunk<{
                merchantId?: string
            }>(endpoints.Quickpay.getOnboardingDataForWebshop(webshopId))
        )

        dispatch(
            quickpayDataReducerActions.extendQuickpayDataForMerchant(
                {
                    webshopId,
                    qpMerchantId: response.merchantId,
                },
                merchantId
            )
        )
    }

interface IRegisterQuickpayMerchantRequest {
    swMerchantId: string
    webshopId: string
    clearhausApiKey: string
}

export const registerQuickpayMerchant =
    (requestData: IRegisterQuickpayMerchantRequest): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const response = await dispatch(
            requestThunk<{
                merchantId: string
            }>(endpoints.Quickpay.registerMerchant, {
                data: requestData,
            })
        )
        dispatch(
            quickpayDataReducerActions.extendQuickpayDataForMerchant(
                { webshopId: requestData.webshopId, qpMerchantId: response.merchantId },
                requestData.swMerchantId
            )
        )
    }

interface IDeleteQuickpayMerchantRequest {
    swMerchantId: string
    webshopId: string
}

export const deleteQuickpayMerchant =
    (requestData: IDeleteQuickpayMerchantRequest): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const response = await dispatch(
            requestThunk<void>(endpoints.Quickpay.deleteMerchant, {
                params: requestData,
            })
        )
        dispatch(
            quickpayDataReducerActions.extendQuickpayDataForMerchant(
                { webshopId: requestData.webshopId, qpMerchantId: undefined },
                requestData.swMerchantId
            )
        )
    }
