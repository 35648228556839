import { getEntries } from "../util/objectUtils"

export interface ITimeTrackBreakEvent {
    type: ETimeTrackBreakEventType
    time: string
}

type ETimeTrackBreakEventType = "start" | "stop"

export interface ITimeTrackCounterState {
    organizationId?: string
    startTime?: string
    endTime?: string
    breaks: ITimeTrackBreakEvent[]
}

export function getElapsedTimeForTimeTrackInMs(state: ITimeTrackCounterState | undefined) {
    if (!state?.startTime) {
        return 0
    }

    let totalTime = 0

    let currentTime = state.startTime
    let currentType: ETimeTrackBreakEventType = "start"
    state.breaks.forEach((evt) => {
        if (currentType === evt.type) {
            return
        }
        if (evt.type === "stop") {
            const extraTime = new Date(evt.time).getTime() - new Date(currentTime).getTime()
            if (extraTime > 0) {
                totalTime += extraTime
            }
            currentType = "stop"
            currentTime = evt.time
        }
        if (evt.type === "start") {
            currentType = "start"
            currentTime = evt.time
        }
    })
    if (currentType === "start") {
        const endTime = state.endTime ? new Date(state.endTime) : new Date()
        const extraTime = endTime.getTime() - new Date(currentTime).getTime()
        if (extraTime > 0) {
            totalTime += extraTime
        }
    }
    return totalTime
}

export const formatTrackedTime = (seconds: number) => {
    const timeString = getEntries({
        hours: Math.floor(seconds / 3600),
        minutes: Math.floor((seconds / 60) % 60),
        seconds: Math.floor(seconds % 60),
    })
        .map((timeEntry) => (timeEntry.value ? timeEntry.value + timeEntry.key[0] : undefined))
        .filter((timeEntry) => timeEntry)
    return timeString.join(" ") || "0s"
}
