import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk } from "swiipe.portal.shared"
import { CredentialsFormModel } from "../../components/merchant/CredentialsForm"
import { endpoints } from "../../data/endpoints"
import { Credentials } from "../../type/merchant/Credentials"
import { StoreState } from "../StoreState"
import { credentialsReducerActions, credentialsSelectors } from "../reducers/credentialsReducer"

export const fetchCredentialsThunk =
    (swMerchantId: string, webshopId: string, force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentCredentials = credentialsSelectors.credentials(getState(), webshopId)

        if (currentCredentials && !force) {
            return
        }

        const credentials = await dispatch(
            requestThunk<{ credentials: Credentials[] }>(endpoints.Content.getCredentials, {
                params: {
                    swMerchantId: swMerchantId,
                    webshopId: webshopId,
                },
            })
        )

        dispatch(credentialsReducerActions.setCredentials(webshopId, credentials.credentials))
    }

export const addCredentialsThunk =
    (
        swMerchantId: string,
        webshopId: string,
        data: CredentialsFormModel,
        credentialsId?: string
    ): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk(endpoints.Content.addCredentials, {
                data: {
                    swMerchantId: swMerchantId,
                    webshopId: webshopId,
                    credentialsId: credentialsId,
                    ...data,
                },
            })
        )

        dispatch(fetchCredentialsThunk(swMerchantId, webshopId, true))
    }

export const deleteCredentialsThunk =
    (swMerchantId: string, webshopId: string, credentialsId: string): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk(endpoints.Content.deleteCredentials, {
                params: {
                    swMerchantId: swMerchantId,
                    webshopId: webshopId,
                    credentialsId: credentialsId,
                },
            })
        )

        dispatch(fetchCredentialsThunk(swMerchantId, webshopId, true))
    }
