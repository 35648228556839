import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { StoreState } from "../StoreState"
import { RevenueReport, SegmentMapping, revenueReducerActions, revenueSelectors } from "../reducers/revenueReducer"

interface RevenueReportResponse {
    reports: (RevenueReport & { webshopId: string })[]
    segmentConfig: SegmentMapping[]
}

export const getRevenueReportsThunk =
    (swMerchantId: string): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (revenueSelectors.merchantRevenue(getState(), swMerchantId)) {
            return
        }
        const { reports, segmentConfig } = await dispatch(
            requestThunk<RevenueReportResponse>(endpoints.Analytics.getRevenueReports, {
                params: {
                    swMerchantId: swMerchantId,
                },
            })
        )
        reports.forEach((report) => {
            dispatch(revenueReducerActions.setWebshopRevenueReport(swMerchantId, report.webshopId, report))
        })
        dispatch(revenueReducerActions.setSegmentConfig(segmentConfig))
    }
