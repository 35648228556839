import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { SharedStoreState, endpointsShared, requestThunk } from ".."
import { swMerchantActions, swMerchantSelectors } from "../store/swMerchantSlice"
import { ISwMerchantDetails } from "../types/merchant/ISwMerchantDetails"

export const fetchSwMerchantWithIdThunk =
    (swMerchantId: string, force: boolean): ThunkAction<void, SharedStoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force && swMerchantSelectors.details(getState(), swMerchantId)) {
            return
        }

        const swMerchantDetails = await dispatch(
            requestThunk<ISwMerchantDetails>(endpointsShared.Identity.getMerchantDetails, {
                params: { merchantId: swMerchantId },
            })
        )

        dispatch(
            swMerchantActions.setDetails({
                swMerchantId,
                details: swMerchantDetails,
            })
        )
    }
