import { IRelationSearchData } from "./../../type/user/IRelationSearchData"
import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { StoreState } from "../StoreState"
import { IUserRelation, IUserRelationFlattened } from "../../type/user/IUserRelation"
import { setUserRelationToLocalStorage, flattenUserRelations } from "../../services/userRelationService"
import { TUserRelation } from "../../type/user/TUserRelation"
import { TUserRole } from "../../type/user/TUserRole"

export type UserRelationState = Readonly<{
    userRelations?: IUserRelation[]
    userRelationsFlattened?: IUserRelationFlattened[]
    currentRelation?: IUserRelationFlattened
    lastUsedRelations: string[]
    relationSearchData?: IRelationSearchData
}>

const initialState: UserRelationState = {
    lastUsedRelations: [],
}

export const userRelationReducerActions = {
    setUserRelations: (userRelations: IUserRelation[]) => createAction("APP/SET_USER_RELATIONS", { userRelations }),
    setCurrentRelation: (currentRelation: IUserRelationFlattened) =>
        createAction("APP/SET_CURRENT_RELATION", { currentRelation }),
    initLastUsedRelations: (lastUsedRelations: IUserRelationFlattened[]) =>
        createAction("APP/INIT_LASTUSED_RELATIONS", { lastUsedRelations }),
    addLastUsedRelation: (lastUsedRelationId: string) =>
        createAction("APP/ADD_NEW_ID_LASTUSED_RELATIONS", { lastUsedRelationId }),
    setRelationSearchData: (searchData: IRelationSearchData) => createAction("APP/SET_RELATION_SEARCH_DATA", { searchData }),
}

export type UserRelationActions = ActionsUnion<typeof userRelationReducerActions>

export const userRelationReducer = (state: UserRelationState = initialState, action: UserRelationActions) => {
    switch (action.type) {
        case "APP/SET_USER_RELATIONS": {
            return {
                ...state,
                userRelations: action.userRelations,
                userRelationsFlattened: flattenUserRelations(action.userRelations),
            }
        }
        case "APP/SET_CURRENT_RELATION": {
            setUserRelationToLocalStorage(action.currentRelation)
            return {
                ...state,
                currentRelation: action.currentRelation,
            }
        }
        case "APP/INIT_LASTUSED_RELATIONS": {
            return {
                ...state,
                lastUsedRelations: action.lastUsedRelations.map((relation) => relation.id).slice(0, 5),
            }
        }
        case "APP/ADD_NEW_ID_LASTUSED_RELATIONS": {
            return {
                ...state,
                lastUsedRelations: state.lastUsedRelations.includes(action.lastUsedRelationId)
                    ? [
                          state.lastUsedRelations[0],
                          ...state.lastUsedRelations.filter(
                              (id) => id === action.lastUsedRelationId && id !== state.lastUsedRelations[0]
                          ),
                          ...state.lastUsedRelations.filter(
                              (id) => id !== action.lastUsedRelationId && id !== state.lastUsedRelations[0]
                          ),
                      ]
                    : [state.lastUsedRelations[0], action.lastUsedRelationId, ...state.lastUsedRelations.slice(1, 4)],
            }
        }
        case "APP/SET_RELATION_SEARCH_DATA": {
            return {
                ...state,
                relationSearchData: action.searchData,
            }
        }
    }

    return state
}

export const userRelationSelectors = {
    userRelationsFlattened: (state: StoreState) => state.userRelation.userRelationsFlattened,
    userRelations: (state: StoreState) => state.userRelation.userRelations,
    currentUserRelation: (state: StoreState) => state.userRelation.currentRelation,
    currentUserRelationType: (state: StoreState): TUserRelation =>
        state.userRelation.currentRelation ? state.userRelation.currentRelation.relationType : "User",
    currentUserRelationRole: (state: StoreState): TUserRole =>
        state.userRelation.currentRelation ? state.userRelation.currentRelation.userRole : TUserRole.None,
    lastUsedRelations: (state: StoreState) => state.userRelation.lastUsedRelations,
    getRelationSearchData: (state: StoreState) => state.userRelation.relationSearchData,
}
