import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { PageWrapper, useSelector, userSelectors } from "swiipe.portal.shared"
import Adder from "../../components/common/Adder"
import PlatformSelect from "../../components/merchant/PlatformSelect"
import PageWrapperWithTabs from "../../components/page/PageWrapperWithTabs"
import ReleaseNoteDisplay from "../../components/releaseNotes/ReleaseNoteDisplay"
import ReleaseNoteEditor from "../../components/releaseNotes/ReleaseNoteEditor"
import { getReleaseNotes } from "../../services/releaseNotesService"
import { releaseNotesSelectors } from "../../store/reducers/releaseNotesReducer"
import { fetchReleaseNotes } from "../../store/thunks/releaseNotesThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { TReleaseNoteContext } from "../../type/releaseNotes/releaseNote"

interface IReleaseNotesPage {}

const languages = ["en", "da"]

const ReleaseNotesPage = ({}: IReleaseNotesPage) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()

    const userData = useSelector(userSelectors.userData)

    const releaseNotes = useSelector(releaseNotesSelectors.releaseNotes)
    const [selectedContext, setSelectedContext] = useState<TReleaseNoteContext>("woocommerce")

    const [showEditor, setShowEditor] = useState(false)
    const [editingCrossLanguageId, setEditingCrossLanguageId] = useState("")

    // For now hardcoded to en instead of: userData?.user.language?.toUpperCase()
    const notesToShow = getReleaseNotes(releaseNotes, {
        context: selectedContext,
        language: "en".toUpperCase(),
        fallbackLanguage: "en".toUpperCase(),
    })

    useEffect(() => {
        dispatch(fetchReleaseNotes(false))
    }, [])

    return (
        <>
            <PlatformSelect
                otherOptions={[{ value: "other", label: "Other" }]}
                onPlatformChange={(context) => setSelectedContext(context as TReleaseNoteContext)}
            ></PlatformSelect>

            {!showEditor && (
                <>
                    <PageWrapper disableMinHeight>
                        <Adder
                            onClick={() => {
                                setEditingCrossLanguageId("")
                                setShowEditor(true)
                            }}
                            buttonText={t("releasenotes.addnew")}
                        ></Adder>
                    </PageWrapper>

                    <PageWrapperWithTabs
                        tabs={notesToShow?.map((rn) => {
                            return {
                                title: rn.version,
                                id: rn.releaseNoteId,
                                element: (
                                    <ReleaseNoteDisplay
                                        key={rn.releaseNoteId}
                                        releaseNoteId={rn.releaseNoteId}
                                        languages={languages}
                                        onEditReleaseNote={(crossLanguageId) => {
                                            setEditingCrossLanguageId(crossLanguageId)
                                            setShowEditor(true)
                                        }}
                                    />
                                ),
                            }
                        })}
                    ></PageWrapperWithTabs>
                </>
            )}

            {showEditor && (
                <PageWrapperWithTabs
                    tabs={languages.map((l) => ({
                        title: l.toUpperCase(),
                        id: l,
                        element: (
                            <ReleaseNoteEditor
                                key={l}
                                crossLanguageId={editingCrossLanguageId}
                                updateCrossLanguageId={(id) => setEditingCrossLanguageId(id)}
                                language={l.toUpperCase()}
                                context={selectedContext}
                                onCloseEditor={() => setShowEditor(false)}
                            />
                        ),
                    }))}
                ></PageWrapperWithTabs>
            )}
        </>
    )
}

export default ReleaseNotesPage
