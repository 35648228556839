import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { ETermsType } from "../../type/terms/ETermsType"
import { ITermsBaseTemplate } from "../../type/terms/ITermsBaseTemplate"
import { ITermsTemplate } from "../../type/terms/ITermsTemplate"
import { ITermsTemplateFile } from "../../type/terms/ITermsTemplateFile"
import { ITermsTemplateTranslation } from "../../type/terms/ITermsTemplateTranslation"
import { StoreState } from "../StoreState"

export type TermsTemplateState = Readonly<{
    activeTermsTemplates?: ITermsTemplate[]
    termsTemplateDetails: {
        [termsType in ETermsType]?: ITermsTemplate[]
    }
    termsBaseTemplates?: ITermsBaseTemplate[]
    translations: {
        [termsType in ETermsType]?: ITermsTemplateTranslation[]
    }
    files: {
        [termsType in ETermsType]?: ITermsTemplateFile[]
    }
}>

const initialState: TermsTemplateState = {
    translations: {},
    files: {},
    termsTemplateDetails: {},
}

export const termsTemplateReducerActions = {
    setActiveTermsTemplates: (termsTemplates: ITermsTemplate[]) =>
        createAction("APP/SET_ACTIVE_TERMS_TEMPLATES", { termsTemplates }),
    setTermsTemplateDetails: (termsType: ETermsType, termsTemplates: ITermsTemplate[]) =>
        createAction("APP/SET_TERMS_TEMPLATE_DETAILS", { termsType, termsTemplates }),
    setTermsBaseTemplates: (termsBaseTemplates: ITermsBaseTemplate[]) =>
        createAction("APP/SET_TERMS_BASE_TEMPLATES", { termsBaseTemplates }),
    setTermsTemplateTranslations: (termsType: ETermsType, termsTypeTranslations: ITermsTemplateTranslation[]) =>
        createAction("APP/SET_TERMS_TEMPLATE_TRANSLATIONS", { termsType, termsTypeTranslations }),
    setTermsTemplateFiles: (termsType: ETermsType, termsTypeFiles: ITermsTemplateFile[]) =>
        createAction("APP/SET_TERMS_TEMPLATE_FILES", { termsType, termsTypeFiles }),
}

export type TermsTemplatesActions = ActionsUnion<typeof termsTemplateReducerActions>

export const termsTemplateReducer = (state: TermsTemplateState = initialState, action: TermsTemplatesActions) => {
    switch (action.type) {
        case "APP/SET_ACTIVE_TERMS_TEMPLATES": {
            return {
                ...state,
                activeTermsTemplates: action.termsTemplates,
            }
        }
        case "APP/SET_TERMS_TEMPLATE_DETAILS": {
            return {
                ...state,
                termsTemplateDetails: {
                    ...state.termsTemplateDetails,
                    [action.termsType]: action.termsTemplates,
                },
            }
        }
        case "APP/SET_TERMS_BASE_TEMPLATES": {
            return {
                ...state,
                termsBaseTemplates: action.termsBaseTemplates,
            }
        }
        case "APP/SET_TERMS_TEMPLATE_TRANSLATIONS": {
            return {
                ...state,
                translations: {
                    ...state.translations,
                    [action.termsType]: action.termsTypeTranslations,
                },
            }
        }
        case "APP/SET_TERMS_TEMPLATE_FILES": {
            return {
                ...state,
                files: {
                    ...state.files,
                    [action.termsType]: action.termsTypeFiles,
                },
            }
        }
    }

    return state
}

export const termsTemplateSelectors = {
    activeTermsTemplates: (state: StoreState) => state.termsTemplate.activeTermsTemplates,
    termsTemplateDetails: (state: StoreState, termsType: ETermsType) => state.termsTemplate.termsTemplateDetails[termsType],
    termsBaseTemplate: (state: StoreState, typeId: string) =>
        state.termsTemplate.termsBaseTemplates?.find((tt) => tt.typeId === typeId),
    termsBaseTemplates: (state: StoreState) => state.termsTemplate.termsBaseTemplates,
    termsTemplateTranslations: (state: StoreState, termsType: ETermsType) => state.termsTemplate.translations[termsType],
    termsTemplateFiles: (state: StoreState, termsType: ETermsType) => state.termsTemplate.files[termsType],
}
