import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { PageWrapper, useSelector } from "swiipe.portal.shared"
import Adder from "../../components/common/Adder"
import CredentialsForm from "../../components/merchant/CredentialsForm"
import WebshopSelect from "../../components/merchant/WebshopSelect"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { StoreState } from "../../store/StoreState"
import { credentialsSelectors } from "../../store/reducers/credentialsReducer"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { fetchCredentialsThunk } from "../../store/thunks/credentialsThunks"
import useReduxDispatch from "../../store/useReduxDispatch"

interface ILoginDetailsPageProps {}

const LoginDetailsPage = ({}: ILoginDetailsPageProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    useRequiredUserRelations([{ requiredType: "Merchant" }])

    const merchantDetails = useSelector(merchantSelectors.merchantDetails)

    const [selectedWebshopId, setSelectedWebshopId] = useState("")
    const [showAdder, setShowAdder] = useState(false)

    const credentials = useSelector((state: StoreState) => credentialsSelectors.credentials(state, selectedWebshopId))

    useEffect(() => {
        if (selectedWebshopId !== "" && merchantDetails) {
            dispatch(fetchCredentialsThunk(merchantDetails.swMerchant.swMerchantId, selectedWebshopId, false))
        }
    }, [selectedWebshopId])

    if (!merchantDetails) {
        return null
    }

    return (
        <>
            <WebshopSelect
                merchantDetails={merchantDetails}
                onWebshopChange={(selectedWebshopId: string) => setSelectedWebshopId(selectedWebshopId)}
            />

            {credentials &&
                credentials.map((c) => {
                    return (
                        <PageWrapper key={c.id} disableMinHeight>
                            <CredentialsForm
                                merchantId={merchantDetails.swMerchant.swMerchantId}
                                webshopId={selectedWebshopId}
                                credentialsId={c.id}
                                currentCredentials={c}
                            />
                        </PageWrapper>
                    )
                })}

            {showAdder && (
                <PageWrapper disableMinHeight>
                    <CredentialsForm
                        merchantId={merchantDetails.swMerchant.swMerchantId}
                        webshopId={selectedWebshopId}
                        onCancel={() => {
                            setShowAdder(false)
                        }}
                    />
                </PageWrapper>
            )}

            {!showAdder && (
                <PageWrapper disableMinHeight>
                    <Adder
                        onClick={() => {
                            setShowAdder(true)
                        }}
                        buttonText={t("loginDetails.addNew")}
                    ></Adder>
                </PageWrapper>
            )}
        </>
    )
}

export default LoginDetailsPage
