import React from "react"
import "./DataTable.scss"
import cn from "classnames"
import SpinnerContainer from "../buttons/SpinnerContainer"

interface Column {
    columnData?: string | number | JSX.Element
    className?: string
    isLoading?: boolean
}

interface DataTableProps {
    className?: string
    headers?: string[]
    rows: {
        rowId?: string
        rowName: string
        className?: string
        columns: (Column | string)[]
    }[]
    hideDividers?: boolean
}

const DataTable = ({ className, headers, rows, hideDividers }: DataTableProps) => {
    headers = ["", ...(headers || [])]
    const TrTableDivider = () =>
        (!hideDividers && headers && (
            <tr>
                <td className="data-table__divider" colSpan={headers.length + 1}></td>
            </tr>
        )) ||
        null
    return (
        <table className={cn("data-table", className)}>
            {headers && (
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th key={"dt-header-" + index + header}>{header}</th>
                        ))}
                    </tr>
                    <TrTableDivider />
                </thead>
            )}
            <tbody>
                {rows &&
                    rows.map((row, rowIndex) => {
                        const columns = [
                            ...row.columns,
                            ...(headers && headers.length > row.columns.length
                                ? Array(headers.length - row.columns.length).fill("")
                                : []),
                        ]
                        const rowKey = (row.rowId || row.rowName) + rowIndex
                        return (
                            <tr key={"dt-row-" + rowKey} className={row.className}>
                                {(!!row.rowName || rows.length > 1) && <th>{row.rowName}</th>}
                                {columns.map((column, columnIndex) => {
                                    const columnKey = rowKey + columnIndex
                                    if (typeof column === "string") {
                                        return <td key={"dt-column-" + columnKey}>{column}</td>
                                    }
                                    return (
                                        <td key={"dt-column-" + columnKey} className={cn(column.className)}>
                                            {typeof column.isLoading === "boolean" ? (
                                                <SpinnerContainer showSpinner={column.isLoading} aboveContent>
                                                    {column.columnData}
                                                </SpinnerContainer>
                                            ) : (
                                                column.columnData
                                            )}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
            </tbody>
            <tfoot>
                <TrTableDivider />
            </tfoot>
        </table>
    )
}

export default DataTable
