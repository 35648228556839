import React from "react"
import "./SessionReport.scss"

interface SessionReportProps {
    reportRows: {
        name: string
        value: string | number
    }[]
}

const SessionReport = ({ reportRows }: SessionReportProps) => {
    return (
        <div className="session-report">
            <h5>Sessions:</h5>
            <p>(Showing up to 100 latest reports)</p>
            {reportRows.map((row, idx) => (
                <div key={`sessions-report-${idx}`}>
                    <span>{row.name}: </span>
                    {row.value}
                </div>
            ))}
        </div>
    )
}

export default SessionReport
