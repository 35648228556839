import React from "react"
import { Control } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
    FormGroupWithCheckbox,
    IFormBase,
    IMerchantOnboardingServicesSubForm,
    LayoutSeparator,
    StandardParagraph,
} from "swiipe.portal.shared"
import { merchantPreOnboardingServicesConfig } from "../../services/merchantPreOnboardingService"
import "./MerchantOnboardingServicesForm.scss"

interface IMerchantOnboardingServicesFormProps<TForm extends IFormBase> {
    control: Control<TForm>
    getFieldsFromControl: (form: TForm | undefined) => IMerchantOnboardingServicesSubForm | undefined
    fieldSelectorPrefix: string
}

export const MerchantOnboardingServicesForm = <TForm extends IFormBase>({
    control,
    getFieldsFromControl,
    fieldSelectorPrefix,
}: IMerchantOnboardingServicesFormProps<TForm>) => {
    const { t } = useTranslation()
    const getFieldSelector = (fieldName: string) => fieldSelectorPrefix + fieldName

    const values = getFieldsFromControl(control.getValues() as TForm)

    return (
        <div className="merchant-onboarding-services-form">
            {merchantPreOnboardingServicesConfig.map((section, index) => {
                return (
                    <div className="merchant-onboarding-services-form__section" key={index}>
                        {section
                            .filter((c) => !c.hide)
                            .map((s) => (!values?.[s.inputName] ? [s] : [s, ...(s.dependencies ?? [])]))
                            .flat()
                            .map((field) => {
                                return (
                                    <FormGroupWithCheckbox
                                        key={field.inputName}
                                        innerRef={control.register()}
                                        name={getFieldSelector(field.inputName)}
                                    >
                                        <StandardParagraph isSubTitle className="my-0 d-inline-block">
                                            {field.title}
                                        </StandardParagraph>
                                    </FormGroupWithCheckbox>
                                )
                            })}
                        {index < merchantPreOnboardingServicesConfig.length - 1 && <LayoutSeparator />}
                    </div>
                )
            })}
        </div>
    )
}
