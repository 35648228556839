import cn from "classnames"
import { useEffect, useMemo, useState } from "react"
import React from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
    EMerchantPreOnboardingStatusFilter,
    FilterCheckbox,
    IMerchantPreOnboarding,
    ITableNavigationControl,
    ITableResetControl,
    PageContainer,
    PageWrapper,
    SharedStoreState,
    TableContainer,
    TextButton,
    getFormattedNumber,
    getQueryValueFromCurrentUrl,
    sharedConfigurationSelectors,
    useSelector,
} from "swiipe.portal.shared"
import Adder from "../../components/common/Adder"
import MerchantPreOnboardingStatusIcons from "../../components/merchantOnboarding/MerchantPreOnboardingStatusIcons"
import CommentBubbleIcon from "../../images/icons/comment-bubble.png"
import { useRequiredUserRelations } from "../../services/userRelationService"
import {
    EMerchantPreOnboardingSortingTypes,
    merchantPreOnboardingActions,
    merchantPreOnboardingSelectors,
} from "../../store/reducers/merchantPreOnboardingSlice"
import { getMerchantPreOnboardingsThunk, removeMerchantPreOnboardingThunk } from "../../store/thunks/merchantPreOnboardingThunks"
import { addModalThunk } from "../../store/thunks/modalThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { getFormattedDate } from "../../util/dateUtil"
import { MerchantPreOnboardingDetailsPage } from "./MerchantPreOnboardingDetailsPage"
import MerchantPreOnboardingPage from "./MerchantPreOnboardingPage"
import "./MerchantPreOnboardingsTablePage.scss"

interface IMerchantPreOnboardingsTablePageProps {}

const sortItems: { title: string; value: EMerchantPreOnboardingSortingTypes }[] = [
    {
        title: "Created",
        value: "created",
    },
    {
        title: "Last Updated",
        value: "lastUpdateOrEvent",
    },
    {
        title: "Merchant Name",
        value: "merchantName",
    },
    {
        title: "Value",
        value: "estimatedValueEUR",
    },
]

const MerchantPreOnboardingsTablePage = ({}: IMerchantPreOnboardingsTablePageProps) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()

    useRequiredUserRelations([{ requiredType: "Partner", requiredMasterPartner: true }])

    const continuationToken = useSelector(merchantPreOnboardingSelectors.continuationToken)
    const filters = useSelector(merchantPreOnboardingSelectors.filters)
    const preOnboardings = useSelector(merchantPreOnboardingSelectors.preOnboardings)
    const [showId, setShowId] = useState<string | undefined>()
    const preOnboardingIdToShow = showId?.split("|")[0]
    const preOnboardingToShow = preOnboardings?.find((po) => po.preOnboardingId === preOnboardingIdToShow)
    const resetControl = useMemo<ITableResetControl>(() => ({}), [])
    const navigationControl = useMemo<ITableNavigationControl<IMerchantPreOnboarding>>(() => ({}), [])
    const initialPreOnboardingId = useMemo(() => getQueryValueFromCurrentUrl("id") ?? undefined, [])
    const portalEndpoint = useSelector((state: SharedStoreState) => sharedConfigurationSelectors.endpointConfig(state, "Portal"))

    useEffect(() => {
        resetControl?.reset?.({
            resetPage: true,
        })
    }, [filters])

    return (
        <PageWrapper widest>
            {showId?.endsWith("|edit") && (
                <MerchantPreOnboardingPage
                    id={preOnboardingIdToShow ?? ""}
                    swMerchantId={preOnboardingToShow?.swMerchantId}
                    isDraft={!!preOnboardingToShow?.isDraft}
                    draft={preOnboardingToShow?.draftData}
                    refreshData={async () => await resetControl.reset?.({ resetData: true })}
                    onGoBack={() => setShowId(undefined)}
                    anyCompanyDetails={!!preOnboardingToShow?.merchantName}
                />
            )}
            {showId && !showId.endsWith("|edit") && (
                <MerchantPreOnboardingDetailsPage
                    preOnboardingId={preOnboardingIdToShow ?? ""}
                    onGoBack={() => setShowId(undefined)}
                    onEdit={(preOnboardingId) => {
                        setShowId(preOnboardingId + "|edit")
                    }}
                    refreshData={async () => await resetControl.reset?.({ resetData: true })}
                    navigationControl={navigationControl}
                />
            )}
            <PageContainer className={cn("pb-0")} hide={!!showId} classNameBody="pb-2 pt-2">
                <Adder
                    buttonText="CREATE NEW OFFER"
                    onClick={() => {
                        setShowId("new|edit")
                    }}
                />
            </PageContainer>
            <PageContainer classNameBody="merchant-pre-onboarding-page" hide={!!showId} title="Merchant onboarding">
                <TableContainer<IMerchantPreOnboarding, EMerchantPreOnboardingSortingTypes>
                    title="Merchant offers"
                    emptyDescription="No onboardings to show"
                    items={preOnboardings}
                    morePagesAvailable={!!continuationToken}
                    triggerShowItemId={initialPreOnboardingId}
                    resetControl={resetControl}
                    showRefresh
                    navigationControl={navigationControl}
                    renderHeaderCells={() => [
                        { className: "merchant-pre-onboarding__header-merchant", content: "Merchant" },
                        { className: "merchant-pre-onboarding__header-admin", content: "Admin" },
                        { className: "merchant-pre-onboarding__header-value", content: "Est. Value" },
                        { className: "merchant-pre-onboarding__header-last-update", content: "Last Update" },
                        { className: "merchant-pre-onboarding__header-status", content: "Status" },
                        { className: "merchant-pre-onboarding__header-actions", content: "Actions" },
                    ]}
                    renderRowCells={(preOnb, isSelected, selectItem, refresh) => [
                        { className: "merchant-pre-onboarding__data-merchant", content: preOnb.merchantName },
                        { className: "merchant-pre-onboarding__data-admin", content: preOnb.creatorShorthand },
                        {
                            className: "merchant-pre-onboarding__data-value",
                            content: preOnb.estimatedValueCurrency + " " + getFormattedNumber(preOnb.estimatedValue),
                        },
                        {
                            className: "merchant-pre-onboarding__data-last-update",
                            content: (
                                <span>
                                    {getFormattedDate(preOnb.lastUpdateOrEvent, "slashes")}
                                    {preOnb.hasComments && (
                                        <img className="merchant-pre-onboarding__comment-bubble" src={CommentBubbleIcon} />
                                    )}
                                </span>
                            ),
                        },
                        {
                            className: "merchant-pre-onboarding__data-status",
                            content: <MerchantPreOnboardingStatusIcons preOnboarding={preOnb} />,
                        },
                        {
                            className: "merchant-pre-onboarding__data-actions",
                            content: (
                                <div className="merchant-pre-onboarding__data-actions__inner">
                                    <TextButton
                                        text="Edit"
                                        textDecoration="underline"
                                        onClick={async () => {
                                            selectItem()
                                        }}
                                    />
                                    {preOnb.isDraft && <div className="ml-2 mr-2">|</div>}
                                    {preOnb.isDraft && (
                                        <TextButton
                                            text="Delete"
                                            textDecoration="underline"
                                            onClick={async () => {
                                                const result = await dispatch(
                                                    addModalThunk({
                                                        type: "confirm",
                                                        text:
                                                            "Are you sure you want to delete offer for merchant " +
                                                            preOnb.merchantName +
                                                            "?",
                                                        confirmButtonText: "Delete",
                                                    })
                                                )
                                                if (result.type === "accepted") {
                                                    await dispatch(removeMerchantPreOnboardingThunk(preOnb.preOnboardingId))
                                                    await refresh()
                                                }
                                            }}
                                        />
                                    )}
                                    {!preOnb.isDraft && <div className="ml-2 mr-2">|</div>}
                                    {!preOnb.isDraft && (
                                        <a
                                            className="merchant-pre-onboarding__data-actions__navigate"
                                            target="_blank"
                                            rel="noreferrer"
                                            href={portalEndpoint + "?relationId=" + preOnb.swMerchantId}
                                        >
                                            {"Portal"}
                                        </a>
                                    )}
                                </div>
                            ),
                        },
                    ]}
                    getItemId={(mp) => mp.preOnboardingId}
                    fetchItems={async (pageNumber, perPage, force) => {
                        await dispatch(getMerchantPreOnboardingsThunk(pageNumber, perPage, force))
                    }}
                    onShowItemId={async (itemId) => {
                        setShowId(itemId)
                    }}
                    fetchItemsHash={JSON.stringify(filters)}
                    tabs={{
                        tabItems: [
                            { id: undefined, title: "All" },
                            { id: "draft", title: "Drafts" },
                            { id: "sent", title: "Sent" },
                            { id: "signed", title: "Signed" },
                            { id: "completed", title: "Completed" },
                        ],
                        initialTabId: undefined,
                        onTabChange: (id) => {
                            dispatch(
                                merchantPreOnboardingActions.setMerchantPreOnboardingFilters({
                                    ...filters,
                                    status: id as EMerchantPreOnboardingStatusFilter | undefined,
                                })
                            )
                        },
                    }}
                    sorting={{
                        initialSortingValue: "created" as EMerchantPreOnboardingSortingTypes,
                        initialSortingDirection: "descending",
                        onSortingChange: (value, direction) => {
                            dispatch(
                                merchantPreOnboardingActions.setMerchantPreOnboardingFilters({
                                    ...filters,
                                    sortByProperty: value,
                                    isOrderByDescending: direction === "descending",
                                })
                            )
                        },
                        sortingItems: sortItems,
                    }}
                    filtering={{
                        renderInlineFilters: () => (
                            <div>
                                <FilterCheckbox
                                    label="Show only offers assigned to me"
                                    showFilters={true}
                                    isChecked={filters.onlyAssignedToMe}
                                    onChange={(checked: boolean) =>
                                        dispatch(
                                            merchantPreOnboardingActions.setMerchantPreOnboardingFilters({
                                                ...filters,
                                                onlyAssignedToMe: checked,
                                            })
                                        )
                                    }
                                />
                            </div>
                        ),
                    }}
                />
            </PageContainer>
        </PageWrapper>
    )
}

export default MerchantPreOnboardingsTablePage
