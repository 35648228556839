import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk, userSelectors } from "swiipe.portal.shared"
import { getPhoneWithCountryCode } from "../../services/smsService"
import { StoreState } from "../StoreState"
import { ISentSms, sentSmsReducerActions, sentSmsSelectors } from "../reducers/sentSmsReducer"
import { ISmsTemplate } from "../reducers/smsTemplateReducer"
import { endpoints } from "./../../data/endpoints"
import { TUserRelation } from "./../../type/user/TUserRelation"

export const getSentSmsesThunk =
    (search: string, force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force) {
            if (sentSmsSelectors.smses(getState()) && search === sentSmsSelectors.search(getState())) {
                return
            }
        }
        dispatch(sentSmsReducerActions.resetSmses())
        const result = await dispatch(
            requestThunk<{ smses: ISentSms[] }>(endpoints.Send.getSentSmses, {
                params: {
                    search: search,
                },
            })
        )
        dispatch(sentSmsReducerActions.setSearch(search))
        dispatch(sentSmsReducerActions.setSmses(result.smses))
    }

interface ISendSmsRequest {
    smsType: string
    language: string
    toNumber: string
    userRelationType: TUserRelation
    relationId: string
    webshopId?: string
    userId: string
    data: { [key: string]: any }
}

export const sendTestSmsThunk =
    (template: ISmsTemplate, data: { [key: string]: any }): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const userData = userSelectors.userData(getState())
        if (!userData || !userData.user.phone) {
            return
        }

        const request: ISendSmsRequest = {
            smsType: template.smsType,
            language: template.language,
            toNumber: getPhoneWithCountryCode(userData.user.phone),
            userRelationType: "User",
            relationId: userData.user.id,
            userId: userData.user.id,
            data,
        }

        await dispatch(
            requestThunk<any>(endpoints.Send.sentTestSms, {
                data: request,
            })
        )

        await dispatch(getSentSmsesThunk(sentSmsSelectors.search(getState()), true))
    }

export const resendSentSmsThunk =
    (smsId: string, resendToNumber: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(
            requestThunk(endpoints.Send.resendSentSms, {
                data: {
                    smsId,
                    toNumber: resendToNumber,
                },
            })
        )

        dispatch(sentSmsReducerActions.addResentSms(smsId))
    }
