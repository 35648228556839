export interface IEmailTemplateType {
    type: string
    label: string
    exampleData: { [key: string]: any }
}

const swiipeBaseData = {
    contactName: "Test Name",
    serviceName: "Service Name",
    webshopName: "Webshop Name",
}

const swiipeServiceOrderCancellationData = {
    ...swiipeBaseData,
    shouldCancelAtProvider: true,
}

const swiipeServiceOrderConfirmationData = {
    ...swiipeBaseData,
    isPartner: true,
    partnerName: "Test Partner",
    termsAndConditionsUrl: "https://portal.swiipe.com/linkToTermsAndConditions",
}

const swiipeWinbackBaseData = {
    customersName: "John Doe",
    webshopName: "example.com",
    webshopLogoUrl: "https://test-cdn.swiipe.com/email-template-images/shared/swiipe-logo-blue.png",
    winbackLink: "https://winbacklinkexample.com",
    nemIDAppLink: "https://www.nemid.nu/dk-da/kom_i_gang_med_nemid/nemid_nogleapp/",
    mitIdLink: "https://www.mitid.dk",
    paymentType: "Credit card",
    trustMarkLogoUrl: "https://swiipetestidentity.blob.core.windows.net/communicationinfoimages/e-maerket.svg",
    signer: "John Doe from example.com",
    includeFooter: true,
}

const emailTemplateTypes: IEmailTemplateType[] = [
    {
        type: "PiqNotification",
        label: "Payments PIQ Email Notification",
        exampleData: {
            merchantName: "Merchant name",
            hostNames: ["www.shop1.com", "shop2.com"],
        },
    },
    {
        type: "PiqNotificationDankort",
        label: "Dankort PIQ Email Notification",
        exampleData: {
            merchantName: "Cheburek ApS",
            dankortId: "12345",
            hostNames: ["www.shop1.com", "shop2.com"],
            piqMerchantIds: ["100231998", "100231998"],
            mccs: ["5944", "5944"],
        },
    },

    {
        type: "WinbackNotificationDefault",
        label: "Win-back Email Notification (Default)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "WinbackNotification3DSIssues",
        label: "Win-back Email Notification (3DS related issues)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "WinbackNotificationCancelledByUser",
        label: "Win-back Email Notification (Payment cancelled by user)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "WinbackNotificationNoFunds",
        label: "Win-back Email Notification (No funds)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "WinbackNotificationCardAccountBankIssues",
        label: "Win-back Email Notification (Card, account or bank issues)",
        exampleData: swiipeWinbackBaseData,
    },

    // Start old WinbackNotifications
    {
        type: "WinbackNotification",
        label: "Win-back Email Notification",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "WinbackNotificationAlt1",
        label: "Win-back Email Notification - Alt 1",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "WinbackNotificationAlt2",
        label: "Win-back Email Notification - Alt 2",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "WinbackNotificationAlt3",
        label: "Win-back Email Notification - Alt 3",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "WinbackNotificationAlt4",
        label: "Win-back Email Notification - Alt 4",
        exampleData: swiipeWinbackBaseData,
    },

    // End old WinbackNotifications

    // Start Winback split test

    {
        type: "wb3DS-A",
        label: "Win-back Email Notification (3DS related issues - A)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "wb3DS-B",
        label: "Win-back Email Notification (3DS related issues - B)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "wbBankIssues-A",
        label: "Win-back Email Notification (Card, account or bank issues - A)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "wbBankIssues-B",
        label: "Win-back Email Notification (Card, account or bank issues - B)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "wbNoFunds-A",
        label: "Win-back Email Notification (No Funds - A)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "wbNoFunds-B",
        label: "Win-back Email Notification (No Funds - B)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "wbUserCancelled-A",
        label: "Win-back Email Notification (Payment cancelled by user - A)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "wbUserCancelled-B",
        label: "Win-back Email Notification (Payment cancelled by user - B)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "wbDefault-A",
        label: "Win-back Email Notification (Default - A)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "wbDefault-B",
        label: "Win-back Email Notification (Default - B)",
        exampleData: swiipeWinbackBaseData,
    },

    // End Winback split test
    {
        type: "WinbackSmsSenderNameNeedsApproval",
        label: "Win-back sms sender name needs approval (Support Notification)",
        exampleData: {
            smsSenderName: "SHOPNAME",
            merchantName: "Super ApS",
            merchantContactName: "John Doe",
            merchantContactEmail: "mail@example.com",
            merchantContactPhone: "+380973821384",
        },
    },
    {
        type: "PlusSellVoidFail",
        label: "Plus parent order void fail",
        exampleData: {
            webshopName: "The Company ApS",
            parentPlatformOrderId: "12345",
            plusSellPlatformOrderId: "12345",
        },
    },
    {
        type: "DankortOnboarding",
        label: "Dankort Jira Notification",
        exampleData: {
            merchantName: "The Company ApS",
            merchantContactName: "John Doe",
            merchantContactEmail: "john.doe@mail.com",
            merchantContactPhone: "+4538475937",
            hostNames: ["https://www.shop1.com", "https://shop2.com"],
            emailToPiqSent: false,
        },
    },
    {
        type: "PlusSellOnboarding",
        label: "Plus-sell Jira Notification",
        exampleData: {
            merchantName: "The Company ApS",
            merchantContactName: "John Doe",
            merchantContactPhone: "+4538475937",
            hostNames: ["https://www.shop1.com", "https://shop2.com"],
        },
    },
    {
        type: "ForbrugsforeningenOnboarding",
        label: "Forbrugsforeningen Jira Notification",
        exampleData: {
            merchantName: "The Company ApS",
            merchantContactName: "John Doe",
            merchantContactEmail: "john.doe@mail.com",
            merchantContactPhone: "+4538475937",
            hostNames: ["https://www.shop1.com", "https://shop2.com"],
        },
    },
    {
        type: "GeneralPaymentsOnboarding",
        label: "General Payments Jira Notification",
        exampleData: {
            paymentMethod: "Some Payment Method",
            merchantName: "The Company ApS",
            merchantContactName: "John Doe",
            merchantContactEmail: "john.doe@mail.com",
            merchantContactPhone: "+4538475937",
            hostNames: ["https://www.shop1.com", "https://shop2.com"],
        },
    },
    {
        type: "GeneralServiceCancellationEopEmail",
        label: "General Service Cancellation End-of-period",
        exampleData: swiipeServiceOrderCancellationData,
    },
    {
        type: "GeneralServiceCancellationInstantEmail",
        label: "General Service Cancellation Instant",
        exampleData: swiipeServiceOrderCancellationData,
    },
    {
        type: "GeneralCardPaymentsCancellationEmail",
        label: "General Card Payment Cancellation",
        exampleData: {
            ...swiipeServiceOrderCancellationData,
            acquirers: ["Nets", "Forbrugsforeningen", "..."],
        },
    },
    {
        type: "CardPayments",
        label: "Card Payments Jira Notification",
        exampleData: {
            merchantName: "The Company ApS",
            merchantContactName: "John Doe",
            merchantContactEmail: "john.doe@mail.com",
            merchantContactPhone: "+4538475937",
            hasClearhausAlready: false,
            hostNames: ["https://www.shop1.com", "https://shop2.com"],
        },
    },
    {
        type: "MpoOnboarding",
        label: "MobilePay Online Jira Notification",
        exampleData: {
            merchantName: "The Company ApS",
            merchantContactName: "John Doe",
            merchantContactEmail: "john.doe@mail.com",
            merchantContactPhone: "+4538475937",
            webshopData: [
                { hostName: "https://www.shop1.com", logoUrl: "https://url1.com/imgae", mcc: "5200" },
                { hostName: "https://www.shop2.com", logoUrl: "https://url2.com/imgae" },
            ],
            existedAtClearhaus: false,
            onboardedWithClearhaus: true,
            editMode: false,
        },
    },
    {
        type: "TransactionActionFail",
        label: "Transaction action fail Jira Notification",
        exampleData: {
            action: "Capture",
            platformOrderId: "1017",
            merchantName: "Fake Merchant ApS",
            webshop: "https://webshop.com",
            swOrderPageUrl: "https://portal.swiipe.com/orders?webshopId=XXX&orderId=XXX&relationId=XXX",
            reportedByUser: "useremail@mail.com",
            userName: "John Doe",
            userPhone: "12345678",
            comment: "Help me!!",
        },
    },
    {
        type: "ChangedPasswordEmail",
        label: "User Changed Password",
        exampleData: {
            resetPasswordUrl: "https://portal.swiipe.com/something",
            userName: "Henrik Larsen",
            date: "24/12-2019",
            time: "12:24",
        },
    },
    {
        type: "PasswordResetEmail",
        label: "User Reset Password",
        exampleData: {
            resetPasswordUrl: "https://portal.swiipe.com/something",
            userName: "Henrik Larsen",
            email: "henrik@larsen.dk",
            date: "24/12-2019",
            time: "12:24",
        },
    },

    {
        type: "InvitedConfirmAccountSetPasswordEmail",
        label: "User Invited, Confirm Account",
        exampleData: {
            confirmEmailUrl: "https://portal.swiipe.com/something",
            termsAndConditions: "https://portal.swiipe.com/terms_and_conditions",
            privacyPolicy: "https://portal.swiipe.com/privacy_policy",
            supportNumber: "+4512341234",
            userName: "Henrik Larsen",
            accountType: "Partner",
            merchantCompanyName: "The Company ApS",
        },
    },
    {
        type: "InvitedExistingEmail",
        label: "User Invited, Email Exists",
        exampleData: {
            portalUrl: "https://portal.swiipe.com",
            resetPasswordUrl: "https://portal.swiipe.com/something",
            supportNumber: "+4512341234",
            userName: "Henrik Larsen",
            accountType: "Partner",
            merchantCompanyName: "The Company ApS",
        },
    },
    {
        type: "MerchantPartnerAgreementConfirmAccountSetPasswordEmail",
        label: "Partner Invited Merchant, Confirm Account",
        exampleData: {
            userName: "Henrik Larsen",
            supportNumber: "+4512341234",
            partnerCompanyName: "My Partner ApS",
            merchantCompanyName: "The Company ApS",
            confirmEmailUrl: "https://portal.swiipe.com/something",
            agreement: "https://portal.swiipe.com/agreementSomething",
            termsAndConditions: "https://portal.swiipe.com/terms_and_conditions",
            privacyPolicy: "https://portal.swiipe.com/privacy_policy",
        },
    },
    {
        type: "MerchantPartnerAgreementExistingEmail",
        label: "Partner Invited Merchant, Email Exists",
        exampleData: {
            userName: "Henrik Larsen",
            partnerCompanyName: "My Partner ApS",
            merchantCompanyName: "The Company ApS",
            acceptAgreementUrl: "https://core.swiipe.com/something",
            portalUrl: "https://portal.swiipe.com",
            resetPasswordUrl: "https://portal.swiipe.com/something",
            agreement: "https://portal.swiipe.com/agreementSomething",
            termsAndConditions: "https://portal.swiipe.com/terms_and_conditions",
        },
    },
    {
        type: "RightsRequestMerchantPartnerAgreementExistingEmail",
        label: "Partner Requests Merchant Access",
        exampleData: {
            userName: "Henrik Larsen",
            partnerCompanyName: "My Partner ApS",
            merchantCompanyName: "The Company ApS",
            acceptAgreementUrl: "https://core.swiipe.com/something",
            portalUrl: "https://portal.swiipe.com",
            resetPasswordUrl: "https://portal.swiipe.com/something",
            agreement: "https://portal.swiipe.com/agreementSomething",
            termsAndConditions: "https://portal.swiipe.com/terms_and_conditions",
        },
    },
    {
        type: "SignupConfirmAccountSetPasswordEmail",
        label: "Signup, Confirm Account",
        exampleData: {
            confirmEmailUrl: "https://portal.swiipe.com/something",
            termsAndConditions: "https://portal.swiipe.com/terms_and_conditions",
            privacyPolicy: "https://portal.swiipe.com/privacy_policy",
            supportNumber: "+4512341234",
            userName: "Henrik Larsen",
            accountType: "Partner",
        },
    },
    {
        type: "SignupExistingEmail",
        label: "Signup, Existing Email",
        exampleData: {
            portalUrl: "https://portal.swiipe.com",
            resetPasswordUrl: "https://portal.swiipe.com/something",
            supportNumber: "+4512341234",
            userName: "Henrik Larsen",
            accountType: "Partner",
        },
    },
    {
        type: "SwiipeInvoiceAutomaticPaymentEmail",
        label: "Swiipe Invoice Automatic Payment",
        exampleData: {
            portalInvoicesUrl: "https://portal.swiipe.com/invoices?relationId=123",
            userName: "Henrik",
            invoiceNumber: "1234567",
        },
    },
    {
        type: "SwiipeInvoiceManualPaymentEmail",
        label: "Swiipe Invoice Manual Payment",
        exampleData: {
            portalInvoicesUrl: "https://portal.swiipe.com/invoices?relationId=123",
            userName: "Henrik",
            invoiceNumber: "1234567",
        },
    },
    {
        type: "PspBasicConfirmation",
        label: "PSP basic order confirmation",
        exampleData: swiipeServiceOrderConfirmationData,
    },
    {
        type: "PspBusinessConfirmation",
        label: "PSP business order confirmation",
        exampleData: swiipeServiceOrderConfirmationData,
    },
    {
        type: "DankortConfirmation",
        label: "Dankort order confirmation",
        exampleData: swiipeServiceOrderConfirmationData,
    },
    {
        type: "ForbrugsforeningenConfirmation",
        label: "Forbrugsforeningen order confirmation",
        exampleData: swiipeServiceOrderConfirmationData,
    },
    {
        type: "SwishConfirmation",
        label: "Swish order confirmation",
        exampleData: swiipeServiceOrderConfirmationData,
    },
    {
        type: "KlarnaConfirmation",
        label: "Klarna order confirmation",
        exampleData: swiipeServiceOrderConfirmationData,
    },
    {
        type: "VippsConfirmation",
        label: "Vipps order confirmation",
        exampleData: swiipeServiceOrderConfirmationData,
    },
    {
        type: "ViabillConfirmation",
        label: "Viabill order confirmation",
        exampleData: swiipeServiceOrderConfirmationData,
    },
    {
        type: "MobilePayOnlineConfirmation",
        label: "MobilePay Online order confirmation",
        exampleData: swiipeServiceOrderConfirmationData,
    },
    {
        type: "MobilePaySubscriptionsConfirmation",
        label: "MobilePay Subscriptions order confirmation",
        exampleData: swiipeServiceOrderConfirmationData,
    },
    {
        type: "AccountToAccountConfirmation",
        label: "Account to account order confirmation",
        exampleData: swiipeServiceOrderConfirmationData,
    },
    {
        type: "ApplePayConfirmation",
        label: "ApplePay order confirmation",
        exampleData: swiipeServiceOrderConfirmationData,
    },
    {
        type: "ResursConfirmation",
        label: "Resurs order confirmation",
        exampleData: swiipeServiceOrderConfirmationData,
    },
    {
        type: "ResursSignUpNotification",
        label: "Resurs Notification to Support when no stores onboarding",
        exampleData: {
            merchantName: "The Company ApS",
            merchantContactName: "John Doe",
            merchantContactEmail: "john.doe@mail.com",
            merchantContactPhone: "+4538475937",
            hostName: "https://www.shop1.com",
        },
    },
    {
        type: "PaymentsAlert",
        label: "Alerting - Payments outage",
        exampleData: {
            alertTrigger: "PaymentsFailedLongTerm",
            alertLevel: "Error",
            webshopName: "shop1.com",
            fromDate: new Date().toDateString(),
            toDate: new Date().toDateString(),
            treshold: "0.30",
            totalTransactions: "100",
            failedTransactions: "50",
            failedRatio: "0.50",
        },
    },
    {
        type: "StaleAuthAlert",
        label: "Alerting - Stale auth",
        exampleData: {
            merchantName: "John Doe",
            webshopName: "shop1.com",
            staleAuths: [
                {
                    platformOrderId: "10015",
                    expireYear: "2025",
                    expireMonth: "10",
                    expireDay: "17",
                },
                {
                    platformOrderId: "10022",
                    expireYear: "2025",
                    expireMonth: "10",
                    expireDay: "21",
                },
                {
                    platformOrderId: "10029",
                    expireYear: "2025",
                    expireMonth: "11",
                    expireDay: "03",
                },
            ],
        },
    },
    {
        type: "WinbackConfirmation",
        label: "Win-back order confirmation",
        exampleData: swiipeServiceOrderConfirmationData,
    },
    {
        type: "PlusSellConfirmation",
        label: "Plus-sell order confirmation",
        exampleData: swiipeServiceOrderConfirmationData,
    },
    {
        type: "ReturnSellConfirmation",
        label: "Return-sell order confirmation",
        exampleData: swiipeServiceOrderConfirmationData,
    },
    {
        type: "AnywhereMobileConfirmation",
        label: "Anywhere Mobile order confirmation",
        exampleData: swiipeServiceOrderConfirmationData,
    },
    {
        type: "FraudReport",
        label: "Fraud report email to Clearhaus",
        exampleData: {
            webshopId: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
            webshopHostName: "Webshop.com",
            orderId: "350480c7-9c15-4645-86ef-14bd0be6e009",
            platformOrderId: "00001",
            orderDate: "24.11.2021",
            currency: "DKK",
            amount: "1700.00",
            reason: "",
        },
    },
    {
        type: "PspCancellationEmail",
        label: "PSP cancellation email",
        exampleData: swiipeServiceOrderCancellationData,
    },
    {
        type: "ViabillCancellationEmail",
        label: "Viabill cancellation Email",
        exampleData: swiipeServiceOrderCancellationData,
    },
    {
        type: "KlarnaCancellationEmail",
        label: "Klarna cancellation Email",
        exampleData: swiipeServiceOrderCancellationData,
    },
    {
        type: "ServiceCancellationJiraNotification",
        label: "Service cancellation Jira notification",
        exampleData: {
            serviceName: "Dankort",
            merchantName: "Clockwork Orange ApS",
            merchantContactName: "Alex DeLarge",
            merchantContactPhone: "+4538475937",
            merchantContactEmail: "blank@blank.com",
        },
    },
    {
        type: "ScheduledMerchantDeletionNotification",
        label: "Merchant deletion scheduled email",
        exampleData: {
            name: "Contact name",
            requestedDate: "dd.MM.yyyy",
            canReactivateUntil: "dd.MM.yyyy",
            merchantName: "Digital Aps",
        },
    },
    {
        type: "MerchantDeletedNotification",
        label: "Merchant deleted notification",
        exampleData: {
            name: "Contact Name",
            merchantName: "Digital Aps",
        },
    },
    {
        type: "MerchantDeletionCancelledNotification",
        label: "Merchant deletion cancelled notification",
        exampleData: {
            name: "Contact Name",
            merchantName: "Digital Aps",
        },
    },
    {
        type: "ConfirmDeletion",
        label: "Confirm merchant deletion",
        exampleData: {
            name: "Contact Name",
            requestedName: "Random Name",
            merchantName: "Digital Aps",
        },
    },
    {
        type: "ScheduledMerchantDeletionTrialEndedNotification",
        label: "Merchant trial ended and deletion scheduled email",
        exampleData: {
            name: "Contact name",
            requestedDate: "dd.MM.yyyy",
            canReactivateUntil: "dd.MM.yyyy",
            merchantName: "Digital Aps",
        },
    },
    {
        type: "ScheduledMerchantDeletionTrialEndingSoonNotification",
        label: "Merchant trial ending soon email - support",
        exampleData: {
            trialExpirationDate: "dd.MM.yyyy",
            merchantName: "Digital Aps",
            swiipePlan: "None",
        },
    },
    {
        type: "PaymentLinkNotification",
        label: "Payment Link Notification",
        exampleData: {
            platformOrderId: "PL228",
            webshopName: "example.com",
            customizedTextHtml: "<h2>Hey Customer,</h2><p>As discussed on phone...<br/><br/> And thank you again for....</p>",
            signedBy: "Jan From Swiipe",
            webshopLogoUrl: "https://test-cdn.swiipe.com/email-template-images/shared/swiipe-logo-blue.png",
            paymentLink: "https://secure.swiipe.com/paymentlink/be020c2f-ee65-46c1-9fd4-696444e43b44",
        },
    },
    {
        type: "PaymentLinkOrderReceipt",
        label: "Payment Link Order Receipt",
        exampleData: {
            customerName: "Ivan",
            platformOrderId: "PL228",
            webshopName: "example.com",
            paymentMethod: "CreditCards",
            amount: "100.00",
            currency: "DKK",
            dateTime: "10-11-2022 10:55",
        },
    },
    {
        type: "Account2AccountVerifiedAccount",
        label: "Account 2 Account verified account notification",
        exampleData: {
            userName: "Contact Name",
            merchantName: "Digital Aps",
            bankAccountDisplayName: "1234 123456789 - My Account",
            accountCurrency: "DKK",
        },
    },
    {
        type: "Account2AccountPendingAccountVerification",
        label: "Account 2 Account pending account verification notification",
        exampleData: {
            userName: "Contact Name",
            merchantName: "Digital Aps",
            bankAccountDisplayName: "1234 123456789 - My Account",
            accountCurrency: "DKK",
        },
    },
    {
        type: "Account2AccountAccountUnverified",
        label: "Account 2 Account unverified account notification",
        exampleData: {
            userName: "Contact Name",
            merchantName: "Digital Aps",
            bankAccountDisplayName: "1234 123456789 - My Account",
            accountCurrency: "DKK",
        },
    },
    {
        type: "Account2AccountAccountUnverifiedSwiipeSupport",
        label: "Account 2 Account unverified account notification - Swiipe Support",
        exampleData: {
            merchantName: "Digital Aps",
            merchantContactName: "Alex DeLarge",
            merchantContactPhone: "+4538475937",
            merchantContactEmail: "blank@blank.com",
            bankAccountDisplayName: "1234 123456789 - My Account - DKK",
            hostnames: ["https://www.shop1.com", "https://www.shop2.com"],
        },
    },
    {
        type: "InstantOnboardingSubmitted",
        label: "(Clearhaus) Cards instant onboarding submitted email",
        exampleData: {
            name: "John Doe",
            swMerchantId: "e001541d-64d4-4090-9d0d-d1c30fc03e2c",
        },
    },
    {
        type: "FullOnboardingSubmitted",
        label: "(Clearhaus) Cards full onboarding submitted email",
        exampleData: {
            name: "John Doe",
        },
    },
    {
        type: "InstantAcquiringConfirmation",
        label: "(Clearhaus) Instant acquiring order confirmation",
        exampleData: {
            name: "John Doe",
            hostNames: ["www.shop1.com", "shop2.com"],
            swMerchantId: "e001541d-64d4-4090-9d0d-d1c30fc03e2c",
            termsAndConditionsUrl: "https://portal.swiipe.com/linkToTermsAndConditions",
        },
    },
    {
        type: "FullAcquiringConfirmation",
        label: "(Clearhaus) Full acquiring order confirmation",
        exampleData: {
            name: "John Doe",
            hostNames: ["www.shop1.com", "shop2.com"],
            termsAndConditionsUrl: "https://portal.swiipe.com/linkToTermsAndConditions",
        },
    },
    {
        type: "InstantAcquiringReminder",
        label: "(Clearhaus) Instant acquiring reminder",
        exampleData: {
            name: "John Doe",
            hostNames: ["www.shop1.com", "shop2.com"],
            isSaved: false,
            swMerchantId: "e001541d-64d4-4090-9d0d-d1c30fc03e2c",
        },
    },
    {
        type: "FullAcquiringReminder",
        label: "(Clearhaus) Full acquiring reminder",
        exampleData: {
            name: "John Doe",
            hostNames: ["www.shop1.com", "shop2.com"],
            isSaved: false,
            swMerchantId: "e001541d-64d4-4090-9d0d-d1c30fc03e2c",
        },
    },
    {
        type: "ClearhausApplicationAccepted",
        label: "(Clearhaus) Application accepted support notification",
        exampleData: {
            merchantName: "Shop ApS",
        },
    },
    {
        type: "ClearhausNeedsInformationNotification",
        label: "(Clearhaus) needs information notification",
        exampleData: {
            name: "John Doe",
            swMerchantId: "e001541d-64d4-4090-9d0d-d1c30fc03e2c",
        },
    },
    {
        type: "ClearhausAdditionalInformationReceived",
        label: "(Clearhaus) additional information received",
        exampleData: {
            name: "John Doe",
        },
    },
    {
        type: "ClearhausChangesRequested",
        label: '(Clearhaus) "Existing application changes" requested notification to User',
        exampleData: {
            name: "John Doe",
        },
    },
    {
        type: "ClearhausChangesApproved",
        label: '(Clearhaus) "Existing application changes" approved notification to User',
        exampleData: {
            name: "John Doe",
        },
    },
    {
        type: "ClearhausChangesRequest",
        label: '(Clearhaus) "Existing application changes requested" notification to Clearhaus',
        exampleData: {
            merchantName: "Some ApS",
            companyNameCurrent: "Some ApS",
            companyNameNew: "Wow ApS",

            companyCvrCurrent: "1234",
            companyCvrNew: "4321",

            ibanCurrent: "AB32984234234",
            ibanNew: "CD32984234234",

            swiftCodeCurrent: "SWIFFT123OLD",
            swiftCodeNew: "SWIFFT123NEW",

            reasonForChange: "We changed our bank",
            //also has file attachment
        },
    },
    {
        type: "ClearhausChangesSupportNotification",
        label: '(Clearhaus) "Existing application changes requested" notification to support',
        exampleData: {
            merchantName: "Random ApS",
            merchantContactEmail: "rand.aps@gmail.com",
        },
    },
    {
        type: "MerchantOnboardingOffer",
        label: "Offer to merchant for onboarding with Swiipe",
        exampleData: {
            offerUrl: "https://portal.swiipe.com/merchantOffer",
            merchantContactFirstName: "Hans",
            merchantContactLastName: "Jensen",
            merchantName: "Random ApS",
            merchantContactEmail: "rand.aps@gmail.com",
            customMessage:
                "[Your custom message goes here. For example: Thank you for the talk, here is the offer we discussed.]",
            salesFirstName: "Bertram",
            salesLastName: "Wittrock",
            mustConfirmAccount: true,
            termsAndConditions: "https://portal.swiipe.com/terms_and_conditions",
            privacyPolicy: "https://portal.swiipe.com/terms_and_conditions",
        },
    },
]

export default emailTemplateTypes
