import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk } from "swiipe.portal.shared"
import { StoreState } from "../StoreState"
import { endpoints } from "./../../data/endpoints"
import { emailTemplateReducerActions, emailTemplateSelectors, IEmailTemplateImage } from "./../reducers/emailTemplateReducer"

export const getEmailTemplateImagesThunk =
    (force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force && emailTemplateSelectors.emailTemplateImages(getState())) {
            return
        }

        const result = await dispatch(requestThunk<{ images: IEmailTemplateImage[] }>(endpoints.Send.getAllEmailTemplateImages))

        dispatch(emailTemplateReducerActions.setEmailTemplateImages(result.images))
    }

export const uploadEmailTemplateImageThunk =
    (
        formImage: File,
        name: string,
        language: string,
        width: number,
        height: number,
        size: number,
        type: string
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(
            requestThunk(
                endpoints.Send.uploadEmailTemplateImage,
                {
                    data: {
                        name: name,
                        language: language,
                        width: width + "",
                        height: height + "",
                        size: size + "",
                        type: type,
                        image: formImage,
                    },
                },
                {
                    asFormData: true,
                }
            )
        )

        await dispatch(getEmailTemplateImagesThunk(true))
    }

export const deleteEmailTemplateImageThunk =
    (image: IEmailTemplateImage): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(
            requestThunk(endpoints.Send.deleteEmailTemplateImage, {
                data: {
                    name: image.name,
                    language: image.language,
                },
            })
        )

        await dispatch(getEmailTemplateImagesThunk(true))
    }
