import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk } from "swiipe.portal.shared"
import { StoreState } from "../StoreState"
import { endpoints } from "./../../data/endpoints"
import {
    emailTemplateReducerActions,
    emailTemplateSelectors,
    IEmailBaseTemplate,
    IEmailTemplate,
} from "./../reducers/emailTemplateReducer"

export const getEmailTemplatesThunk =
    (force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force && emailTemplateSelectors.emailTemplates(getState())) {
            return
        }

        const result = await dispatch(requestThunk<{ emailTemplates: IEmailTemplate[] }>(endpoints.Send.getAllEmailTemplates))

        dispatch(emailTemplateReducerActions.setEmailTemplates(result.emailTemplates))
    }

export const getEmailBaseTemplatesThunk =
    (force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force && emailTemplateSelectors.emailBaseTemplates(getState())) {
            return
        }

        const result = await dispatch(
            requestThunk<{ emailBaseTemplates: IEmailBaseTemplate[] }>(endpoints.Send.getAllEmailBaseTemplates)
        )

        dispatch(emailTemplateReducerActions.setEmailBaseTemplates(result.emailBaseTemplates))
    }

export const updateEmailTemplateThunk =
    (emailTemplate: IEmailTemplate): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk(endpoints.Send.updateEmailTemplate, {
                data: emailTemplate,
            })
        )

        await dispatch(getEmailTemplatesThunk(true))
    }

export const updateEmailBaseTemplateThunk =
    (emailBaseTemplate: IEmailBaseTemplate): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk(endpoints.Send.updateEmailBaseTemplate, {
                data: emailBaseTemplate,
            })
        )

        await dispatch(getEmailBaseTemplatesThunk(true))
    }
