import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav } from "reactstrap"
import { useSelector } from "swiipe.portal.shared"
import { filterAndSortUserRelationsForSwitchUserMenu, getIconClassForRelation } from "../../services/userRelationService"
import useReduxDispatch from "../../store/useReduxDispatch"
import { useShowLoggedInMenus } from "./../../services/authService"
import { navigationService } from "./../../services/navigationService"
import { userRelationSelectors } from "./../../store/reducers/userRelationReducer"
import { signOutThunk, switchUserThunk } from "./../../store/thunks/startupThunks"
import { IUserRelationFlattened } from "./../../type/user/IUserRelation"
import "./SwitchUserMenu.scss"

interface ISwitchUserMenuProps {}

interface IOptionProps {
    iconClass: string
    text: string
    isLogout?: boolean
}
const Option = ({ iconClass, text, isLogout }: IOptionProps) => {
    return (
        <>
            <span className={`icon ${iconClass}`} />
            <span className={cn("account-name", "d-md-inline-block", { "font-weight-bold": isLogout })}>{text}</span>
        </>
    )
}

const SwitchUserMenu = ({}: ISwitchUserMenuProps) => {
    const showLoggedInMenus = useShowLoggedInMenus()

    const dispatch = useReduxDispatch()

    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)
    let userRelationsFlattened = useSelector(userRelationSelectors.userRelationsFlattened) || []
    const masterPartnerRelation = userRelationsFlattened.find((ur) => ur.isMasterPartner)!
    userRelationsFlattened = userRelationsFlattened.filter((ur) => !ur.isMasterPartner).filter((ur) => !ur.markedDeletion)

    const lastVisitedAccounts = useSelector(userRelationSelectors.lastUsedRelations)

    const getUserRealtionsToShow = (): IUserRelationFlattened[] => {
        return filterAndSortUserRelationsForSwitchUserMenu(userRelationsFlattened, lastVisitedAccounts)
    }

    const userRelationsToShow = [
        masterPartnerRelation,
        ...(userRelationsFlattened.length > 5 ? getUserRealtionsToShow() : userRelationsFlattened),
    ]
    const [isOpen, setIsOpen] = useState(false)
    const { t } = useTranslation()

    if (!currentUserRelation || !showLoggedInMenus) {
        return null
    }

    const getOption = (userRelation: IUserRelationFlattened) => {
        return <Option iconClass={getIconClassForRelation(userRelation)} text={userRelation.name || ""} />
    }

    const onUserRelationClick = (userRelation: IUserRelationFlattened) => {
        dispatch(switchUserThunk(userRelation))
    }

    const onLogoutClick = () => {
        dispatch(signOutThunk())
    }

    return (
        <Nav navbar>
            <Dropdown nav className="navbar-dropdown" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                <DropdownToggle nav caret>
                    {getOption(currentUserRelation)}
                </DropdownToggle>
                <DropdownMenu>
                    {userRelationsToShow.slice(0, 6).map((userRelation, index) => {
                        const optionComp = getOption(userRelation)
                        if (!optionComp || userRelation.id === currentUserRelation.id) {
                            return null
                        }
                        return (
                            <DropdownItem onClick={() => onUserRelationClick(userRelation)} key={index}>
                                {optionComp}
                            </DropdownItem>
                        )
                    })}
                    {userRelationsFlattened.length > 6 && (
                        <DropdownItem onClick={() => navigationService.navigate("manageuserrelations")}>
                            <Option iconClass="icon-roleswitch-seall" text={t("menu.showAll")} />
                        </DropdownItem>
                    )}
                    <DropdownItem onClick={() => onLogoutClick()}>
                        <Option iconClass="icon-nav-logout" isLogout text={t("menu.logout")} />
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </Nav>
    )
}

export default SwitchUserMenu
