import React from "react"
import { InputProps } from "reactstrap"
import { Input } from "reactstrap"
import "./DropDownList.scss"
import cn from "classnames"

export interface IDropdownOption {
    text: string
    value: any
    label?: string
    containerClassName?: string
}

interface IDropDownListProps extends InputProps {
    options: IDropdownOption[]
}

const DropDownList = ({ options, placeholder, defaultValue, label, containerClassName, ...otherProps }: IDropDownListProps) => {
    return (
        <div className={cn("ddl-comp", containerClassName)}>
            {label && <label>{label}</label>}
            <Input type="select" {...otherProps} defaultValue={defaultValue}>
                {placeholder && (
                    <option key="" value="">
                        {placeholder}
                    </option>
                )}
                {options.map((p) => (
                    <option key={p.value} value={p.value}>
                        {p.text}
                    </option>
                ))}
            </Input>
        </div>
    )
}

export default DropDownList
