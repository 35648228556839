import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { StoreState } from "../StoreState"

export type SmsTemplateState = Readonly<{
    smsTemplates?: ISmsTemplate[]
}>

export interface ISmsTemplate {
    smsType: string
    language: string
    body: string
    defaultToNumber: string
}

const initialState: SmsTemplateState = {}

export const smsTemplateReducerActions = {
    setSmsTemplates: (smsTemplates: ISmsTemplate[]) => createAction("APP/SET_SMS_TEMPLATES", { smsTemplates }),
}

export type SmsTemplatesActions = ActionsUnion<typeof smsTemplateReducerActions>

export const smsTemplateReducer = (state: SmsTemplateState = initialState, action: SmsTemplatesActions) => {
    switch (action.type) {
        case "APP/SET_SMS_TEMPLATES": {
            return {
                ...state,
                smsTemplates: action.smsTemplates,
            }
        }
    }

    return state
}

export const smsTemplateSelectors = {
    smsTemplate: (state: StoreState, type: string, language: string) =>
        state.smsTemplate.smsTemplates?.find((st) => st.smsType === type && st.language === language),
    smsTemplates: (state: StoreState) => state.smsTemplate.smsTemplates,
}
