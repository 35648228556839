import { ReducersMapObject } from "redux"
import { getSharedRootReducer, SharedStoreState } from "swiipe.portal.shared"
import { credentialsReducer, CredentialsState } from "./reducers/credentialsReducer"
import { emailTemplateReducer, EmailTemplateState } from "./reducers/emailTemplateReducer"
import { healthStatusReducer, HealthStatusState } from "./reducers/healthStatusReducer"
import { HostedFieldsState, hostedFiledsReducer } from "./reducers/hostedFieldsReducer"
import { invoicingReducer, InvoicingState } from "./reducers/invoicingReducer"
import { layoutReducer, LayoutState } from "./reducers/layoutReducer"
import { IMerchantPreOnboardingStoreAddition, merchantPreOnboardingSlice } from "./reducers/merchantPreOnboardingSlice"
import { merchantReducer, MerchantState } from "./reducers/merchantReducer"
import { partnerReducer, PartnerState } from "./reducers/partnerReducer"
import { paymentIQReducer, PaymentIqState } from "./reducers/paymentIQReducer"
import { paymentPageReducer, PaymentPageState } from "./reducers/paymentPageReducer"
import { pluginReducer, PluginState } from "./reducers/pluginReducer"
import { plusSellReducer, PlusSellState } from "./reducers/plusSellReducer"
import { quickpayDataReducer, QuickpayState } from "./reducers/quickpayReducer"
import { releaseNotesReducer, ReleaseNotesState } from "./reducers/releaseNotesReducer"
import { revenueReducer, RevenueState } from "./reducers/revenueReducer"
import { sentEmailsReducer, SentEmailState } from "./reducers/sentEmailReducer"
import { sentSmsesReducer, SentSmsState } from "./reducers/sentSmsReducer"
import { smsTemplateReducer, SmsTemplateState } from "./reducers/smsTemplateReducer"
import { termsTemplateReducer, TermsTemplateState } from "./reducers/termsTemplateReducer"
import { testResultReducer, TestResultState } from "./reducers/testResultReducer"
import { trackedTimeReducer, TrackedTimeState } from "./reducers/trackedTimeReducer"
import { userRelationReducer, UserRelationState } from "./reducers/userRelationReducer"
import { webshopReducer, WebshopState } from "./reducers/webshopReducer"

export type StoreState = {
    quickpay: QuickpayState
    paymentPage: PaymentPageState
    hostedFields: HostedFieldsState
    layout: LayoutState
    sentEmail: SentEmailState
    emailTemplate: EmailTemplateState
    userRelation: UserRelationState
    partner: PartnerState
    merchant: MerchantState
    plugin: PluginState
    releaseNotes: ReleaseNotesState
    trackedTime: TrackedTimeState
    smsTemplate: SmsTemplateState
    sentSms: SentSmsState
    credentials: CredentialsState
    invoicing: InvoicingState
    paymentIQ: PaymentIqState
    testResult: TestResultState
    plusSell: PlusSellState
    healthStatus: HealthStatusState
    termsTemplate: TermsTemplateState
    webshop: WebshopState
    revenue: RevenueState
} & SharedStoreState &
    IMerchantPreOnboardingStoreAddition

export function createRootReducer() {
    const reducers: ReducersMapObject = {
        quickpay: quickpayDataReducer,
        paymentPage: paymentPageReducer,
        hostedFields: hostedFiledsReducer,
        layout: layoutReducer,
        sentEmail: sentEmailsReducer,
        emailTemplate: emailTemplateReducer,
        userRelation: userRelationReducer,
        partner: partnerReducer,
        merchant: merchantReducer,
        plugin: pluginReducer,
        releaseNotes: releaseNotesReducer,
        trackedTime: trackedTimeReducer,
        smsTemplate: smsTemplateReducer,
        sentSms: sentSmsesReducer,
        credentials: credentialsReducer,
        invoicing: invoicingReducer,
        paymentIQ: paymentIQReducer,
        testResult: testResultReducer,
        plusSell: plusSellReducer,
        healthStatus: healthStatusReducer,
        termsTemplate: termsTemplateReducer,
        webshop: webshopReducer,
        revenue: revenueReducer,
        merchantPreOnboarding: merchantPreOnboardingSlice.reducer,
        ...getSharedRootReducer(),
    } as any

    return reducers
}
