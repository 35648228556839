import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import {
    ISearchUsersData,
    UserDataDto,
    UserIdentityDto,
    requestThunk,
    setCulture,
    userReducerActions,
    userSelectors,
} from "swiipe.portal.shared"
import { getCultureForCountry, getCultureForLanguage, setLocaleForDatepicker } from "../../services/languageService"
import { endpoints } from "./../../data/endpoints"
import { StoreState } from "./../StoreState"
import { addModalThunk } from "./modalThunks"

export const fetchUserDataThunk =
    (force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force && userSelectors.userData(getState())) {
            // User data already fetched
            return
        }

        const userData = await dispatch(requestThunk<UserDataDto>(endpoints.Core.getUserData))
        setCulture(getCultureForLanguage(userData.user.language) || getCultureForCountry(userData.user.countryCode))
        setLocaleForDatepicker({
            language: userData.user.language,
            countryCode: userData.user.countryCode,
        })
        dispatch(userReducerActions.setUserData(userData))
    }

export const fetchUserByIdThunk =
    (userId: string, force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force && userSelectors.userById(getState(), userId)) {
            // User data already fetched
            return
        }

        const userDataResp = await dispatch(
            requestThunk<{ user: UserIdentityDto }>(endpoints.Identity.getUsersByIdAdmin, {
                params: {
                    userId,
                },
            })
        )
        dispatch(userReducerActions.setUserBaseData(userDataResp.user))
    }

export const searchForUserThunk =
    (email: string): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if ((userSelectors.searchedUsers(getState()) || []).filter((u) => u.email === email).length > 0) {
            // User data already found
            return
        }

        if (!email || email.length < 2) {
            dispatch(userReducerActions.setSearchedUsers([]))
            return
        }

        dispatch(userReducerActions.setSearchedUsers(undefined))

        const userData = await dispatch(
            requestThunk<ISearchUsersData>(endpoints.Identity.getUsersByEmail, {
                params: {
                    email: email,
                    startsWith: true,
                },
            })
        )
        dispatch(userReducerActions.setSearchedUsers(userData.users))
    }

export const deleteUserThunk =
    (id: string): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const modelResp = await dispatch(
            addModalThunk({
                type: "confirm",
                text: "Are you sure you would like to delete this user?",
                confirmButtonText: "Yes, Delete!",
            })
        )
        if (modelResp.type !== "accepted") {
            return
        }

        await dispatch(requestThunk<ISearchUsersData>(endpoints.Identity.deleteUser(id)))
        dispatch(
            userReducerActions.setSearchedUsers((userSelectors.searchedUsers(getState()) || []).filter((u) => u.userId !== id))
        )
    }
