import cn from "classnames"
import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { Form } from "reactstrap"
import { FloatingLabelInput, StandardButtonWithSpinner } from "swiipe.portal.shared"
import { IEmailTemplateImage } from "../../store/reducers/emailTemplateReducer"
import { deleteEmailTemplateImageThunk, uploadEmailTemplateImageThunk } from "../../store/thunks/emailTemplateImageThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { getFileSizeStrInKb, getImageFileDimensions } from "../../util/fileUtil"
import { valFuncRequiredAndPattern, validationPatterns } from "../../util/validationUtil"
import SubmitButton from "../buttons/SubmitButton"
import ShowErrorMessages from "../form/ShowErrorMessages"
import FileInput from "../form/input/FileInput"
import "./EmailTemplateImage.scss"

interface IEmailTemplateImageProps {
    className?: string
    language: string
    image?: IEmailTemplateImage
}

interface IForm {
    file: FileList
    name: string
}

const EmailTemplateImage = ({ className, image, language }: IEmailTemplateImageProps) => {
    const { handleSubmit, trigger, register, clearErrors, setError, setValue, getValues, formState, errors } = useForm<IForm>()
    const dispatch = useReduxDispatch()

    const onSubmit: SubmitHandler<IForm> = async (data) => {
        try {
            const file = data.file[0]
            const dimensions = await getImageFileDimensions(file)

            await dispatch(
                uploadEmailTemplateImageThunk(
                    file,
                    image?.name ?? data.name,
                    language,
                    dimensions.width,
                    dimensions.height,
                    file.size,
                    file.type
                )
            )

            setValue("name", "")
            setValue("file", "")
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    const imageExistsForLanguage = image && image.language === language
    const imageExistsForShared = image && image.language !== language && image.language === "shared"
    const imageExistsForOtherLanguage = image && image.language !== language && image.language !== "shared"

    return (
        <div className={cn("email-template-image", className)}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div
                    className={cn("image-container", {
                        green: imageExistsForLanguage,
                        blue: imageExistsForShared,
                        red: imageExistsForOtherLanguage,
                    })}
                >
                    <div className="image">{image && <img src={image.url} />}</div>
                    <div className="info-column">
                        {image && (
                            <div>
                                <div className="name">{image.name}</div>
                                {imageExistsForOtherLanguage && (
                                    <>
                                        <div>Language: {image.language}</div>
                                    </>
                                )}
                                {imageExistsForLanguage && (
                                    <>
                                        <div>Width: {image.width}</div>
                                        <div>Height: {image.height}</div>
                                        <div>Size: {getFileSizeStrInKb(image.size)}</div>
                                        <div>Type: {image.type}</div>
                                    </>
                                )}
                            </div>
                        )}
                        {!image && (
                            <div>
                                <FloatingLabelInput
                                    name="name"
                                    innerRef={register(
                                        valFuncRequiredAndPattern(
                                            validationPatterns.fileName,
                                            "Can contain small letters, numbers, dash, and underscore. Minimum length 3."
                                        )
                                    )}
                                    placeholder="Image File Name"
                                />
                            </div>
                        )}
                        {!imageExistsForLanguage && (
                            <div>
                                <FileInput<IForm>
                                    containerClass="file"
                                    name={"file"}
                                    hideUploadArea
                                    triggerValidation={trigger}
                                    clearError={clearErrors}
                                    setError={setError}
                                    setValue={setValue}
                                    getValues={getValues}
                                    register={register}
                                    minFilesAmount={1}
                                    maxFilesAmount={1}
                                    requiredErrorMessage={"File upload input error"}
                                    accept=".jpg,.png"
                                ></FileInput>
                            </div>
                        )}
                    </div>

                    <div className="actions">
                        {!imageExistsForLanguage && <SubmitButton formState={formState} title="Save" />}
                        {imageExistsForLanguage && (
                            <StandardButtonWithSpinner
                                isDelete
                                title="Delete"
                                onClick={async () => {
                                    await dispatch(deleteEmailTemplateImageThunk(image!))
                                }}
                            />
                        )}
                    </div>
                </div>
                <ShowErrorMessages<IForm> errors={errors} />
            </Form>
        </div>
    )
}

export default EmailTemplateImage
