export const regularExpressions = {
    urlRegex: new RegExp(/((?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+;=]+)/g),
}

export const getPhoneWithCountryCode = (phone: string) => {
    if (phone.includes("+")) {
        return phone
    }

    if (phone.substring(0, 2) === "00") {
        return "+" + phone.slice(2)
    }

    return "+45" + phone
}
