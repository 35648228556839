import React from "react"
import { useTranslation } from "react-i18next"
import { ModalBody } from "reactstrap"
import {
    BaseModal,
    CloseIcon,
    ICompanyData,
    IInvoicingPrice,
    IMerchantOnboardingServicesSubForm,
    IMerchantPreOnboardingInternalUser,
    IMerchantPreOnboardingOwner,
    IModalBase,
    IModalControlProps,
    MerchantOfferContentContainer,
    MerchantOfferFooter,
    MerchantOfferHeader,
    MerchantOfferIntro,
    MerchantOfferOrderSummary,
    MerchantOfferSteps,
    WebshopDetails,
} from "swiipe.portal.shared"
import "./ModalMerchantOfferPreview.scss"

export interface IModalMerchantOfferPreview extends IModalBase {
    type: "merchantOfferPreview"
    owner: IMerchantPreOnboardingOwner
    creator: IMerchantPreOnboardingInternalUser
    company: ICompanyData
    countryCode: string
    created: string
    offerMessage: string
    services: IMerchantOnboardingServicesSubForm
    webshops: WebshopDetails[]
    prices: IInvoicingPrice[]
}

const ModalMerchantOfferPreview = ({
    owner,
    creator,
    company,
    countryCode,
    created,
    offerMessage,
    services,
    webshops,
    prices,
    callback,
    ...otherProps
}: IModalMerchantOfferPreview & IModalControlProps) => {
    const { t } = useTranslation()

    return (
        <BaseModal {...otherProps} modalClassName="modal-merchant-offer-preview" callback={callback} fullSize>
            <ModalBody>
                <CloseIcon className="modal-merchant-offer-preview__close" onClick={() => callback("ignored")} />
                <MerchantOfferHeader
                    company={company}
                    creator={creator}
                    owner={owner}
                    countryCode={countryCode}
                    created={created}
                />
                <MerchantOfferContentContainer>
                    <MerchantOfferSteps
                        currentStep={"info"}
                        includeKyc={true}
                        maxStepAllowed={1}
                        onChangeStep={() => {
                            // Do nothing
                        }}
                        marginBottom={45}
                    />
                    <MerchantOfferIntro owner={owner} message={offerMessage} services={services} />
                    <MerchantOfferOrderSummary prices={prices} services={services} webshops={webshops} />
                    <MerchantOfferFooter creator={creator} />
                </MerchantOfferContentContainer>
            </ModalBody>
        </BaseModal>
    )
}

export default ModalMerchantOfferPreview
