import cn from "classnames"
import createFileList from "create-file-list"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Input, InputProps } from "reactstrap"
import { StandardButton } from "swiipe.portal.shared"
import {
    DataType,
    TClearError,
    TRegister,
    TSetError,
    TSetValue,
    TTriggerValidation,
} from "../../../type/form/ReactHooksFormTypes"
import { fileMinMaxAmountMaxSizeBytesFunc } from "../../../util/validationUtil"
import UploadedFilesOverview from "../overview/UploadedFilesOverview"
import "./FileInput.scss"

export const megabyteInBytes = 1048576
interface IFileInputProps<T> extends InputProps {
    containerClass?: string
    hideUploadArea?: boolean
    name: Extract<keyof T, string>
    accept: string
    multiple?: boolean
    minFilesAmount?: number
    maxFilesAmount?: number
    maxFileSizeBytes?: number
    requiredErrorMessage?: string
    setError: TSetError<T>
    clearError: TClearError<T>
    triggerValidation: TTriggerValidation<T>
    getValues: (name: string) => any
    setValue: TSetValue<T>
    register: TRegister
}

const FileInput = <T extends DataType>({
    containerClass,
    name,
    accept,
    multiple,
    minFilesAmount,
    maxFilesAmount,
    maxFileSizeBytes,
    requiredErrorMessage,
    setError,
    clearError,
    triggerValidation,
    setValue,
    getValues,
    register,
    hideUploadArea,
    ...otherProps
}: IFileInputProps<T>) => {
    const { t } = useTranslation()

    const [uploadedFilesList, setUploadedFilesList] = useState({} as FileList)

    const [showFilesCountError, setShowFilesCountError] = useState(false)
    const [showFileSizeError, setShowFileSizeError] = useState(false)

    const maximumFilesAmount = maxFilesAmount ? maxFilesAmount : 1
    const fileListRef = useRef<HTMLDivElement | null | undefined>()

    useEffect(() => {
        const filesKeys = Object.keys(uploadedFilesList)

        if (!filesKeys.length) {
            return
        }

        if (maxFileSizeBytes) {
            const hasToLargeFiles = filesKeys.find((key) => uploadedFilesList[key as any].size > maxFileSizeBytes)
            setShowFileSizeError(!!hasToLargeFiles)
        }

        filesKeys.length <= maximumFilesAmount ? setShowFilesCountError(false) : setShowFilesCountError(true)
    }, [uploadedFilesList])

    const updateUploadedFiles = () => {
        const newFiles: FileList = getValues(name)

        const filesToAdd: File[] = Object.keys(newFiles).map((key) => newFiles[key as any])
        const previousFiles: File[] = Object.keys(uploadedFilesList).map((key) => uploadedFilesList[key as any])

        const allFiles = createFileList(filesToAdd, previousFiles)

        setUploadedFilesList(allFiles)

        setValue(name, allFiles)
    }

    const removeFile = (index: number) => {
        const files = Object.keys(uploadedFilesList).map((key) => uploadedFilesList[key as any])
        const filesFiltered = files.filter((f, i) => (i === index ? false : true))

        const fileList = createFileList(filesFiltered)
        setUploadedFilesList(fileList)
        setValue(name, fileList)

        triggerValidation(name)
    }

    return (
        <>
            <div className={cn("swiipe-file-upload mt-3", containerClass)}>
                {!hideUploadArea && (
                    <span>
                        {t("fileupload.dropfiles")}
                        {maxFileSizeBytes && (
                            <>{t("fileupload.dropfilesmaxsize", { fileSize: maxFileSizeBytes / megabyteInBytes })}</>
                        )}
                    </span>
                )}
                <StandardButton dark noBorder noBorderRadius className="upload-btn">
                    {t("fileupload.upload")}
                </StandardButton>
                <Input
                    type="file"
                    name={name}
                    multiple={multiple}
                    accept={accept}
                    onChange={() => {
                        updateUploadedFiles()
                    }}
                    innerRef={register(
                        fileMinMaxAmountMaxSizeBytesFunc(
                            requiredErrorMessage || "",
                            minFilesAmount,
                            maxFilesAmount,
                            maxFileSizeBytes
                        )
                    )}
                    {...otherProps}
                ></Input>
            </div>
            <div
                className={cn("swiipe-files-list", {
                    "d-none": Object.keys(uploadedFilesList).length === 0,
                })}
                ref={(ref) => (fileListRef.current = ref)}
            >
                <UploadedFilesOverview
                    files={uploadedFilesList}
                    onRemoveFile={(i) => {
                        removeFile(i)
                    }}
                ></UploadedFilesOverview>
                {showFilesCountError && (
                    <span className="error">{t("fileupload.dropatleast", { filesAmount: maximumFilesAmount })}</span>
                )}
                {showFileSizeError && maxFileSizeBytes && (
                    <span className="error">
                        {t("fileupload.errorfilesize", { fileSize: maxFileSizeBytes / megabyteInBytes })}
                    </span>
                )}
            </div>
        </>
    )
}

export default FileInput
