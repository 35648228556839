import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { Form } from "reactstrap"
import { FloatingLabelInput, PageContainer, PageWrapper, sharedConfigurationSelectors, useSelector } from "swiipe.portal.shared"
import SubmitButton from "../../components/buttons/SubmitButton"
import ShowErrorMessages from "../../components/form/ShowErrorMessages"
import CodeEditor from "../../components/form/syntax/CodeEditor"
import WebshopSelect from "../../components/merchant/WebshopSelect"
import PageTitle from "../../components/page/PageTitle"
import PrefetchedDataSection from "../../components/piqConfig/PrefetchedDataSection"
import { GenerateConfigs as generateConfigs } from "../../services/piqConfigService"
import { StoreState } from "../../store/StoreState"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { paymentIQSelectors } from "../../store/reducers/paymentIQReducer"
import { getClearhausMerchantThunk } from "../../store/thunks/clearhausMerchantThunk"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { fetchOnboardingStatusThunk, fetchPiqSecrets } from "../../store/thunks/piqThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { IClearhausMerchant } from "../../type/clearhausMerchant/IClearhausMerchant"
import { IPiqSecrets } from "../../type/paymentIQ/IPiqSecrets"
import { IWebshopPIQOnboardingDetails } from "../../type/paymentIQ/IWebshopPIQOnboardingDetails"
import { valFuncRequired } from "../../util/validationUtil"
import "./PiqConfigGeneratorPage.scss"

interface IPiqConfigGeneratorPage {}

interface IForm {
    clearhausApiKey: string
}

const PiqConfigGeneratorPage = ({}: IPiqConfigGeneratorPage) => {
    const dispatch = useReduxDispatch()
    const { handleSubmit, register, errors, formState, setValue } = useForm<IForm>()

    const swMerchant = useSelector(merchantSelectors.merchantDetails)

    const initialSelectedWebshopId = useSelector(merchantSelectors.selectedWebshopId)

    const [selectedWebshopId, setSelectedWebshopId] = useState(initialSelectedWebshopId ?? swMerchant?.webshops[0].webshopId)

    const [isSubmit, setIsSubmit] = useState(true)
    const [clearhausApiKey, setClearhausApiKey] = useState("")
    const [clearhausMerchant, setClearhausMerchant] = useState<IClearhausMerchant>()
    const [piqSecrets, setPiqSecrets] = useState<IPiqSecrets>()

    const onboardingDetails: IWebshopPIQOnboardingDetails = useSelector((state: StoreState) =>
        paymentIQSelectors.onboardingDetails(state, selectedWebshopId ?? "")
    )
    const currentWebshop = swMerchant?.webshops.find((shop) => shop.webshopId == selectedWebshopId)

    useEffect(() => {
        clearData()
    }, [swMerchant, selectedWebshopId])

    const clearData = () => {
        if (!swMerchant) {
            return
        }
        setIsSubmit(false)
        setClearhausMerchant(undefined)
        dispatch(fetchOnboardingStatusThunk(selectedWebshopId ?? "", false))
    }

    const onSubmit: SubmitHandler<IForm> = async (data) => {
        try {
            setClearhausApiKey(data.clearhausApiKey)
            setClearhausMerchant(await dispatch(getClearhausMerchantThunk(data.clearhausApiKey)))
            setPiqSecrets(await fetchPiqConfigSecrets())
            if (swMerchant && selectedWebshopId) {
                await dispatch(fetchOnboardingStatusThunk(selectedWebshopId, false))
            }
            setIsSubmit(true)
        } catch (err: any) {
            await dispatch(
                addModalThunk({
                    type: "error",
                    errorMessage: err?.message,
                })
            )
        } // stops spinner
    }

    const fetchPiqConfigSecrets = async () => {
        const piqSecret = await dispatch(
            fetchPiqSecrets(currentWebshop?.webshopId ?? "", swMerchant?.swMerchant.swMerchantId ?? "")
        )
        if (!piqSecret) {
            throw new Error("Unable to fetch PIQ secrets")
        }
        return piqSecret
    }

    const securePortalUrl = useSelector((state: StoreState) => sharedConfigurationSelectors.endpointConfig(state, "SecurePortal"))

    const configData =
        swMerchant && piqSecrets && isSubmit
            ? generateConfigs({
                  merchantName: clearhausMerchant?.name ?? swMerchant?.swMerchant.name ?? "",
                  country: clearhausMerchant?.country ?? "DK",
                  piqSecrets: piqSecrets,
                  clearhausApiKey: clearhausApiKey,
                  clearhausDescriptor: clearhausMerchant?.descriptor ?? "",
                  clearhausMcc: clearhausMerchant?.mcc ?? "",
                  clearhausMerchantId: clearhausMerchant?.merchantId ?? "",
                  merchantUrl: currentWebshop?.hostName ?? "",
                  isDankort: onboardingDetails?.netsDankortId != undefined ?? false,
                  mpLogoUrl: onboardingDetails?.mobilePayShopIconUrl ?? "https://no-logo.png",
                  mpShopName: onboardingDetails?.mobilePayShopName ?? "",
                  swishId: onboardingDetails?.swishId,
                  klarnaId: piqSecrets?.klarnaSecrets?.klarnaApiId,
                  klarnaKey: piqSecrets?.klarnaSecrets?.klarnaApiKey,
                  vippsId: onboardingDetails?.vippsId,
                  vippsClientId: onboardingDetails?.vippsClientId,
                  securePortalUrl,
              })
            : undefined

    return (
        <>
            {swMerchant && (
                <WebshopSelect
                    merchantDetails={swMerchant}
                    onWebshopChange={(selectedWebshopId: string) => {
                        setSelectedWebshopId(selectedWebshopId)
                    }}
                    pageWrapperWider={true}
                />
            )}
            <PageWrapper wider>
                <PageContainer id="piq_config_generator-page">
                    <PageTitle title="Piq Config Generator" />

                    <section className="user-input-container">
                        <h4>Required information</h4>

                        <Form onSubmit={handleSubmit(onSubmit)} className="mt-4">
                            <FloatingLabelInput
                                name="clearhausApiKey"
                                placeholder="Clearhaus API key"
                                hintInsideInput="The Clearhaus API key can be found in clearhaus dashboard for the given merchant."
                                innerRef={register(valFuncRequired("Clearhaus API key required"))}
                            />
                            <SubmitButton formState={formState} isLarge containerClass="save-button">
                                Submit
                            </SubmitButton>
                        </Form>
                        <ShowErrorMessages<IForm> errors={errors} />
                    </section>
                    {isSubmit && (
                        <section className="loaded-data-container">
                            <PrefetchedDataSection
                                merchantName={swMerchant?.swMerchant.name ?? ""}
                                url={currentWebshop?.hostName ?? ""}
                                country={clearhausMerchant?.country ?? "DK"}
                                clearhausName={clearhausMerchant?.name}
                                clearhausDescriptor={clearhausMerchant?.descriptor}
                                clearhausMcc={clearhausMerchant?.mcc}
                                clearhausMid={clearhausMerchant?.merchantId}
                                mpName={onboardingDetails?.mobilePayShopName}
                                mpLogo={onboardingDetails?.mobilePayShopIconUrl ?? "https://no-logo.png"}
                                netsDankortId={onboardingDetails?.netsDankortId}
                                netsForbrugsforeningenId={onboardingDetails?.netsForbrugsforeningenId}
                                swishId={onboardingDetails?.swishId}
                                klarnaId={piqSecrets?.klarnaSecrets?.klarnaApiId}
                                klarnaKey={piqSecrets?.klarnaSecrets?.klarnaApiKey}
                                vippsId={onboardingDetails?.vippsId}
                            />
                        </section>
                    )}
                    {isSubmit && (
                        <section className="configs-container">
                            <h4>Generated configs</h4>
                            {configData?.configs.map((config, index) => {
                                if (!config.config) {
                                    return <></>
                                }
                                return (
                                    <CodeEditor
                                        key={config.label}
                                        code={config.config ?? ""}
                                        label={config.label}
                                        language="xml"
                                        maxHeight={150}
                                        showCopyButton={true}
                                    />
                                )
                            })}
                        </section>
                    )}
                </PageContainer>
            </PageWrapper>
        </>
    )
}

export default PiqConfigGeneratorPage
