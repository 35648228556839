import cn from "classnames"
import React from "react"
import { StandardBox } from "swiipe.portal.shared"
import "./FormActionSection.scss"

interface IFormActionSectionProps {
    className?: string
    left?: JSX.Element
    middle?: JSX.Element
    right?: JSX.Element
    leftAlignment?: "full" | "left" | "right" | "center"
    middleAlignment?: "full" | "left" | "right" | "center"
    rightAlignment?: "full" | "left" | "right" | "center"
}

export const FormActionSection = ({
    className,
    left,
    middle,
    right,
    leftAlignment,
    middleAlignment,
    rightAlignment,
}: IFormActionSectionProps) => {
    return (
        <StandardBox className={cn("form-action-section", "mt-2", className)} padding="15-20" margin="0" borders="rounded">
            <div className={cn("form-action-section__left", "form-action-section--alignment-" + (leftAlignment ?? "center"))}>
                {left}
            </div>
            <div className={cn("form-action-section__middle", "form-action-section--alignment-" + (middleAlignment ?? "full"))}>
                {middle}
            </div>
            <div className={cn("form-action-section__right", "form-action-section--alignment-" + (rightAlignment ?? "center"))}>
                {right}
            </div>
        </StandardBox>
    )
}
