import React from "react"
import { FormGroup } from "reactstrap"
import cn from "classnames"
import "./FieldsetFormGroup.scss"

interface IFieldsetFormGroupProps {
    className?: string
    forCheckbox?: boolean
    field?: boolean
    col2?: boolean
    tight?: boolean
    tightX?: boolean
    narrowX?: boolean
    first?: boolean
    second?: boolean
    children?: React.ReactNode
}

const FieldsetFormGroup = ({
    className,
    children,
    forCheckbox,
    field,
    tight,
    tightX,
    narrowX,
    col2,
    first,
    second,
}: IFieldsetFormGroupProps) => {
    return (
        <FormGroup
            check={forCheckbox}
            className={cn("swiipe-fieldset-form-group", className, {
                "form-group": forCheckbox,
                tight,
                sw__field: field && !col2,
                sw__field_6: field && col2,
                "col-sm-6": field && col2,
                "pr-sm-1": (tight || tightX) && first,
                "pl-sm-1": (tight || tightX) && second,
                "pr-sm-2": narrowX && first,
                "pl-sm-2": narrowX && second,
            })}
            tag="fieldset"
        >
            {children}
        </FormGroup>
    )
}

export default FieldsetFormGroup
