import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { PageContainer, PageWrapper, ensureInternalCommentsThunk, useSelector } from "swiipe.portal.shared"
import WebshopSelect from "../../../components/merchant/WebshopSelect"
import TrackedTimeList from "../../../components/trackedTime/TrackedTimeList"
import { removeSchemeAndWWWFromHostName } from "../../../services/merchantService"
import { useRequiredUserRelations } from "../../../services/userRelationService"
import { StoreState } from "../../../store/StoreState"
import { merchantSelectors } from "../../../store/reducers/merchantReducer"
import { paymentIQSelectors } from "../../../store/reducers/paymentIQReducer"
import { fetchOnboardingStatusThunk } from "../../../store/thunks/piqThunks"
import useReduxDispatch from "../../../store/useReduxDispatch"
import "./MerchantDetailsPage.scss"
import BusinessInfoSection from "./businessInfoSection/BusinessInfoSection"
import DataRow from "./dataRow/DataRow"

interface IMerchantDetailsPage {}

const MerchantDetailsPage = ({}: IMerchantDetailsPage) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    useRequiredUserRelations([{ requiredType: "Merchant" }])

    const initialSelectedWebshopId = useSelector(merchantSelectors.selectedWebshopId)
    const swMerchant = useSelector(merchantSelectors.merchantDetails)
    const [selectedWebshopId, setSelectedWebshopId] = useState(initialSelectedWebshopId ?? swMerchant?.webshops[0].webshopId)

    const currentWebshop = swMerchant?.webshops.find((shop) => shop.webshopId == selectedWebshopId)

    useEffect(() => {
        if (!selectedWebshopId) {
            return
        }
        dispatch(fetchOnboardingStatusThunk(selectedWebshopId, false))
    }, [selectedWebshopId])

    useEffect(() => {
        if (swMerchant) {
            dispatch(ensureInternalCommentsThunk(swMerchant.swMerchant.swMerchantId, false))
        }
    }, [swMerchant])

    const onboardingDetails = useSelector((state: StoreState) =>
        paymentIQSelectors.onboardingDetails(state, selectedWebshopId ?? "")
    )

    return (
        <>
            {swMerchant && (
                <WebshopSelect
                    merchantDetails={swMerchant}
                    onWebshopChange={(selectedWebshopId: string) => {
                        setSelectedWebshopId(selectedWebshopId)
                    }}
                    pageWrapperWider={true}
                ></WebshopSelect>
            )}
            <PageWrapper wider>
                <PageContainer id="merchant-details-page">
                    <section className="merchant-details-container">
                        <h4>
                            <b>{t("merchantDetails.merchantInfoTitle")}</b>
                        </h4>
                        <h6>
                            <b>{swMerchant?.swMerchant?.name}</b>
                        </h6>
                        <br />
                        <DataRow attributeName="Merchant Id" value={swMerchant?.swMerchant?.swMerchantId} />
                        <DataRow
                            attributeName={t("merchantDetails.merchantCvr")}
                            value={swMerchant?.swMerchant?.registrationNumber}
                        />
                        {swMerchant?.swMerchantContacts.map((c, index) => (
                            <div key={index}>
                                <hr />
                                <DataRow attributeName={t("merchantDetails.merchantContactName")} value={c.name} />
                                <DataRow attributeName={t("merchantDetails.merchantContactPhone")} value={c.phone} />
                                <DataRow attributeName={t("merchantDetails.merchantContactEmail")} value={c.email} />
                                <DataRow
                                    attributeName={t("merchantDetails.merchantContactComment")}
                                    value={c.comment}
                                    withoutCopyBtn
                                />
                            </div>
                        ))}
                    </section>
                    <section className="merchant-details-container">
                        <h4>
                            <b>{t("merchantDetails.webshopInfoTitle")}</b>
                        </h4>
                        <h6>
                            <b>{removeSchemeAndWWWFromHostName(currentWebshop?.hostName ?? "")}</b>
                        </h6>
                        <br />
                        <DataRow attributeName="Webshop Id" value={currentWebshop?.webshopId} />
                        <DataRow attributeName="Client id" value={currentWebshop?.clientId} />
                        <DataRow attributeName="PaymentIQ MID" value={onboardingDetails?.piqMerchantId} />
                        <DataRow attributeName="Platform" value={currentWebshop?.platform} />
                        <DataRow attributeName="MCC" value={currentWebshop?.mcc} />
                        {!!onboardingDetails?.netsDankortId && (
                            <DataRow attributeName="Dankort Id" value={onboardingDetails?.netsDankortId} />
                        )}
                    </section>
                    <BusinessInfoSection selectedWebshopId={selectedWebshopId} />
                </PageContainer>
                <PageContainer id="merchant-details-page">
                    <section>
                        <h4>
                            <b>Time spent on support</b>
                        </h4>
                        <TrackedTimeList selectedWebshopId={selectedWebshopId} />
                    </section>
                </PageContainer>
            </PageWrapper>
        </>
    )
}

export default MerchantDetailsPage
