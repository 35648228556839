import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { StoreState } from "../StoreState"

export type SentEmailState = Readonly<{
    emails?: ISentEmail[]
    resentEmailsIds: string[]
    search: string
}>

export interface ISentEmail {
    emailId: string
    created: string
    type: string
    sentToName: string
    sentToEmail: string
    sentCcToEmails: string[]
    sentBccToEmails: string[]
    subject: string
    body: string
    simulatedOnly: boolean
}

const initialState: SentEmailState = { resentEmailsIds: [], search: "" }

export const sentEmailReducerActions = {
    setSearch: (search: string) => createAction("APP/SET_SENT_EMAILS_SEARCH", { search }),
    resetEmails: () => createAction("APP/RESET_SENT_EMAILS"),
    setEmails: (emails: ISentEmail[]) => createAction("APP/SET_SENT_EMAILS", { emails }),
    addResentEmail: (emailId: string) => createAction("APP/ADD_RESENT_EMAIL", { emailId }),
}

export type SentEmailsActions = ActionsUnion<typeof sentEmailReducerActions>

export const sentEmailsReducer = (state: SentEmailState = initialState, action: SentEmailsActions) => {
    switch (action.type) {
        case "APP/SET_SENT_EMAILS": {
            return {
                ...state,
                emails: action.emails,
            }
        }
        case "APP/ADD_RESENT_EMAIL": {
            return {
                ...state,
                resentEmailsIds: [...state.resentEmailsIds, action.emailId],
            }
        }
        case "APP/SET_SENT_EMAILS_SEARCH": {
            return {
                ...state,
                search: action.search,
            }
        }
        case "APP/RESET_SENT_EMAILS": {
            return {
                ...state,
                emails: undefined,
            }
        }
    }

    return state
}

export const sentEmailSelectors = {
    emails: (state: StoreState) => state.sentEmail.emails,
    search: (state: StoreState) => state.sentEmail.search,
    resentEmailsIds: (state: StoreState) => state.sentEmail.resentEmailsIds,
}
