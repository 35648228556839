import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { IPaymentPageData } from "../../type/paymentPage/IPaymentPageData"
import { StoreState } from "../StoreState"

export type PaymentPageState = Readonly<{
    data?: IPaymentPageData
    dataWasFetched: boolean
}>
const initialState: PaymentPageState = { data: undefined, dataWasFetched: false }

export const paymentPageReducerActions = {
    addPaymentPageData: (data?: IPaymentPageData) => createAction("APP/ADD_PAYMENT_PAGE_DATA", { data }),
}

export type PaymentPageActions = ActionsUnion<typeof paymentPageReducerActions>

export const paymentPageReducer = (state: PaymentPageState = initialState, action: PaymentPageActions) => {
    switch (action.type) {
        case "APP/ADD_PAYMENT_PAGE_DATA": {
            return {
                ...state,
                data: action.data,
                dataWasFetched: true,
            }
        }
    }

    return state
}

export const paymentPageSelectors = {
    data: (state: StoreState) => state.paymentPage.data,
    dataWasFetched: (state: StoreState) => state.paymentPage.dataWasFetched,
}
