import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Form, FormGroup } from "reactstrap"
import { FloatingLabelInput, PageContainer, PageWrapper, StandardButton, TextButton } from "swiipe.portal.shared"
import SpinnerContainer from "../../components/buttons/SpinnerContainer"
import SubmitButton from "../../components/buttons/SubmitButton"
import ShowErrorMessages from "../../components/form/ShowErrorMessages"
import WebshopSelect from "../../components/merchant/WebshopSelect"
import PageTitle from "../../components/page/PageTitle"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { StoreState } from "../../store/StoreState"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { IQuickpayDataDto, quickpayDataSelectors } from "../../store/reducers/quickpayReducer"
import { addModalThunk } from "../../store/thunks/modalThunks"
import {
    deleteQuickpayMerchant,
    getQuickpayMerchantDataForWebshopThunk,
    registerQuickpayMerchant,
} from "../../store/thunks/quickpayThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { valFuncRequired } from "../../util/validationUtil"
import "./SwiipeEnsureOnboardingPage.scss"

interface ISwiipeEnsureOnboardingPage {}

interface IForm {
    clearhausApiKey: string
}

const SwiipeEnsureOnboardingPage = ({}: ISwiipeEnsureOnboardingPage) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const { handleSubmit, register, errors, formState } = useForm<IForm>()

    useRequiredUserRelations([{ requiredType: "Merchant" }])

    const initialSelectedWebshopId = useSelector(merchantSelectors.selectedWebshopId)

    const [isLoading, setIsLoading] = useState(false)
    const swMerchant = useSelector(merchantSelectors.merchantDetails)
    const [selectedWebshopId, setSelectedWebshopId] = useState(
        initialSelectedWebshopId || swMerchant?.webshops[0].webshopId || ""
    )

    const swMerchantId = swMerchant?.swMerchant.swMerchantId || ""
    useEffect(() => {
        dispatch(getQuickpayMerchantDataForWebshopThunk(swMerchantId, selectedWebshopId, false))
    }, [selectedWebshopId])

    const quickPayData = useSelector<StoreState, IQuickpayDataDto | undefined>((state) =>
        quickpayDataSelectors.getQuickpayData(state, swMerchantId, selectedWebshopId)
    )

    useEffect(() => {
        setIsLoading(!quickPayData)
    }, [quickPayData])

    if (!swMerchant) return null

    const onSubmit: SubmitHandler<IForm> = async (data) => {
        try {
            await dispatch(
                registerQuickpayMerchant({
                    swMerchantId,
                    webshopId: selectedWebshopId,
                    clearhausApiKey: data.clearhausApiKey,
                })
            )
        } catch (err: any) {
            await dispatch(
                addModalThunk({
                    type: "error",
                    errorMessage: err?.message,
                })
            )
        }
    }

    const qpMerchantId = quickPayData?.qpMerchantId
    const renderRegistrationForm = !isLoading && !qpMerchantId

    return (
        <>
            <WebshopSelect
                merchantDetails={swMerchant}
                onWebshopChange={(selectedWebshopId: string) => {
                    setSelectedWebshopId(selectedWebshopId)
                }}
                pageWrapperWider={true}
            ></WebshopSelect>
            <PageWrapper wider>
                <PageContainer id="ensure-onboarding-page">
                    <PageTitle title="Swiipe Ensure Onboarding" />
                    <SpinnerContainer showSpinner={isLoading} className="spinner-container">
                        {qpMerchantId && (
                            <div className="onboarding-data-container">
                                <div className="merchant-id">Quickpay MerchantID: {qpMerchantId}</div>
                                <TextButton
                                    text="Delete"
                                    textDecoration="underline"
                                    color="red"
                                    onClick={async () => {
                                        const modalAnswer = await dispatch(
                                            addModalThunk({
                                                type: "confirm",
                                                text: "Are you sure you want to remove Swiipe Ensure for this Merchant?",
                                                confirmButtonText: "Yes, delete",
                                            })
                                        )

                                        if (modalAnswer.type !== "accepted") {
                                            return
                                        }

                                        await dispatch(
                                            deleteQuickpayMerchant({
                                                swMerchantId,
                                                webshopId: selectedWebshopId,
                                            })
                                        )
                                    }}
                                />
                                <StandardButton
                                    className="copy-btn"
                                    dark
                                    onClick={() => navigator.clipboard.writeText(qpMerchantId)}
                                >
                                    Copy
                                </StandardButton>
                            </div>
                        )}
                        {renderRegistrationForm && (
                            <div className="user-input-container">
                                <div className="header">Required information</div>

                                <Form onSubmit={handleSubmit(onSubmit)} className="mt-4 mb-3">
                                    <FormGroup>
                                        <FloatingLabelInput
                                            name="clearhausApiKey"
                                            placeholder="Clearhaus API key"
                                            hintInsideInput="The Clearhaus API key can be found in clearhaus dashboard for the given merchant."
                                            innerRef={register(valFuncRequired("Clearhaus API key required"))}
                                        />
                                    </FormGroup>
                                    <SubmitButton formState={formState} dark containerClass="save-button">
                                        Submit
                                    </SubmitButton>
                                </Form>
                                <ShowErrorMessages<IForm> errors={errors} />
                            </div>
                        )}
                    </SpinnerContainer>
                </PageContainer>
            </PageWrapper>
        </>
    )
}

export default SwiipeEnsureOnboardingPage
