import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { IGetTrackedTimeDto } from "../../type/trackedTime/IGetTrackedTimeDto"
import { StoreState } from "../StoreState"

export type TrackedTimeState = Readonly<{
    trackedTime: { [organizationId: string]: IGetTrackedTimeDto[] }
}>

const initialState: TrackedTimeState = { trackedTime: {} }

export const trackedTimeReducerActions = {
    setTrackedTime: (organizationId: string, trackedTimeList: IGetTrackedTimeDto[]) =>
        createAction("APP/SET_TRACKED_TIME", { organizationId, trackedTimeList }),
}

export type TrackedTimeActions = ActionsUnion<typeof trackedTimeReducerActions>

export const trackedTimeReducer = (state: TrackedTimeState = initialState, action: TrackedTimeActions) => {
    switch (action.type) {
        case "APP/SET_TRACKED_TIME": {
            return {
                ...state,
                trackedTime: {
                    ...state.trackedTime,
                    [action.organizationId]: action.trackedTimeList,
                },
            }
        }
    }

    return state
}

export const trackedTimeSelectors = {
    trackedTime: (state: StoreState, organizationId: string) => state.trackedTime.trackedTime[organizationId],
}
