import React, { Children } from "react"
import CodeEditor from "../form/syntax/CodeEditor"
import "./TemplateDataExample.scss"

interface ITemplateDataExampleProps {
    data: { [key: string]: any }
}

const TemplateDataExample = ({ data }: ITemplateDataExampleProps) => {
    return (
        <div className="template-data-example">
            <CodeEditor className="data-example" label="Data Example" code={JSON.stringify(data, undefined, 2)} language="json" />
        </div>
    )
}

export default TemplateDataExample
