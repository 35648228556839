export async function getImageFileDimensions(file: File): Promise<{ width: number; height: number }> {
    return new Promise((resolve) => {
        const fr = new FileReader()

        fr.onload = function () {
            // file is loaded
            const img = new Image()

            img.onload = function () {
                resolve({ width: img.width, height: img.height })
            }
            img.src = fr.result as string // is the data URL because called with readAsDataURL
        }

        fr.readAsDataURL(file)
    })
}

export function getFileSizeStrInKb(sizeInBytes: number) {
    return Math.round(sizeInBytes / 100) / 10 + " kB"
}
