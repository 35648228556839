import React, { Children } from "react"
import CodeEditor from "../form/syntax/CodeEditor"
import "./EmailBaseTemplateLegend.scss"

interface IEmailBaseTemplateLegendProps {}

const EmailBaseTemplateLegend = ({}: IEmailBaseTemplateLegendProps) => {
    const legendData: { code: string; text: string }[] = [
        {
            code: `<Placeholder MyPlaceholderName />`,
            text: "Add a placeholder where the email template can add html into. Here MyPlaceholderName is the identifier.",
        },
        {
            code: `<Placeholder MyPlaceholderName>My <bold>default</bold> content</Placeholder>`,
            text: "An alternative way of adding a placeholder with default content used, if the email does not provide content for the placeholder.",
        },
        {
            code: `[[my-image-name]]`,
            text: "The url of the image my-image-name will be inserted. This can e.g. be used in img-tag src property.",
        },
    ]

    return (
        <div className="email-base-template-legend">
            {legendData.map((d, i) => {
                return (
                    <div key={i} className="legend">
                        <CodeEditor code={d.code} language="html" />
                        <div className="text">{d.text}</div>
                    </div>
                )
            })}
        </div>
    )
}

export default EmailBaseTemplateLegend
