import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { StandardButton, useSelector } from "swiipe.portal.shared"
import { StoreState } from "../../store/StoreState"
import { releaseNotesSelectors } from "../../store/reducers/releaseNotesReducer"
import { sortByValueOrder } from "../../util/arrayUtil"
import "./ReleaseNoteDisplay.scss"

interface IReleaseNoteDisplay {
    releaseNoteId: string
    languages: string[]
    onEditReleaseNote: (crossLanguageId: string) => void
}

const ReleaseNoteDisplay = ({ releaseNoteId, languages, onEditReleaseNote }: IReleaseNoteDisplay) => {
    const { t } = useTranslation()

    const releaseNotes = useSelector(releaseNotesSelectors.releaseNotes)
    const [shownNoteId, setShownNoteId] = useState(releaseNoteId)
    const shownNote = useSelector((state: StoreState) => releaseNotesSelectors.releaseNoteById(state, shownNoteId))

    if (!shownNote) {
        return null
    }

    return (
        <div id="release-note-display">
            <p className="version">{shownNote.version}</p>
            <p className="date">{shownNote.createdDate}</p>
            <div className="language-switcher">
                {sortByValueOrder(
                    releaseNotes?.filter((rn) => rn.crossLanguageId === shownNote.crossLanguageId) ?? [],
                    (rn) => rn.language,
                    languages.map((l) => l.toUpperCase())
                ).map((rn) => {
                    return (
                        <div
                            key={rn.releaseNoteId}
                            onClick={() => setShownNoteId(rn.releaseNoteId)}
                            className={cn("language-option", rn.releaseNoteId === shownNoteId && "active")}
                        >
                            {rn.language}
                        </div>
                    )
                })}
            </div>
            <div dangerouslySetInnerHTML={{ __html: shownNote.htmlBody }}></div>
            {shownNote.internalHtmlBody && (
                <div>
                    <i>Internal:</i>
                    <div dangerouslySetInnerHTML={{ __html: shownNote.internalHtmlBody }}></div>
                </div>
            )}
            <StandardButton
                inverted
                noBorderRadius
                className="edit-button"
                onClick={() => onEditReleaseNote(shownNote.crossLanguageId!)}
            >
                {t("releasenotes.display.edit")}
            </StandardButton>
        </div>
    )
}

export default ReleaseNoteDisplay
