import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk } from "swiipe.portal.shared"
import { parsePlusSellSessionEvents } from "../../services/plusSellService"
import { IPlusSellReport } from "../../type/plusSell/IPlusSellReport"
import { IPlusSellSession } from "../../type/plusSell/IPlusSellSession"
import { StoreState } from "../StoreState"
import { plusSellReducerActions, plusSellSelectors } from "../reducers/plusSellReducer"
import { userRelationSelectors } from "../reducers/userRelationReducer"
import { endpoints } from "./../../data/endpoints"
import { ensureUserRelationsThunk } from "./userRelationThunks"

export const ensureAggregatedPlusSellReportThunk =
    (webshopId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())

        if (!currentUserRelation || currentUserRelation.relationType !== "Merchant") {
            return
        }

        if (plusSellSelectors.plusSellAggregatedReport(getState(), webshopId)) {
            return
        }

        const { reports } = await dispatch(
            requestThunk<{
                reports: IPlusSellReport[]
            }>(endpoints.Analytics.getPlusSellReports, {
                params: { swMerchantId: currentUserRelation.id, webshopId },
            })
        )

        const aggregatedReport = reports.reduce((agg, rep) => {
            if (!agg) {
                return { ...rep }
            }
            agg.addProductClicks += rep.addProductClicks
            agg.placePlusSellOrders += rep.placePlusSellOrders
            agg.noRecommendationsSessions += rep.noRecommendationsSessions
            agg.plusSellStarts += rep.plusSellStarts
            agg.relevantSessions += rep.relevantSessions
            agg.showConfModals += rep.showConfModals
            return agg
        }, undefined as IPlusSellReport | undefined)

        if (!aggregatedReport) {
            return
        }

        dispatch(plusSellReducerActions.setPlusSellAggregatedReport(webshopId, aggregatedReport))
    }

export const fetchPlusSellSessionsThunk =
    (webshopId: string, useContinuationToken = false): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(ensureUserRelationsThunk())

        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation || currentUserRelation.relationType !== "Merchant") {
            return
        }

        if (!useContinuationToken && plusSellSelectors.plusSellSessions(getState(), webshopId)) {
            return
        }

        const previousContinuationToken = useContinuationToken
            ? plusSellSelectors.plusSellSessionsContinuationToken(getState(), webshopId)
            : undefined

        if (useContinuationToken && !previousContinuationToken) {
            return
        }

        const { sessions, continuationToken } = await dispatch(
            requestThunk<{
                sessions: IPlusSellSession[]
                continuationToken: string
            }>(endpoints.Analytics.getPlusSellSessions, {
                params: {
                    swMerchantId: currentUserRelation.id,
                    webshopId,
                    perPage: 100,
                    continuationToken: previousContinuationToken,
                },
            })
        )

        dispatch(
            plusSellReducerActions.setPlusSellSessions(
                webshopId,
                sessions.map((ses) => ({
                    ...ses,
                    parsedEvents: parsePlusSellSessionEvents(ses.events),
                })),
                continuationToken
            )
        )
    }
