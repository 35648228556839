import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { Form } from "reactstrap"
import {
    FloatingLabelInput,
    PageContainer,
    PageWrapper,
    StandardButton,
    StandardButtonWithSpinner,
    useSelector,
    userSelectors,
} from "swiipe.portal.shared"
import SubmitButton from "../../components/buttons/SubmitButton"
import ShowErrorMessages from "../../components/form/ShowErrorMessages"
import DropDownList from "../../components/form/input/DropDownList"
import CodeEditor from "../../components/form/syntax/CodeEditor"
import PageTitle from "../../components/page/PageTitle"
import TemplateDataExample from "../../components/template/TemplateDataExample"
import TemplateLegend from "../../components/template/TemplateLegend"
import { languages } from "../../data/languages"
import smsTemplateTypes from "../../data/smsTemplateTypes"
import { StoreState } from "../../store/StoreState"
import { smsTemplateSelectors } from "../../store/reducers/smsTemplateReducer"
import { getSmsTemplatesThunk, updateSmsTemplateThunk } from "../../store/thunks/smsTemplateThunks"
import { sendTestSmsThunk } from "../../store/thunks/smsThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { valFuncPattern, validationPatterns } from "../../util/validationUtil"
import "./TemplatePage.scss"

interface ISmsTemplatePage {}

interface IForm {
    toNumber: string
}

const SmsTemplatePage = ({}: ISmsTemplatePage) => {
    const dispatch = useReduxDispatch()
    const { handleSubmit, register, errors, formState, setValue } = useForm<IForm>()

    const [showHelp, setShowHelp] = useState(false)
    const [showExampleData, setShowExampleData] = useState(false)
    const [body, setBody] = useState("")
    const [smsTemplateType, setSmsTemplateType] = useState(smsTemplateTypes[0].type)
    const [language, setLanguage] = useState(languages[0].code)

    const userData = useSelector(userSelectors.userData)

    const smsTemplate = useSelector((state: StoreState) => smsTemplateSelectors.smsTemplate(state, smsTemplateType, language))

    const exampleData = smsTemplateTypes.find((t) => t.type === smsTemplateType)?.exampleData ?? {}

    useEffect(() => {
        dispatch(getSmsTemplatesThunk(false))
    }, [])

    useEffect(() => {
        setBody(smsTemplate?.body ?? "")
        setValue("toNumber", smsTemplate?.defaultToNumber ?? "")
    }, [smsTemplate])

    if (!userData) {
        return null
    }

    const onSubmit: SubmitHandler<IForm> = async (data, e) => {
        try {
            await dispatch(
                updateSmsTemplateThunk({
                    body,
                    smsType: smsTemplateType,
                    language: language,
                    defaultToNumber: data.toNumber,
                })
            )
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    return (
        <PageWrapper fullWidth>
            <PageContainer id="template-page">
                <PageTitle title="Update SMS Template" />
                <div className="ddl-container">
                    <DropDownList
                        label="Sms Template Type"
                        containerClassName="template-type"
                        value={smsTemplateType}
                        onChange={(v) => setSmsTemplateType(v.target.value)}
                        options={smsTemplateTypes.map((x) => ({ text: x.label, value: x.type }))}
                    />
                    <DropDownList
                        label="Language"
                        containerClassName="language"
                        value={language}
                        onChange={(v) => setLanguage(v.target.value)}
                        options={languages.map((x) => ({ text: x.label, value: x.code }))}
                    />
                </div>
                <label>Actions</label>
                <div className="actions">
                    <StandardButton
                        className="show-help"
                        isSmall
                        invertedBlue
                        title={showHelp ? "Hide help" : "Show help"}
                        onClick={() => setShowHelp(!showHelp)}
                    />
                    <StandardButton
                        isSmall
                        invertedBlue
                        title={showExampleData ? "Hide Example Data" : "Show Example Data"}
                        onClick={() => setShowExampleData(!showExampleData)}
                    />
                    <StandardButtonWithSpinner
                        containerClass="send-test-btn"
                        title="Send To Myself (Must save first)"
                        isSmall
                        onClick={async () => {
                            if (!smsTemplate) {
                                alert("You need to save the template first")
                                return
                            }

                            if (!userData || !userData.user.phone) {
                                alert("You need to add your phone number in Portal")
                                return
                            }

                            await dispatch(sendTestSmsThunk(smsTemplate, exampleData))
                        }}
                    />
                </div>
                {showExampleData && <TemplateDataExample data={exampleData} />}
                {showHelp && <TemplateLegend templateType="sms" />}

                <Form onSubmit={handleSubmit(onSubmit)} className="mt-5">
                    <div className="address-container">
                        <FloatingLabelInput
                            name="toNumber"
                            defaultValue={smsTemplate?.defaultToNumber ?? ""}
                            innerRef={register(valFuncPattern(validationPatterns.phone, "Wrong number format"))}
                            placeholder="Default to number"
                        />
                        <SubmitButton formState={formState} isLarge containerClass="save-button">
                            Save SMS Template
                        </SubmitButton>
                    </div>
                    <ShowErrorMessages<IForm> errors={errors} />
                </Form>
                <CodeEditor code={body} label="Body" onChange={(code) => setBody(code)} language="html" minHeight={300} />
            </PageContainer>
        </PageWrapper>
    )
}

export default SmsTemplatePage
