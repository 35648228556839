import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { StoreState } from "../StoreState"
import { Credentials } from "../../type/merchant/Credentials"

export type CredentialsState = Readonly<{
    credentials: {
        [webshopId: string]: Credentials[] | undefined
    }
}>

const initialState: CredentialsState = { credentials: {} }

export const credentialsReducerActions = {
    setCredentials: (webshopId: string, credentials: Credentials[]) =>
        createAction("APP/SET_CREDENTIALS", { webshopId, credentials }),
}

export type CredentialsActions = ActionsUnion<typeof credentialsReducerActions>

export const credentialsReducer = (state: CredentialsState = initialState, action: CredentialsActions) => {
    switch (action.type) {
        case "APP/SET_CREDENTIALS": {
            return {
                ...state,
                credentials: {
                    ...state.credentials,
                    [action.webshopId]: action.credentials,
                },
            }
        }
    }

    return state
}

export const credentialsSelectors = {
    credentials: (state: StoreState, webshopId: string) => state.credentials.credentials[webshopId],
}
