import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk, userSelectors } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import {
    getCurrentUserRelationFromLocalStorage,
    getLastUsedRelationsFromLocalStorage,
    setLastUsedRelationsToLocalStorage,
} from "../../services/userRelationService"
import { IUserRelationFlattened } from "../../type/user//IUserRelation"
import { IRelationSearchData } from "../../type/user/IRelationSearchData"
import { getQueryValueFromCurrentUrl, removeQueryValueFromCurrentUrl } from "../../util/urlUtil"
import { StoreState } from "../StoreState"
import { userRelationReducerActions, userRelationSelectors } from "../reducers/userRelationReducer"
import { switchUserThunk } from "./../../store/thunks/startupThunks"
import { IUserRelations } from "./../../type/user/IUserRelation"
import { ensureMasterPartnerThunk } from "./partnerThunks"
import { fetchUserDataThunk } from "./userThunks"

export const ensureUserRelationsThunk =
    (force?: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentRelation = userRelationSelectors.currentUserRelation(getState())
        if (currentRelation && !force) {
            // Already set up
            return
        }

        const resp = await dispatch(requestThunk<IUserRelations>(endpoints.Core.getUserRelations))

        const userRelations = resp.relations.filter((ur) => ur.relationType !== "User")

        dispatch(userRelationReducerActions.setUserRelations(userRelations))
        const relations = userRelationSelectors.userRelationsFlattened(getState()) || []

        let newCurrentRelation = undefined
        if (!newCurrentRelation) {
            newCurrentRelation = getCurrentUserRelationFromLocalStorage(relations)
        }
        if (!newCurrentRelation) {
            newCurrentRelation = relations.find((r) => r.isMasterPartner)!
        }
        dispatch(userRelationReducerActions.setCurrentRelation(newCurrentRelation))
        dispatch(ensureMasterPartnerThunk(false))
        dispatch(ensureLastUsedRelationsThunk())
    }

export const ensureSwitchUserRelationsFromUrl =
    (): ThunkAction<Promise<boolean>, StoreState, null, AnyAction> => async (dispatch, getState) => {
        const id = getQueryValueFromCurrentUrl("relationId")
        const relations = userRelationSelectors.userRelationsFlattened(getState()) || []
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())

        if (!currentUserRelation || !id) {
            return false
        }

        if (currentUserRelation.id === id) {
            return false
        }

        const newUserRelation = relations.find((r) => r.id === id)

        if (!newUserRelation) {
            return false
        }
        removeQueryValueFromCurrentUrl("relationId")
        dispatch(switchUserThunk(newUserRelation))
        return true
    }

export const ensureLastUsedRelationsThunk =
    (): ThunkAction<Promise<void>, StoreState, null, AnyAction> => async (dispatch, getState) => {
        await dispatch(fetchUserDataThunk(false))
        const lastUsedRelations = userRelationSelectors.lastUsedRelations(getState())
        const userData = userSelectors.userData(getState())
        const userId = userData?.user.id ?? ""
        if (lastUsedRelations.length === 0) {
            const userRelationsFlattened = userRelationSelectors.userRelationsFlattened(getState()) || []
            const lastUsedRelationsFromStorage = getLastUsedRelationsFromLocalStorage(userId, userRelationsFlattened)

            if (lastUsedRelationsFromStorage) {
                //if any of id's in localstorage is non existent
                if (lastUsedRelationsFromStorage.length < 5) {
                    dispatch(userRelationReducerActions.initLastUsedRelations(userRelationsFlattened))
                } else {
                    dispatch(userRelationReducerActions.initLastUsedRelations(lastUsedRelationsFromStorage))
                }
            } else {
                setLastUsedRelationsToLocalStorage(userId, userRelationsFlattened.map((r) => r.id).slice(0, 5))
                dispatch(userRelationReducerActions.initLastUsedRelations(userRelationsFlattened))
            }
        }
    }

export const addLastUsedRelationThunk =
    (relation: IUserRelationFlattened): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const userData = userSelectors.userData(getState())
        const userId = userData?.user.id ?? ""
        await dispatch(userRelationReducerActions.addLastUsedRelation(relation.id))
        setLastUsedRelationsToLocalStorage(userId, userRelationSelectors.lastUsedRelations(getState()))
    }

export const fetchRelationSearchData =
    (force?: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force) {
            const relationSearchData = userRelationSelectors.getRelationSearchData(getState())
            if (relationSearchData) {
                return
            }
        }

        const result = await dispatch(requestThunk<IRelationSearchData>(endpoints.Identity.getUserRelationsSearchData))

        await dispatch(userRelationReducerActions.setRelationSearchData(result))
    }
