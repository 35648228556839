import React from "react"
import { IModalControlProps } from "swiipe.portal.shared"
import ModalConfirm, { IModalConfirm } from "../ModalConfirm"
import ModalDialog, { IModalDialog } from "../ModalDialog"
import ModalEmail, { IModalEmail } from "../ModalEmail"
import ModalInfo, { IModalInfo } from "../ModalInfo"
import ModalLegalText, { IModalLegalText } from "../ModalLegalText"
import ModalLogOut, { IModalLogOut } from "../ModalLogOut"
import ModalLoggedOut, { IModalLoggedOut } from "../ModalLoggedOut"
import ModalMerchantOfferPreview, { IModalMerchantOfferPreview } from "../ModalMerchantOfferPreview"
import ModalSms, { IModalSms } from "../ModalSms"
import ModalTrackTime, { IModalTrackTime } from "../ModalTrackTime"

export type TAllModals =
    | IModalInfo
    | IModalLogOut
    | IModalEmail
    | IModalLoggedOut
    | IModalSms
    | IModalConfirm
    | IModalDialog
    | IModalLegalText
    | IModalTrackTime
    | IModalMerchantOfferPreview

export function RenderModal(modalWithoutCallback: TAllModals, controlProps: IModalControlProps) {
    switch (modalWithoutCallback.type) {
        case "info":
            return <ModalInfo {...modalWithoutCallback} {...controlProps} />
        case "logOut":
            return <ModalLogOut {...modalWithoutCallback} {...controlProps} />
        case "email":
            return <ModalEmail {...modalWithoutCallback} {...controlProps} />
        case "modalLoggedOut":
            return <ModalLoggedOut {...modalWithoutCallback} {...controlProps} />
        case "sms":
            return <ModalSms {...modalWithoutCallback} {...controlProps} />
        case "confirm":
            return <ModalConfirm {...modalWithoutCallback} {...controlProps} />
        case "dialog":
            return <ModalDialog {...modalWithoutCallback} {...controlProps} />
        case "legal":
            return <ModalLegalText {...modalWithoutCallback} {...controlProps} />
        case "trackTime":
            return <ModalTrackTime {...modalWithoutCallback} {...controlProps} />
        case "merchantOfferPreview":
            return <ModalMerchantOfferPreview {...modalWithoutCallback} {...controlProps} />
        default:
            return null
    }
}
