import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { ITestRun } from "../../type/testResults/ITestRun"
import { StoreState } from "../StoreState"

export type TestResultState = Readonly<{
    testRuns?: ITestRun[]
}>
const initialState: TestResultState = {}

export const testResultReducerActions = {
    setTestRens: (testRuns: ITestRun[]) => createAction("APP/SET_TEST_RUNS", { testRuns }),
}

export type TestResultActions = ActionsUnion<typeof testResultReducerActions>

export const testResultReducer = (state: TestResultState = initialState, action: TestResultActions) => {
    switch (action.type) {
        case "APP/SET_TEST_RUNS": {
            return {
                ...state,
                testRuns: action.testRuns,
            }
        }
    }

    return state
}

export const testResultSelectors = {
    testRuns: (state: StoreState) => state.testResult.testRuns,
}
