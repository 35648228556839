import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { StoreState } from "../StoreState"

export type EmailTemplateState = Readonly<{
    emailTemplates?: IEmailTemplate[]
    emailBaseTemplates?: IEmailBaseTemplate[]
    emailTemplateImages?: IEmailTemplateImage[]
}>

export interface IEmailTemplate {
    emailType: string
    emailBaseTypeId?: string
    language: string
    subject: string
    body: string
    fromEmail: string
    fromName: string
    defaultToEmail: string
    defaultCcToEmails: string[]
    defaultToName: string
}

export interface IEmailBaseTemplate {
    typeId: string
    name: string
    language: string
    body: string
    isDisabled: boolean
}

export interface IEmailTemplateImage {
    name: string
    language: string
    url: string
    width: number
    height: number
    size: number
    type: string
}

const initialState: EmailTemplateState = {}

export const emailTemplateReducerActions = {
    setEmailTemplates: (emailTemplates: IEmailTemplate[]) => createAction("APP/SET_EMAIL_TEMPLATES", { emailTemplates }),
    setEmailBaseTemplates: (emailBaseTemplates: IEmailBaseTemplate[]) =>
        createAction("APP/SET_EMAIL_BASE_TEMPLATES", { emailBaseTemplates }),
    setEmailTemplateImages: (emailTemplateImages: IEmailTemplateImage[]) =>
        createAction("APP/SET_EMAIL_TEMPLATE_IMAGES", { emailTemplateImages }),
}

export type EmailTemplatesActions = ActionsUnion<typeof emailTemplateReducerActions>

export const emailTemplateReducer = (state: EmailTemplateState = initialState, action: EmailTemplatesActions) => {
    switch (action.type) {
        case "APP/SET_EMAIL_TEMPLATES": {
            return {
                ...state,
                emailTemplates: action.emailTemplates,
            }
        }
        case "APP/SET_EMAIL_BASE_TEMPLATES": {
            return {
                ...state,
                emailBaseTemplates: action.emailBaseTemplates,
            }
        }
        case "APP/SET_EMAIL_TEMPLATE_IMAGES": {
            return {
                ...state,
                emailTemplateImages: action.emailTemplateImages,
            }
        }
    }

    return state
}

export const emailTemplateSelectors = {
    emailTemplate: (state: StoreState, type: string, language: string) =>
        state.emailTemplate.emailTemplates?.find((et) => et.emailType === type && et.language === language),
    emailTemplates: (state: StoreState) => state.emailTemplate.emailTemplates,
    emailBaseTemplate: (state: StoreState, typeId: string, language: string) =>
        state.emailTemplate.emailBaseTemplates?.find((et) => et.typeId === typeId && et.language === language),
    emailBaseTemplates: (state: StoreState) => state.emailTemplate.emailBaseTemplates,
    emailTemplateImages: (state: StoreState) => state.emailTemplate.emailTemplateImages,
}
