import React from "react"
import "./SessionEventsTable.scss"
import cn from "classnames"
import { ISessionEvent } from "../../../type/ISessionEvent"
import { formatDate } from "../../../util/dateUtil"
import { getTimeBetween } from "../../../util/timeUtil"

type EClassModifier =
    | "--first"
    | "--bolder-black"
    | "--bolder-green"
    | "--bolder-blue"
    | "--bolder-orange"
    | "--bolder-red"
    | "--regular"
    | "--italic"

interface SessionEventsTableProps<T extends ISessionEvent> {
    events: T[]
    getBetweenEventInterval: (event: T) => string
    conditionalLineStyle?: (event: T) => EClassModifier[]
}

function SessionEventsTable<T extends ISessionEvent>({
    events,
    getBetweenEventInterval,
    conditionalLineStyle,
}: SessionEventsTableProps<T>) {
    return (
        <div className="session-events-table">
            <h5>Events:</h5>
            <div className="session-events-table__line">
                <div className="session-events-table__line-column">Time</div>
                <div className="session-events-table__line-column">Between</div>
                <div className="session-events-table__line-column">Since start</div>
                <div className="session-events-table__line-column--medium">Event</div>
                <div className="session-events-table__line-column--large">Data</div>
            </div>

            <div>
                {events.map((event, index) => {
                    const { type, time, pageStartTime, data } = event
                    return (
                        <div
                            className={cn(
                                "session-events-table__line",
                                conditionalLineStyle?.(event).map((classModifier) => `session-events-table__line${classModifier}`)
                            )}
                            key={`session-events-table__line-${index}`}
                        >
                            <div className="session-events-table__line-column">{formatDate(time, "time-detailed")}</div>
                            <div className="session-events-table__line-column">{getBetweenEventInterval(event)}</div>
                            <div className="session-events-table__line-column">{getTimeBetween(pageStartTime, time)}</div>
                            <div className="session-events-table__line-column--medium">{type}</div>
                            <div className="session-events-table__line-column--large">{data}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default SessionEventsTable
