import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Alert } from "reactstrap"
import {
    PageContainer,
    PageWrapper,
    SearchBox,
    StandardButtonWithSpinner,
    useSelector,
    userSelectors,
} from "swiipe.portal.shared"
import SpinnerContainer from "../../components/buttons/SpinnerContainer"
import PageTitle from "../../components/page/PageTitle"
import smsTemplateTypes from "../../data/smsTemplateTypes"
import { getPhoneWithCountryCode } from "../../services/smsService"
import { sentSmsSelectors } from "../../store/reducers/sentSmsReducer"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { getSentSmsesThunk, resendSentSmsThunk } from "../../store/thunks/smsThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { getFormattedDate } from "../../util/dateUtil"
import "./SentSmsesPage.scss"

interface ISentSmsesPage {}

const SentSmsesPage = ({}: ISentSmsesPage) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()
    const initialSearch = useSelector(sentSmsSelectors.search)
    const [searchValue, setSearchValue] = useState(initialSearch)

    useEffect(() => {
        dispatch(getSentSmsesThunk(initialSearch, false))
    }, [])

    useEffect(() => {
        dispatch(getSentSmsesThunk(searchValue, false))
    }, [searchValue])

    const smses = useSelector(sentSmsSelectors.smses)
    const resentSmsesIds = useSelector(sentSmsSelectors.resentSmsesIds)
    const userData = useSelector(userSelectors.userData)

    if (!userData) {
        return null
    }

    return (
        <PageWrapper fullWidth>
            <PageContainer id="sentsmses-page">
                <PageTitle title={t("sentemails.emails")} />
                <SearchBox
                    className="search"
                    currentValue={searchValue}
                    delayOnChangeMs={1500}
                    placeholder="Search by email or number"
                    onValueChange={(val) => {
                        setSearchValue(val)
                    }}
                />
                <SpinnerContainer showSpinner={!smses}>
                    {smses && smses.length === 0 && <div className="no-smses">No smses for the current search criteria</div>}
                    <table className="smses-table table-responsive-md">
                        <tbody>
                            <tr>
                                <th>{t("sentemails.created")}</th>
                                <th>{t("sentemails.type")}</th>
                                <th>{t("sentemails.sentto")}</th>
                                <th></th>
                            </tr>
                            {(smses ?? []).map((s, i) => {
                                return (
                                    <tr key={s.smsId + "_" + i}>
                                        <td>{getFormattedDate(s.created, "slashes")}</td>
                                        <td>{smsTemplateTypes.find((t) => t.type === s.type)?.label ?? s.type}</td>
                                        <td className="sent-to">
                                            {s.fromSenderName && <span>From: {s.fromSenderName}</span>}
                                            <span className="name">To: {s.sentToNumber}</span>
                                            <span>{s.sentToName}</span>
                                            <span>{s.sentToEmail}</span>
                                            {s.throttled && (
                                                <Alert className="alert-danger d-inline-block mt-2 mb-0 p-1">Throttled</Alert>
                                            )}
                                            {s.notWhitelisted && (
                                                <>
                                                    {s.throttled && <br />}
                                                    <Alert className="alert-danger d-inline-block mt-2 mb-0 p-1">
                                                        Not whitelisted
                                                    </Alert>
                                                </>
                                            )}
                                            {s.sendAmountLimitPassed && (
                                                <>
                                                    {(s.throttled || s.notWhitelisted) && <br />}
                                                    <Alert className="alert-danger d-inline-block mt-2 mb-0 p-1">
                                                        Send amount limit passed
                                                    </Alert>
                                                </>
                                            )}
                                        </td>
                                        <td className="actions">
                                            <StandardButtonWithSpinner
                                                dark
                                                noBorder
                                                className="action-btn"
                                                onClick={async () => {
                                                    await dispatch(
                                                        addModalThunk({
                                                            type: "sms",
                                                            title: s.fromSenderName,
                                                            body: s.body,
                                                        })
                                                    )
                                                }}
                                            >
                                                Show SMS
                                            </StandardButtonWithSpinner>

                                            {resentSmsesIds.includes(s.smsId) ? (
                                                <span className="sent-text">{t("sentemails.sent")}</span>
                                            ) : (
                                                <StandardButtonWithSpinner
                                                    dark
                                                    noBorder
                                                    className="action-btn"
                                                    onClick={async () => {
                                                        if (!userData.user.phone) {
                                                            alert("Please add your phone number")
                                                            return
                                                        }

                                                        await dispatch(
                                                            resendSentSmsThunk(
                                                                s.smsId,
                                                                getPhoneWithCountryCode(userData.user.phone)
                                                            )
                                                        )
                                                    }}
                                                >
                                                    {t("sentemails.sendtome")}
                                                </StandardButtonWithSpinner>
                                            )}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </SpinnerContainer>
            </PageContainer>
        </PageWrapper>
    )
}

export default SentSmsesPage
