import { MutableRefObject, useRef, useState } from "react"

export function useRefState<T>(initialValue: T): [MutableRefObject<T>, (newValue: T) => void, number] {
    const [reloadTrigger, setReloadTrigger] = useState<number>(0)
    const ref = useRef<T>(initialValue)

    return [
        ref,
        (newValue: T) => {
            ref.current = newValue
            setReloadTrigger(reloadTrigger + 1)
        },
        reloadTrigger,
    ]
}
