import React from "react"
import { useTranslation } from "react-i18next"
import SecuredBySwiipeFooter from "../../../images/secured-by-swiipe.png"
import "./FormFooter.scss"
import cn from "classnames"
interface IFormFooterProps {
    className?: string
}

const FormFooter = ({ className }: IFormFooterProps) => {
    const { t } = useTranslation()
    return (
        <div className={cn("swiipe-form-footer form-footer mb-2 mt-5", className)}>
            <p className="text-center">
                <img src={SecuredBySwiipeFooter} className="secured-by-swiipe" />
            </p>
        </div>
    )
}

export default FormFooter
