import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk } from "swiipe.portal.shared"
import { TSwiipePlan } from "../../type/merchant/TSwiipePlan"
import { StoreState } from "../StoreState"
import { IMerchantCreateStatusResponse, merchantReducerActions, merchantSelectors } from "../reducers/merchantReducer"
import { userRelationSelectors } from "../reducers/userRelationReducer"
import { endpoints } from "./../../data/endpoints"
import { MixedMerchantDetails } from "./../../type/merchant/mixedmerchantdetails"
import { ensureUserRelationsThunk } from "./userRelationThunks"

export const fetchMerchantDataThunk =
    (force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(ensureUserRelationsThunk())

        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation || currentUserRelation.relationType !== "Merchant") {
            return
        }

        if (!force && merchantSelectors.merchantDetails(getState())) {
            // Merchant data already fetched
            return
        }

        const merchantDetails = await dispatch(
            requestThunk<MixedMerchantDetails>(endpoints.Identity.getMerchantDetails, {
                params: { merchantId: currentUserRelation.id },
            })
        )

        merchantDetails.webshops = merchantDetails.webshops.sort(function (a, b) {
            const hostA = a.hostName,
                hostB = b.hostName
            if (hostA < hostB) return -1
            if (hostA > hostB) return 1
            return 0
        })

        dispatch(merchantReducerActions.setMerchantDetails(currentUserRelation.id, merchantDetails))
        dispatch(tryToSetLastSelectedWebshopIdThunk())

        await dispatch(fetchSwiipePlanThunk(currentUserRelation.id))
    }

export const tryToSetLastSelectedWebshopIdThunk =
    (): ThunkAction<Promise<void>, StoreState, null, AnyAction> => async (dispatch, getState) => {
        const merchantDetails = merchantSelectors.merchantDetails(getState())
        if (!merchantDetails || !merchantDetails.swMerchant) {
            return
        }
        const lastSelectedWebshopId = localStorage[merchantDetails.swMerchant.swMerchantId + "_selectedWebshopId"]
        if (!lastSelectedWebshopId) {
            return
        }
        dispatch(merchantReducerActions.setSelectedWebhop(merchantDetails.swMerchant.swMerchantId, lastSelectedWebshopId))
    }

export const fetchSwiipePlanThunk =
    (swMerchantId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const swiipePlan = await dispatch(
            requestThunk<{ swiipePlan?: TSwiipePlan }>(endpoints.Identity.getSwiipePlan(swMerchantId))
        )
        if (swiipePlan.swiipePlan !== undefined) {
            dispatch(merchantReducerActions.setSwiipePlan(swMerchantId, swiipePlan.swiipePlan))
        }
    }

export const setSelectedWebshopIdThunk =
    (merchantId: string, webshopId: string): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch) => {
        dispatch(merchantReducerActions.setSelectedWebhop(merchantId, webshopId))
    }

export const recalculateOrderThunk =
    (orderId: string, webshopId: string): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<void>(endpoints.Payments.recalculateOrders, {
                data: {
                    orderId: orderId,
                    webshopId: webshopId,
                },
            })
        )
    }

export const exportMerchantContactsThunk =
    (merchantsPlan: string): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const response = await dispatch(
            requestThunk<any>(endpoints.Identity.exportMerchantContacts, {
                params: { merchantsPlan },
            })
        )

        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute(
            "download",
            `contactsExport_${new Date().toLocaleString("da", {
                dateStyle: "short",
                timeStyle: "short",
            })}.csv`
        )
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

export const updateMerchantDetails =
    (swMerchantId: string, trialExpiration: Date): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<any>(endpoints.Identity.setMerchantDetails, {
                data: {
                    swMerchantId,
                    trialExpiration,
                },
            })
        )

        await dispatch(fetchMerchantDataThunk(true))
    }

export const getMerchantCreateStatusThunk =
    (crn: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        if (!crn?.trim()) {
            return
        }
        const resp = await dispatch(
            requestThunk<IMerchantCreateStatusResponse>(endpoints.Identity.getMerchantCreateStatus, {
                params: {
                    companyRegistrationNumber: crn,
                },
            })
        )
        dispatch(merchantReducerActions.setCreateMerchantStatus(crn, resp))
    }
