import React, { useEffect } from "react"
import { Control } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
    DropDownList,
    FieldsetFormGroup,
    FloatingLabelInput,
    FormInputColumnWithInfoColumn,
    IFormBase,
    IconButton,
    LayoutSeparator,
    TPlatformValue,
    ensureArrayLength,
    getAvailablePlatforms,
    getDomainValidationPattern,
    sharedConfigurationSelectors,
    useRefState,
    useSelector,
    valFuncPattern,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import "./AddWebshopsForm.scss"

export interface IWebshopSubForm {
    domain: string
    platform: TPlatformValue
    mcc?: string
}

export type IAddWebshopsSubForm = IWebshopSubForm[]

interface IAddWebshopsFormProps<TForm extends IFormBase> {
    control: Control<TForm>
    getFieldsFromControl: (form: TForm | undefined) => IAddWebshopsSubForm | undefined
    savedFields: IAddWebshopsSubForm | undefined
    fieldSelectorPrefix: string
    defaultLength?: number | undefined
    isDisabled?: boolean
}

export const AddWebshopsForm = <TForm extends IFormBase>({
    control,
    getFieldsFromControl,
    savedFields,
    fieldSelectorPrefix,
    defaultLength,
    isDisabled,
}: IAddWebshopsFormProps<TForm>) => {
    const { t } = useTranslation()
    const [renderExtraRowRef, setRenderExtraRow] = useRefState(false)
    const [removeLastRowRef, setRemoveLastRow] = useRefState(false)

    const [forcedRowCountRef, setForcedRowsCount, forcedRowCountWatcher] = useRefState<number | undefined>(undefined)
    const env = useSelector(sharedConfigurationSelectors.environment)

    const currentFields = getFieldsFromControl(control.getValues() as TForm)
    const getFieldSelector = (fieldName: string, index: number) => `${fieldSelectorPrefix}[${index}].${fieldName}`

    const currentLength = (currentFields ?? []).length
    const webshopsFields = ensureArrayLength<IWebshopSubForm>(
        currentFields ?? [],
        Math.max(
            1,
            removeLastRowRef.current
                ? currentLength - 1
                : renderExtraRowRef.current
                ? currentLength + 1
                : forcedRowCountRef.current || forcedRowCountRef.current === 0
                ? forcedRowCountRef.current
                : currentLength
        ),
        () => ({} as IWebshopSubForm)
    )

    useEffect(() => {
        if (renderExtraRowRef.current || removeLastRowRef.current) {
            setRenderExtraRow(false)
            setRemoveLastRow(false)
        }
    }, [renderExtraRowRef.current, removeLastRowRef.current])

    useEffect(() => {
        if (typeof forcedRowCountRef.current === "number") {
            setForcedRowsCount(undefined)
        }
    }, [forcedRowCountWatcher])

    useEffect(() => {
        if (typeof defaultLength === "number") {
            setForcedRowsCount(defaultLength)
        }
    }, [defaultLength])

    return (
        <div className="add-webshops-form">
            {webshopsFields.map((wf, index) => {
                return (
                    <div className="add-webshops-form__webshop-container" key={index}>
                        <FormInputColumnWithInfoColumn>
                            <FieldsetFormGroup className="add-webshops-form__webshop-field" field>
                                <FloatingLabelInput
                                    placeholder={t("common.hostname.label")}
                                    name={getFieldSelector("domain", index)}
                                    type="text"
                                    disabled={isDisabled}
                                    innerRef={control.register(
                                        valFuncRequiredAndPattern(
                                            getDomainValidationPattern(env),
                                            t("common.hostname.errorMessage")
                                        )
                                    )}
                                    bottomHint={t(
                                        env === "Production"
                                            ? "common.hostname.domainCheckHintProd"
                                            : "common.hostname.domainCheckHintNonProd"
                                    )}
                                    disableHintAfter={5}
                                    autoCompleteOff
                                ></FloatingLabelInput>
                            </FieldsetFormGroup>
                        </FormInputColumnWithInfoColumn>
                        <FormInputColumnWithInfoColumn>
                            <FieldsetFormGroup field>
                                <DropDownList
                                    name={getFieldSelector("platform", index)}
                                    disabled={isDisabled}
                                    options={getAvailablePlatforms()}
                                    innerRef={control.register()}
                                ></DropDownList>
                            </FieldsetFormGroup>
                        </FormInputColumnWithInfoColumn>
                        <FormInputColumnWithInfoColumn
                            infoNarrowWidth
                            infoColumn={
                                <span className="add-webshops-form__mcc-link">
                                    Click{" "}
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.web-payment-software.com/online-merchant-accounts/mcc-codes/"
                                    >
                                        here
                                    </a>{" "}
                                    to see all the MCC codes
                                </span>
                            }
                        >
                            <FieldsetFormGroup className="add-webshops-form__webshop-field" field>
                                <FloatingLabelInput
                                    placeholder={t("common.mcc.label")}
                                    name={getFieldSelector("mcc", index)}
                                    type="text"
                                    hintInsideInput="MobilePay requires MCC codes during onboarding. If you know the merchant type, you can find its MCC code online."
                                    disabled={isDisabled}
                                    innerRef={control.register(
                                        valFuncPattern(validationPatterns.mccNumber, t("common.mcc.errorMessage"))
                                    )}
                                    autoCompleteOff
                                ></FloatingLabelInput>
                            </FieldsetFormGroup>
                        </FormInputColumnWithInfoColumn>
                    </div>
                )
            })}
            {!isDisabled && (
                <FormInputColumnWithInfoColumn>
                    <LayoutSeparator />
                    <div className="add_webshops-form__icon-buttons">
                        <IconButton icon="add" text="Add webshop" onClick={() => setRenderExtraRow(true)} />
                        {webshopsFields.length > 1 && (
                            <IconButton icon="close" text="Remove last webshop" onClick={() => setRemoveLastRow(true)} />
                        )}
                    </div>
                </FormInputColumnWithInfoColumn>
            )}
        </div>
    )
}
