import React, { SubmitHandler, useForm } from "react-hook-form"
import { Form } from "reactstrap"
import { FloatingLabelInput, useSelector } from "swiipe.portal.shared"
import { getLatestTemplate } from "../../../services/termsTemplateService"
import { StoreState } from "../../../store/StoreState"
import { termsTemplateSelectors } from "../../../store/reducers/termsTemplateReducer"
import { ETermsType } from "../../../type/terms/ETermsType"
import { calculateVersionValue, isValidNumberForVersion } from "../../../util/versionUtil"
import SubmitButton from "../../buttons/SubmitButton"
import ShowErrorMessages from "../ShowErrorMessages"
import "./TermsTemplateVersionForm.scss"

interface ITermsTemplateVersionFormProps {
    onSelection: (version: string, revision: number) => void
    termsType: ETermsType
}

interface ITermsTemplateVersionForm {
    major: number
    minor: number
    patch: number
    revision: number
}

const TermsTemplateVersionForm = ({ onSelection, termsType }: ITermsTemplateVersionFormProps) => {
    const { handleSubmit, register, errors, formState } = useForm<ITermsTemplateVersionForm>()

    const termsTemplateVersions = useSelector((state: StoreState) =>
        termsTemplateSelectors.termsTemplateDetails(state, termsType)
    )

    const latestTemplate = getLatestTemplate(termsTemplateVersions ?? [])

    const onSubmit: SubmitHandler<ITermsTemplateVersionForm> = async (data) => {
        const version = `${data.major}.${data.minor}.${data.patch}`
        const revision = data.revision

        if (
            latestTemplate &&
            calculateVersionValue(latestTemplate.version, latestTemplate.revision) >= calculateVersionValue(version, revision)
        ) {
            // Too low version
            alert("Version not higher than latest version")
            return
        }

        if (
            isValidNumberForVersion(data.major) &&
            isValidNumberForVersion(data.minor) &&
            isValidNumberForVersion(data.patch) &&
            isValidNumberForVersion(data.revision, ["require-positive"])
        ) {
            onSelection(`${data.major}.${data.minor}.${data.patch}`, data.revision)
        }
    }

    return (
        <div className="terms-template-version-form">
            <Form onSubmit={handleSubmit(onSubmit)} className="mt-5">
                <div className="terms-template-version-form__version-container">
                    <FloatingLabelInput
                        containerClass="terms-template-version-form__input terms-template-version-form__version-major"
                        name={"major"}
                        defaultValue={latestTemplate?.version.split(".")[0] ?? "1"}
                        innerRef={register()}
                        placeholder="Major"
                        type="number"
                    ></FloatingLabelInput>
                    <FloatingLabelInput
                        containerClass="terms-template-version-form__input terms-template-version-form__version-minor"
                        name={"minor"}
                        defaultValue={latestTemplate?.version.split(".")[1] ?? "0"}
                        innerRef={register()}
                        placeholder="Minor"
                        type="number"
                    ></FloatingLabelInput>
                    <FloatingLabelInput
                        containerClass="terms-template-version-form__input terms-template-version-form__version-patch"
                        name={"patch"}
                        defaultValue={latestTemplate?.version.split(".")[2] ?? "0"}
                        innerRef={register()}
                        placeholder="Patch"
                        type="number"
                    ></FloatingLabelInput>
                    <FloatingLabelInput
                        containerClass="terms-template-version-form__input terms-template-version-form__version-revision"
                        name={"revision"}
                        defaultValue={latestTemplate?.revision ?? "1"}
                        innerRef={register()}
                        placeholder="Revision"
                        type="number"
                    ></FloatingLabelInput>
                </div>
                <SubmitButton formState={formState} isLarge containerClass="save-button">
                    Select version
                </SubmitButton>
                <ShowErrorMessages<ITermsTemplateVersionForm> errors={errors} />
            </Form>
        </div>
    )
}

export default TermsTemplateVersionForm
