import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { IGetTrackedTimeDto } from "../../type/trackedTime/IGetTrackedTimeDto"
import { IUpdateTrackedTimeDto } from "../../type/trackedTime/IUpdateTrackedTimeDto"
import { StoreState } from "../StoreState"
import { trackedTimeReducerActions, trackedTimeSelectors } from "../reducers/trackedTimeReducer"
import { userRelationSelectors } from "../reducers/userRelationReducer"
import { addModalThunk } from "./modalThunks"

export const ensureTrackedTimeThunk =
    (organizationId: string, force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!organizationId) {
            return
        }
        if (!force && trackedTimeSelectors.trackedTime(getState(), organizationId)) {
            return
        }
        const resp = await dispatch(
            requestThunk<{
                trackedTimeList: IGetTrackedTimeDto[]
            }>(endpoints.Content.getAllTrackedTime, {
                params: {
                    organizationId: organizationId,
                },
            })
        )

        dispatch(trackedTimeReducerActions.setTrackedTime(organizationId, resp.trackedTimeList))
    }

export const addOrUpdateTrackedTimeThunk =
    (dto: IUpdateTrackedTimeDto): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const userRelation = userRelationSelectors.currentUserRelation(getState())
        if (userRelation?.relationType !== "Merchant") {
            return
        }
        await dispatch(
            requestThunk(endpoints.Content.addOrUpdateTrackedTime, {
                data: dto,
            })
        )

        await dispatch(ensureTrackedTimeThunk(dto.organizationId, true))
    }

export const deleteTrackedTimeThunk =
    (organizationId: string, trackedTimeId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const res = await dispatch(
            addModalThunk({
                type: "verify",
                title: "Delete tracked time",
                text: "Are you sure you want to delete this?",
                acceptButtonIsDelete: true,
                acceptButtonText: "Delete",
            })
        )
        if (res.type === "accepted") {
            await dispatch(
                requestThunk(endpoints.Content.deleteTrackedTime, {
                    params: {
                        organizationId,
                        trackedTimeId,
                    },
                })
            )

            await dispatch(ensureTrackedTimeThunk(organizationId, true))
        }
    }
