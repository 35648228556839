import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk, userSelectors } from "swiipe.portal.shared"
import { TPayment } from "../../type/TPayment"
import { IHealthStatusDto } from "../../type/healthStatus/IHealthStatusDto"
import { StoreState } from "../StoreState"
import { healthStatusReducerActions, healthStatusSelectors } from "../reducers/healthStatusReducer"
import { merchantSelectors } from "../reducers/merchantReducer"
import { userRelationSelectors } from "../reducers/userRelationReducer"
import { endpoints } from "./../../data/endpoints"
import { addModalThunk } from "./modalThunks"

export const getHealthStatusesThunk =
    (force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const userRelation = userRelationSelectors.currentUserRelation(getState())
        const isGlobal = userRelation?.isMasterPartner ?? false
        if (!isGlobal && userRelation?.relationType !== "Merchant") {
            return
        }
        const webshopId = merchantSelectors.selectedWebshopId(getState())

        if (!force) {
            const healthStatuses = healthStatusSelectors.healthStatuses(getState(), isGlobal, webshopId)
            if (healthStatuses) {
                return
            }
        }

        const result = await dispatch(
            requestThunk<{ healthStatuses: IHealthStatusDto[]; paymentTypes: TPayment[]; piqPaymentTypes: TPayment[] }>(
                endpoints.Payments.getHealthStatuses,
                {
                    params: {
                        isGlobal: isGlobal,
                        webshopId: webshopId,
                    },
                }
            )
        )
        dispatch(healthStatusReducerActions.setHealthStatuses(isGlobal, webshopId, result.healthStatuses))
        dispatch(
            healthStatusReducerActions.setPaymentTypes(
                isGlobal,
                webshopId,
                result.paymentTypes.map((pt) => {
                    return {
                        name: pt,
                        isPiq: result.piqPaymentTypes.indexOf(pt) >= 0,
                    }
                })
            )
        )
    }

export const updateHealthStatusesThunk =
    (healthStatuses: IHealthStatusDto[]): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const userRelation = userRelationSelectors.currentUserRelation(getState())
        const isGlobal = userRelation?.isMasterPartner ?? false
        if (!isGlobal && userRelation?.relationType !== "Merchant") {
            return
        }
        const webshopId = merchantSelectors.selectedWebshopId(getState())

        if (isGlobal && healthStatuses.find((hs) => hs.enabled)) {
            const user = userSelectors.userData(getState())
            const answer = await dispatch(
                addModalThunk({
                    type: "verify",
                    emailToVerify: user?.user.email,
                    text: "You are affecting all webshops with Swiipe Payments! This is a serious action! Verify with your email:",
                })
            )
            if (answer.type !== "accepted") {
                return
            }
        } else {
            const answer = await dispatch(
                addModalThunk({
                    type: "verify",
                    text: "Are you sure you want to save your changes?",
                })
            )
            if (answer.type !== "accepted") {
                return
            }
        }

        await dispatch(
            requestThunk<any>(endpoints.Payments.updateHealthStatuses, {
                data: {
                    isGlobal: isGlobal,
                    webshopId: webshopId,
                    healthStatuses: healthStatuses,
                },
            })
        )

        await dispatch(getHealthStatusesThunk(true))
    }
