import { createEndpoint0, createEndpoint1 } from "swiipe.portal.shared"

const apiEndpoints = {
    Self: {
        checkCookie: createEndpoint0("post", "/checkCookie", { endpoint: "Self" }),
        getConfiguration: createEndpoint0("get", "/api/configuration/GetServerConfigurationEndpoints", { endpoint: "Self" }),
    },
    Auth: { login: createEndpoint0("post", "/auth/login", { isOpen: true }) },
    Core: {
        getUserData: createEndpoint0("get", "/core/personal/user"),
        getUserRelations: createEndpoint0("get", "/core/userRelation/userRelations"),
        getPartnerData: createEndpoint1("get", "/core/partner/{partnerId}"),
    },
    Identity: {
        getUserRelationsSearchData: createEndpoint0("get", "/identity/user/getUserRelationsSearchData"),
        getSwiipePlan: createEndpoint1("get", "/identity/swiipePlan/{swMerchantId}"),
        getUsersByEmail: createEndpoint0("get", "/identity/user/getByEmailAsMasterPartner"),
        getUsersByIdAdmin: createEndpoint0("get", "/identity/user/getByIdAdmin"),
        deleteUser: createEndpoint1("delete", "/identity/user/{id}"),
        getMerchantDetails: createEndpoint0("get", "/identity/merchant/getMerchantDetails"),
        exportMerchantContacts: createEndpoint0("get", "/identity/merchant/exportContacts"),
        setMerchantDetails: createEndpoint0("post", "/identity/merchant/setMerchantDetails"),
        getMerchantPreOnboardings: createEndpoint0("get", "/identity/merchantPreOnboardings"),
        removeMerchantPreOnboarding: createEndpoint0("delete", "/identity/merchantPreOnboardings"),
        getMerchantPreOnboardingDetails: createEndpoint0("get", "/identity/merchantPreOnboardings/details"),
        preOnboardMerchant: createEndpoint0("post", "/identity/merchantPreOnboardings"),
        saveDraftMerchantPreOnboarding: createEndpoint0("post", "/identity/merchantPreOnboardings/saveDraft"),
        addMerchantPreOnboardingEvent: createEndpoint0("post", "/identity/merchantPreOnboardings/events"),
        getMerchantCreateStatus: createEndpoint0("get", "/identity/merchant/createStatus"),
    },
    Plugin: {
        getPluginInfo: createEndpoint0("get", "/plugin/pluginInfo/getPluginData", { isOpen: true }),
        getPluginAsset: createEndpoint0("get", "/plugin/customWebshopAsset"),
        updatePluginAsset: createEndpoint0("post", "/plugin/customWebshopAsset"),
        enablePluginAssetsFastUpdate: createEndpoint0("post", "/plugin/pluginAsset/enableFastUpdate"),
        getWebshopAssetsSettings: createEndpoint0("get", "/plugin/pluginAsset/webshopSettings"),
        recordWebshopSnapshot: createEndpoint0("post", "/plugin/webshopData/recordWebshopSnapshot/fromPortal"),
        adminApiTest: createEndpoint0("post", "/plugin/webshopData/adminApiTest"),
    },
    Quickpay: {
        getOnboardingDataForWebshop: createEndpoint1("get", "/quickpay/onboarding/webshops/{webshopId}"),
        registerMerchant: createEndpoint0("post", "/quickpay/onboarding/registration"),
        deleteMerchant: createEndpoint0("delete", "/quickpay/onboarding/registration"),
    },
    Send: {
        getSentEmails: createEndpoint0("get", "/send/email/getSentEmails"),
        getSentSmses: createEndpoint0("get", "/send/sms/getSentSmses"),
        sentTestEmail: createEndpoint0("post", "/send/email/sendTestEmail"),
        sentTestSms: createEndpoint0("post", "/send/sms/sendTestSms"),
        resendSentEmail: createEndpoint0("post", "/send/email/resendSentEmail"),
        resendSentSms: createEndpoint0("post", "/send/sms/resendSentSms"),
        getAllEmailTemplates: createEndpoint0("get", "/send/emailTemplate/getAll"),
        getAllSmsTemplates: createEndpoint0("get", "/send/smsTemplate/getAll"),
        getAllEmailBaseTemplates: createEndpoint0("get", "/send/emailTemplate/getAllBase"),
        updateEmailTemplate: createEndpoint0("post", "/send/emailTemplate/update"),
        updateSmsTemplate: createEndpoint0("post", "/send/smsTemplate/update"),
        updateEmailBaseTemplate: createEndpoint0("post", "/send/emailTemplate/updateBase"),
        uploadEmailTemplateImage: createEndpoint0("post", "/send/emailTemplateImage/upload"),
        deleteEmailTemplateImage: createEndpoint0("delete", "/send/emailTemplateImage"),
        getAllEmailTemplateImages: createEndpoint0("get", "/send/emailTemplateImage/getAll"),
    },
    Content: {
        getReleaseNotes: createEndpoint0("get", "/content/releaseNotes/getAllInternal"),
        addReleaseNote: createEndpoint0("post", "/content/releaseNotes/add"),
        editReleaseNote: createEndpoint1("put", "/content/releaseNotes/edit/{releaseNoteId}"),
        deleteReleaseNote: createEndpoint1("delete", "/content/releaseNotes/{releaseNoteId}"),
        getCredentials: createEndpoint0("get", "/content/credentials"),
        deleteCredentials: createEndpoint0("delete", "/content/credentials"),
        addCredentials: createEndpoint0("post", "/content/credentials/add"),
        getAllTrackedTime: createEndpoint0("get", "/content/trackedTime"),
        addOrUpdateTrackedTime: createEndpoint0("post", "/content/trackedTime"),
        deleteTrackedTime: createEndpoint0("delete", "/content/trackedTime"),
    },
    Clearhaus: {
        getClearhausMerchantData: createEndpoint0("get", "/clearhaus/merchant"),
    },
    Invoicing: {
        setMerchantConfig: createEndpoint0("post", "/invoicing/merchantConfig"),
        getMerchantConfig: createEndpoint0("get", "/invoicing/merchantConfig"),
        getPrices: createEndpoint0("get", "/invoicing/price"),
        getPublicPrices: createEndpoint0("get", "/invoicing/price/public"),
        getOrganization: createEndpoint0("get", "/invoicing/organization"),
        patchOrganization: createEndpoint0("patch", "/invoicing/organization"),
    },
    PaymentIQ: {
        getOnboardingStatuses: createEndpoint0("post", "/paymentiq/onboardingStatus/getOnboardingStatuses"),
        getConfigSecrets: createEndpoint0("get", "/paymentiq/paymentIQSecret/configSecrets"),

        adminEnableApplePaySwiipeCerts: createEndpoint0("post", "/paymentiq/applePayOnboarding/adminEnableApplePaySwiipeCerts"),
        adminGetApplePaySwiipeSigningCerts: createEndpoint0(
            "get",
            "/paymentiq/applePayOnboarding/adminGetApplePaySwiipeSigningCerts"
        ),
        adminGetApplePaySwiipeInfo: createEndpoint0("get", "/paymentiq/applePayOnboarding/adminGetApplePaySwiipeInfo"),
    },
    Payments: {
        recalculateOrders: createEndpoint0("post", "/payments/order/recalculate"),
        getHealthStatuses: createEndpoint0("get", "/payments/healthStatus"),
        updateHealthStatuses: createEndpoint0("put", "/payments/healthStatus"),
    },
    TestResults: {
        getTestRuns: createEndpoint0("get", "/testresults/testRun"),
        getTestRunsTv: createEndpoint0("get", "/testresults/testRun/ipRestricted"),
    },
    Legal: {
        getTermsTemplates: createEndpoint0("get", "/legal/termsTemplates"),
        getTermsTemplateDetails: createEndpoint1("get", "/legal/termsTemplates/{termsType}"),
        updateTermsTemplate: createEndpoint0("put", "/legal/termsTemplates"),
        removeTermsTemplate: createEndpoint1("delete", "/legal/termsTemplates/{termsTemplateId}"),
        getTermsBaseTemplates: createEndpoint0("get", "/legal/termsTemplates/bases"),
        updateTermsBaseTemplate: createEndpoint0("put", "/legal/termsTemplates/bases"),
        getTerms: createEndpoint0("get", "/legal/terms/asLoggedIn"),
        getTermsContentType: createEndpoint0("get", "/legal/terms/contentType"),
        getTermsReleaseInfo: createEndpoint0("get", "/legal/termsRelease"),
        releaseTerms: createEndpoint0("post", "/legal/termsRelease"),
    },
    Analytics: {
        getRevenueReports: createEndpoint0("get", "/analytics/reporting/revenueReports"),
        getPluginVersions: createEndpoint0("get", "/analytics/reporting/pluginVersions"),
        getPlusSellSessions: createEndpoint0("get", "/analytics/reporting/plusSellSessions"),
        getPlusSellReports: createEndpoint0("get", "/analytics/reporting/plusSellReports"),
    },
    GoogleAnalytics: {
        getWebshopData: createEndpoint0("get", "/googleAnalytics/onboarding/adminWebshopData"),
        setWebshopData: createEndpoint0("post", "/googleAnalytics/onboarding/adminWebshopData"),
        getEventsOverview: createEndpoint0("get", "/googleAnalytics/reporting/adminGetEventsOverview"),
    },
}

type IApiEndpoints = typeof apiEndpoints
export const endpoints: IApiEndpoints = apiEndpoints
