import cn from "classnames"
import React, { useEffect, useState } from "react"
import { PageContainer, PageWrapper } from "swiipe.portal.shared"
import "./PageWrapperWithTabs.scss"

export interface IPageTab {
    title: string
    id: string
    element: React.ReactNode
}

interface IPageWrapperWithTabs {
    tabs: IPageTab[] | undefined
}

const PageWrapperWithTabs = ({ tabs }: IPageWrapperWithTabs) => {
    const [selectedTabId, setSelectedTabId] = useState("")

    useEffect(() => {
        if (selectedTabId && tabs?.find((t) => t.id === selectedTabId)) {
            return
        }
        const tabToSelect = tabs?.find((t) => t.id)
        if (tabToSelect) {
            setSelectedTabId(tabToSelect.id)
        }
    }, [tabs])

    if (!tabs || !tabs.length) {
        return null
    }

    return (
        <div id="wrapper-with-tabs">
            <PageWrapper>
                <PageContainer classNameBody="main-container-el">
                    <div className="tabs-container">
                        {tabs.map((t) => (
                            <span
                                className={cn("tab", { active: selectedTabId === t.id })}
                                key={t.id}
                                onClick={() => setSelectedTabId(t.id)}
                            >
                                {t.title}
                            </span>
                        ))}
                    </div>
                    <div className="element-container">
                        {tabs.map((t) => (
                            <div key={t.id} className={cn(t.id !== selectedTabId && "d-none")}>
                                {t.element}
                            </div>
                        ))}
                    </div>
                </PageContainer>
            </PageWrapper>
        </div>
    )
}

export default PageWrapperWithTabs
