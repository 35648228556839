import React from "react"
import { ModalBody, Row } from "reactstrap"
import { BaseModal, IModalBase, IModalControlProps, ModalFooter } from "swiipe.portal.shared"

export interface IModalConfirm extends IModalBase {
    type: "confirm"
    text: string
    confirmButtonText: string
}

const ModalConfirm = ({ callback, text, confirmButtonText, ...otherProps }: IModalConfirm & IModalControlProps) => {
    return (
        <BaseModal {...otherProps} callback={callback} centered>
            <ModalBody>
                <Row className="text-center">
                    <div className="col">
                        <h3>{text}</h3>
                    </div>
                </Row>
            </ModalBody>
            <ModalFooter
                wideButtons
                leftButton={{ onClick: async () => callback("declined"), text: "Cancel", isInvertedColor: true }}
                rightButton={{ onClick: async () => callback("accepted"), text: confirmButtonText }}
            />
        </BaseModal>
    )
}

export default ModalConfirm
