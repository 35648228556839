import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
    EMerchantPreOnboardingStatusFilter,
    IMerchantOnboardingServicesSubForm,
    IMerchantPreOnboarding,
    IMerchantPreOnboardingEvent,
    IMerchantPreOnboardingInternalUser,
    IMerchantPreOnboardingOwner,
} from "swiipe.portal.shared"

export interface IMerchantPreOnboardingStoreAddition {
    merchantPreOnboarding: IMerchantPreOnboardingSliceState
}

export type IMerchantPreOnboardingFilter = {
    sortByProperty: EMerchantPreOnboardingSortingTypes
    isOrderByDescending: boolean
    onlyMyPreOnboardings: boolean
    onlyAssignedToMe: boolean
    status?: EMerchantPreOnboardingStatusFilter
}

export interface IMerchantPreOnboardingDetailsState {
    preOnboarding: IMerchantPreOnboarding
    creator: IMerchantPreOnboardingInternalUser
    assigned?: IMerchantPreOnboardingInternalUser
    owner: IMerchantPreOnboardingOwner
    events: IMerchantPreOnboardingEvent[]
    services: IMerchantOnboardingServicesSubForm
}

export type EMerchantPreOnboardingSortingTypes = "created" | "lastUpdateOrEvent" | "merchantName" | "estimatedValueEUR"

interface IMerchantPreOnboardingSliceState {
    continuationToken?: string
    preOnboardings?: IMerchantPreOnboarding[]
    swMerchantIdToPreOnboardingId: { [swMerchantId: string]: string }
    details: {
        [preOnboardingId: string]: IMerchantPreOnboardingDetailsState | undefined
    }
    filters: IMerchantPreOnboardingFilter
}

const initialState: IMerchantPreOnboardingSliceState = {
    details: {},
    swMerchantIdToPreOnboardingId: {},
    filters: { isOrderByDescending: true, sortByProperty: "created", onlyMyPreOnboardings: false, onlyAssignedToMe: true },
}

export const merchantPreOnboardingSlice = createSlice({
    name: "merchantPreOnboarding",
    initialState,
    reducers: {
        setMerchantPreOnboardings: (
            state,
            action: PayloadAction<{ preOnboardings: IMerchantPreOnboarding[]; continuationToken: string | undefined }>
        ) => {
            state.preOnboardings = action.payload.preOnboardings
            state.continuationToken = action.payload.continuationToken
        },
        setMerchantPreOnboardingFilters: (state, action: PayloadAction<IMerchantPreOnboardingFilter>) => {
            state.filters = action.payload
            state.preOnboardings = undefined
        },
        setMerchantPreOnboardingDetails: (
            state,
            action: PayloadAction<{
                preOnboardingId: string
                swMerchantId: string | undefined
                preOnboarding: IMerchantPreOnboarding
                creator: IMerchantPreOnboardingInternalUser
                assigned?: IMerchantPreOnboardingInternalUser
                owner: IMerchantPreOnboardingOwner
                events: IMerchantPreOnboardingEvent[]
                services: IMerchantOnboardingServicesSubForm
            }>
        ) => {
            state.details[action.payload.preOnboardingId] = {
                preOnboarding: action.payload.preOnboarding,
                creator: action.payload.creator,
                assigned: action.payload.assigned,
                owner: action.payload.owner,
                events: action.payload.events,
                services: action.payload.services,
            }
            if (action.payload.swMerchantId) {
                state.swMerchantIdToPreOnboardingId[action.payload.swMerchantId] = action.payload.preOnboardingId
            }
        },
    },
})

export const merchantPreOnboardingActions = merchantPreOnboardingSlice.actions
export const merchantPreOnboardingSelectors = {
    detailsBySwMerchantId: (state: IMerchantPreOnboardingStoreAddition, swMerchantId: string) =>
        state.merchantPreOnboarding.swMerchantIdToPreOnboardingId[swMerchantId]
            ? state.merchantPreOnboarding.details[state.merchantPreOnboarding.swMerchantIdToPreOnboardingId[swMerchantId]]
            : undefined,
    details: (state: IMerchantPreOnboardingStoreAddition, preOnboardingId: string) =>
        state.merchantPreOnboarding.details[preOnboardingId],
    preOnboardings: (state: IMerchantPreOnboardingStoreAddition) => state.merchantPreOnboarding.preOnboardings,
    continuationToken: (state: IMerchantPreOnboardingStoreAddition) => state.merchantPreOnboarding.continuationToken,
    filters: (state: IMerchantPreOnboardingStoreAddition) => state.merchantPreOnboarding.filters,
}
