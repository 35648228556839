import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { Form } from "reactstrap"
import {
    FloatingLabelInput,
    PageContainer,
    PageWrapper,
    StandardButton,
    StandardButtonWithSpinner,
    useSelector,
} from "swiipe.portal.shared"
import SubmitButton from "../../components/buttons/SubmitButton"
import EmailTemplateImageDisplay from "../../components/emailTemplate/EmailTemplateImageDisplay"
import ShowErrorMessages from "../../components/form/ShowErrorMessages"
import DropDownList from "../../components/form/input/DropDownList"
import CodeEditor from "../../components/form/syntax/CodeEditor"
import PageTitle from "../../components/page/PageTitle"
import TemplateDataExample from "../../components/template/TemplateDataExample"
import TemplateLegend from "../../components/template/TemplateLegend"
import emailTemplateTypes, { IEmailTemplateType } from "../../data/emailTemplateTypes"
import { languages } from "../../data/languages"
import { getBaseTemplatesWithBestLanguageFit, getImagesWithBestLanguageFit } from "../../services/emailTemplateService"
import { StoreState } from "../../store/StoreState"
import { emailTemplateSelectors } from "../../store/reducers/emailTemplateReducer"
import { getEmailTemplateImagesThunk } from "../../store/thunks/emailTemplateImageThunks"
import {
    getEmailBaseTemplatesThunk,
    getEmailTemplatesThunk,
    updateEmailTemplateThunk,
} from "../../store/thunks/emailTemplateThunks"
import { sendTestEmailThunk } from "../../store/thunks/emailThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { sortAscending } from "../../util/arrayUtil"
import { valFuncPattern, validationPatterns } from "../../util/validationUtil"
import "./TemplatePage.scss"

interface IEmailTemplatePage {}

interface IForm {
    fromEmail: string
    toEmail: string
    ccToEmails: string
    fromName: string
    toName: string
    emailBaseTypeId: string
}

const EmailTemplatePage = ({}: IEmailTemplatePage) => {
    const dispatch = useReduxDispatch()
    const { handleSubmit, register, errors, formState, setValue, getValues } = useForm<IForm>()
    const [showHelp, setShowHelp] = useState(false)
    const [showExampleData, setShowExampleData] = useState(false)
    const [showImages, setShowImages] = useState(false)
    const [subject, setSubject] = useState("")
    const [body, setBody] = useState("")
    const [showEmailBaseTemplate, setShowEmailBaseTemplate] = useState(false)
    const [emailTemplateType, setEmailTemplateType] = useState(emailTemplateTypes[0].type)
    const [language, setLanguage] = useState(languages[0].code)

    const emailTemplate = useSelector((state: StoreState) =>
        emailTemplateSelectors.emailTemplate(state, emailTemplateType, language)
    )

    const images = useSelector(emailTemplateSelectors.emailTemplateImages) ?? []
    const imagesBestFits = getImagesWithBestLanguageFit(images, language)

    const emailBaseTemplates = useSelector((state: StoreState) => emailTemplateSelectors.emailBaseTemplates(state))
    const emailBaseTemplate = emailBaseTemplates?.find((x) => x.typeId === getValues().emailBaseTypeId && x.language && language)

    const exampleData = emailTemplateTypes.find((t) => t.type === emailTemplateType)?.exampleData ?? {}

    useEffect(() => {
        dispatch(getEmailTemplatesThunk(false))
        dispatch(getEmailBaseTemplatesThunk(false))
        dispatch(getEmailTemplateImagesThunk(false))
    }, [])

    useEffect(() => {
        setSubject(emailTemplate?.subject ?? "")
        setBody(emailTemplate?.body ?? "")
        setValue("fromEmail", emailTemplate?.fromEmail ?? "")
        setValue("fromName", emailTemplate?.fromName ?? "")
        setValue("toEmail", emailTemplate?.defaultToEmail ?? "")
        setValue("ccToEmails", emailTemplate?.defaultCcToEmails ? emailTemplate.defaultCcToEmails.join(", ") : "")
        setValue("toName", emailTemplate?.defaultToName ?? "")
        setValue("emailBaseTypeId", emailTemplate?.emailBaseTypeId ?? "")
    }, [emailTemplate])

    const onSubmit: SubmitHandler<IForm> = async (data) => {
        try {
            await dispatch(
                updateEmailTemplateThunk({
                    body,
                    subject,
                    fromEmail: data.fromEmail,
                    fromName: data.fromName,
                    defaultToEmail: data.toEmail,
                    defaultCcToEmails: data.ccToEmails === "" ? [] : data.ccToEmails.split(",").map((e) => e.trim()),
                    defaultToName: data.toName,
                    emailType: emailTemplateType,
                    language: language,
                    emailBaseTypeId: data.emailBaseTypeId,
                })
            )
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    return (
        <PageWrapper fullWidth>
            <PageContainer id="template-page">
                <PageTitle title="Update Email Template" />
                <div className="ddl-container">
                    <DropDownList
                        label="Email Template Type"
                        containerClassName="template-type"
                        value={emailTemplateType}
                        onChange={(v) => setEmailTemplateType(v.target.value)}
                        options={sortAscending<IEmailTemplateType>(emailTemplateTypes, (emailType) => emailType.label).map(
                            (x) => ({
                                text: x.label,
                                value: x.type,
                            })
                        )}
                    />
                    <DropDownList
                        label="Language"
                        containerClassName="language"
                        value={language}
                        onChange={(v) => setLanguage(v.target.value)}
                        options={languages.map((x) => ({ text: x.label, value: x.code }))}
                    />
                </div>
                <label>Actions</label>
                <div className="actions">
                    <StandardButton
                        className="show-help"
                        isSmall
                        invertedBlue
                        title={showHelp ? "Hide help" : "Show help"}
                        onClick={() => setShowHelp(!showHelp)}
                    />
                    <StandardButton
                        isSmall
                        invertedBlue
                        title={showExampleData ? "Hide Example Data" : "Show Example Data"}
                        onClick={() => setShowExampleData(!showExampleData)}
                    />
                    <StandardButton
                        isSmall
                        invertedBlue
                        title={showImages ? "Hide Images" : "Show Images"}
                        onClick={() => setShowImages(!showImages)}
                    />
                    <StandardButtonWithSpinner
                        containerClass="send-test-btn"
                        title="Send To Myself (Must save first)"
                        isSmall
                        onClick={async () => {
                            if (!emailTemplate) {
                                alert("You need to save the template first")
                                return
                            }
                            await dispatch(sendTestEmailThunk(emailTemplate, exampleData))
                        }}
                    />
                </div>
                {showExampleData && <TemplateDataExample data={exampleData} />}
                {showHelp && <TemplateLegend templateType="email" />}
                {showImages && (
                    <div className="image-container">
                        {imagesBestFits.map((img, index) => (
                            <EmailTemplateImageDisplay key={index} image={img} language={language} />
                        ))}
                    </div>
                )}

                <Form onSubmit={handleSubmit(onSubmit)} className="mt-5">
                    <div className="address-container">
                        <FloatingLabelInput
                            name="fromEmail"
                            defaultValue={emailTemplate?.fromEmail ?? ""}
                            innerRef={register(valFuncPattern(validationPatterns.email, "Wrong email format"))}
                            placeholder="From email"
                        />
                        <FloatingLabelInput
                            name="fromName"
                            defaultValue={emailTemplate?.fromName ?? ""}
                            innerRef={register}
                            placeholder="From name"
                        />
                        <FloatingLabelInput
                            name="toEmail"
                            defaultValue={emailTemplate?.defaultToEmail ?? ""}
                            innerRef={register(valFuncPattern(validationPatterns.email, "Wrong email format"))}
                            placeholder="Default to email"
                        />
                        <FloatingLabelInput
                            name="ccToEmails"
                            defaultValue={emailTemplate?.defaultCcToEmails ?? ""}
                            innerRef={register(valFuncPattern(validationPatterns.emailsCommaSeparated, "Wrong cc emails format"))}
                            placeholder="CC to emails (sep. by ',')"
                        />
                        <FloatingLabelInput
                            name="toName"
                            defaultValue={emailTemplate?.defaultToName ?? ""}
                            innerRef={register}
                            placeholder="Default to name"
                        />
                        <SubmitButton formState={formState} isLarge containerClass="save-button">
                            Save Email Template
                        </SubmitButton>
                    </div>
                    <ShowErrorMessages<IForm> errors={errors} />
                </Form>
                <div className="email-base-container">
                    <DropDownList
                        label="Email Base Template"
                        className="email-base"
                        name="emailBaseTypeId"
                        innerRef={register}
                        options={[
                            { text: "None", value: "" },
                            ...getBaseTemplatesWithBestLanguageFit(emailBaseTemplates ?? [], language).map((x) => ({
                                text: x.name + (x.language === "shared" ? " (shared)" : ""),
                                value: x.typeId,
                            })),
                        ]}
                    />

                    <StandardButton
                        className="show-code"
                        isSmall
                        invertedBlue
                        title={showEmailBaseTemplate ? "Hide Base Template Code" : "Show Base Template Code"}
                        onClick={() => setShowEmailBaseTemplate(!showEmailBaseTemplate)}
                    />

                    {showEmailBaseTemplate && emailBaseTemplate && (
                        <CodeEditor
                            className="base-template-code"
                            code={emailBaseTemplate.body}
                            label="Email Base Template Code"
                            language="html"
                        />
                    )}
                </div>
                <CodeEditor
                    className="subject"
                    label="Subject"
                    code={subject}
                    onChange={(code) => setSubject(code)}
                    language="html"
                />
                <CodeEditor code={body} label="Body" onChange={(code) => setBody(code)} language="html" minHeight={300} />
            </PageContainer>
        </PageWrapper>
    )
}

export default EmailTemplatePage
