import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { IReleaseNote } from "../../type/releaseNotes/releaseNote"
import { StoreState } from "../StoreState"

export type ReleaseNotesState = Readonly<{
    releaseNotes?: IReleaseNote[]
}>

const initialState: ReleaseNotesState = {}

export const releaseNotesReducerActions = {
    setReleaseNotes: (releaseNotes: IReleaseNote[]) => createAction("APP/SET_RELEASE_NOTES", { releaseNotes }),
}

export type ReleaseNotesActions = ActionsUnion<typeof releaseNotesReducerActions>

export const releaseNotesReducer = (state: ReleaseNotesState = initialState, action: ReleaseNotesActions) => {
    switch (action.type) {
        case "APP/SET_RELEASE_NOTES": {
            return {
                ...state,
                releaseNotes: action.releaseNotes,
            }
        }
    }

    return state
}

export const releaseNotesSelectors = {
    releaseNotes: (state: StoreState) => state.releaseNotes.releaseNotes,
    releaseNoteById: (state: StoreState, releaseNoteId?: string) =>
        state.releaseNotes.releaseNotes?.find((rn) => rn.releaseNoteId === releaseNoteId),
}
