import React from "react"
import { IMerchantPreOnboarding, StandardIcon } from "swiipe.portal.shared"
import {
    EMerchantPreOnboardingIconType,
    getMerchantPreOnboardingIcon,
    getMerchantPreOnboardingIconTitle,
} from "../../services/merchantPreOnboardingService"
import "./MerchantPreOnboardingStatusIcons.scss"

interface IMerchantPreOnboardingStatusIcons {
    preOnboarding: IMerchantPreOnboarding
}

const activeBackgroundColor = "#e4fafb"
const activeForegroundColor = "#1A496D"
const notActiveForegroundColor = "#8CA3B5"

const MerchantPreOnboardingStatusIcons = ({ preOnboarding }: IMerchantPreOnboardingStatusIcons) => {
    const addIcon = (type: EMerchantPreOnboardingIconType, isActive: boolean) => (
        <StandardIcon
            className="merchant-pre-onboarding-status-icons__icon"
            svgComponent={getMerchantPreOnboardingIcon(type)}
            title={getMerchantPreOnboardingIconTitle(type)}
            padding={3}
            width={30}
            backgroundType="circle"
            backgroundColor={isActive ? activeBackgroundColor : "transparent"}
            componentForegroundColor={isActive ? activeForegroundColor : notActiveForegroundColor}
        />
    )

    return (
        <div className="merchant-pre-onboarding-status-icons">
            {addIcon("created", true)}
            {addIcon("sent", preOnboarding.isSent)}
            {addIcon("password", preOnboarding.isOwnerConfirmed)}
            {addIcon("viewed", preOnboarding.isViewed)}
            {addIcon("signed", preOnboarding.isSigned)}
            {preOnboarding.requiresAcquiring && addIcon("kyc", preOnboarding.isAcquiringOnboarded)}
        </div>
    )
}

export default MerchantPreOnboardingStatusIcons
