import { IWebshopPIQOnboardingDetails } from "../../type/paymentIQ/IWebshopPIQOnboardingDetails"
import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { StoreState } from "../StoreState"

export type PaymentIqState = Readonly<{
    onboardingDetails: { [webshopId: string]: IWebshopPIQOnboardingDetails }
    applePayEnvOnboarding?: {
        applePayId?: string
        paymentProcessingCsr?: string
        merchantIdentityCsr?: string
    }
}>

const initState: PaymentIqState = {
    onboardingDetails: {},
}

export const paymentIQReducerActions = {
    setOnboardingDetails: (webshopId: string, onboardingDetails: IWebshopPIQOnboardingDetails) =>
        createAction("APP/SET_ONBOARDING_DETAILS", { onboardingDetails, webshopId }),
    setApplePayId: (applePayId?: string) => createAction("APP/SET_APPLE_PAY_ID", { applePayId }),
    setApplePayCsrs: (paymentProcessingCsr: string, merchantIdentityCsr: string) =>
        createAction("APP/SET_APPLE_PAY_CSRS", { paymentProcessingCsr, merchantIdentityCsr }),
}

export type PaymentIQReducerActions = ActionsUnion<typeof paymentIQReducerActions>

export const paymentIQReducer = (state: PaymentIqState = initState, action: PaymentIQReducerActions): PaymentIqState => {
    switch (action.type) {
        case "APP/SET_ONBOARDING_DETAILS": {
            return {
                ...state,
                onboardingDetails: {
                    ...state.onboardingDetails,
                    [action.webshopId]: action.onboardingDetails,
                },
            }
        }
        case "APP/SET_APPLE_PAY_ID": {
            return {
                ...state,
                applePayEnvOnboarding: {
                    ...state.applePayEnvOnboarding,
                    applePayId: action.applePayId,
                },
            }
        }
        case "APP/SET_APPLE_PAY_CSRS": {
            return {
                ...state,
                applePayEnvOnboarding: {
                    ...state.applePayEnvOnboarding,
                    paymentProcessingCsr: action.paymentProcessingCsr,
                    merchantIdentityCsr: action.merchantIdentityCsr,
                },
            }
        }
    }
    return state
}

export const paymentIQSelectors = {
    onboardingDetails: (state: StoreState, webshopId: string) => state.paymentIQ.onboardingDetails[webshopId],
    getApplePayEnvOnboarding: (state: StoreState) => state.paymentIQ.applePayEnvOnboarding,
}
