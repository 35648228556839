import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk, userSelectors } from "swiipe.portal.shared"
import { StoreState } from "../StoreState"
import { ISentEmail, sentEmailReducerActions, sentEmailSelectors } from "../reducers/sentEmailReducer"
import { endpoints } from "./../../data/endpoints"
import { TUserRelation } from "./../../type/user/TUserRelation"
import { IEmailTemplate } from "./../reducers/emailTemplateReducer"

export const getSentEmailsThunk =
    (search: string, force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force) {
            if (sentEmailSelectors.emails(getState()) && search === sentEmailSelectors.search(getState())) {
                return
            }
        }

        dispatch(sentEmailReducerActions.resetEmails())
        const result = await dispatch(
            requestThunk<{ emails: ISentEmail[] }>(endpoints.Send.getSentEmails, {
                params: {
                    search: search,
                },
            })
        )

        dispatch(sentEmailReducerActions.setSearch(search))
        dispatch(sentEmailReducerActions.setEmails(result.emails))
    }

export const resendSentEmailThunk =
    (emailId: string, resendToEmail: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk(endpoints.Send.resendSentEmail, {
                data: {
                    emailId: emailId,
                    toEmail: resendToEmail,
                },
            })
        )

        dispatch(sentEmailReducerActions.addResentEmail(emailId))
    }

interface ISendEmailRequest {
    emailType: string
    language: string
    toEmail: string
    toName: string
    userRelationType: TUserRelation
    relationId: string
    webshopId?: string
    userId: string
    data: { [key: string]: any }
}

export const sendTestEmailThunk =
    (template: IEmailTemplate, data: { [key: string]: any }): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const userData = userSelectors.userData(getState())
        if (!userData) {
            return
        }

        const req: ISendEmailRequest = {
            emailType: template.emailType,
            language: template.language,
            toEmail: userData.user.email,
            toName: userData.user.fullName,
            userRelationType: "User",
            relationId: userData.user.id,
            userId: userData.user.id,
            data,
        }

        await dispatch(
            requestThunk<any>(endpoints.Send.sentTestEmail, {
                data: req,
            })
        )

        await dispatch(getSentEmailsThunk(sentEmailSelectors.search(getState()), true))
    }
