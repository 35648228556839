import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import { FloatingLabelInput, StandardButton, StandardButtonWithSpinner, useSelector } from "swiipe.portal.shared"
import ShowErrorMessages from "../../components/form/ShowErrorMessages"
import { StoreState } from "../../store/StoreState"
import { releaseNotesSelectors } from "../../store/reducers/releaseNotesReducer"
import { addReleaseNote, deleteReleaseNote, editReleaseNote } from "../../store/thunks/releaseNotesThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { IReleaseNote, TReleaseNoteContext } from "../../type/releaseNotes/releaseNote"
import { valFuncRequired } from "../../util/validationUtil"
import SubmitButton from "../buttons/SubmitButton"
import "./ReleaseNoteEditor.scss"

interface IReleaseNoteEditor {
    crossLanguageId?: string
    language: string
    context: TReleaseNoteContext
    updateCrossLanguageId: (id: string) => void
    onCloseEditor: () => void
}

interface IForm {
    version: string
    createdDate: string
}

const ReleaseNoteEditor = ({ crossLanguageId, language, context, updateCrossLanguageId, onCloseEditor }: IReleaseNoteEditor) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const { handleSubmit, register, errors, formState, setValue } = useForm<IForm>()

    const releaseNotes = useSelector(releaseNotesSelectors.releaseNotes)
    const [editingNoteId, setEditingNoteId] = useState<string | undefined>(undefined)
    const editingNote = useSelector((state: StoreState) => releaseNotesSelectors.releaseNoteById(state, editingNoteId))

    const selectedReleaseNoteCrossLanguages = releaseNotes?.filter((rn) => rn.crossLanguageId === crossLanguageId)
    const languagesWithVersion = (selectedReleaseNoteCrossLanguages ?? []).filter((rn) => rn.version)

    const [editorHtml, setEditorHtml] = useState("")
    const [editorInternalHtml, setEditorInternalHtml] = useState("")
    const [isDirty, setIsDirty] = useState(false)

    useEffect(() => {
        setEditingNoteId(
            releaseNotes?.find((rn) => rn.crossLanguageId === crossLanguageId && rn.language === language.toUpperCase())
                ?.releaseNoteId
        )
    }, [releaseNotes])

    useEffect(() => {
        if (!editingNote) {
            return
        }

        //Set saved values
        setValue("version", editingNote.version || languagesWithVersion.length > 0 ? languagesWithVersion[0].version : "")
        setValue("createdDate", editingNote.createdDate)
        setEditorHtml(editingNote.htmlBody)
        setEditorInternalHtml(editingNote.internalHtmlBody)
    }, [editingNote])

    useEffect(() => {
        setIsDirty(false)
    }, [editingNoteId])

    const onSubmit: SubmitHandler<IForm> = async (data, e) => {
        try {
            const noteData: IReleaseNote = {
                context: context,
                language: language,
                version: data.version,
                createdDate: data.createdDate,
                htmlBody: editorHtml,
                internalHtmlBody: editorInternalHtml,
                crossLanguageId,
                releaseNoteId: editingNote?.releaseNoteId || "",
            }
            if (editingNote) {
                await dispatch(editReleaseNote(noteData, editingNote.releaseNoteId))
            } else {
                const crossLanguageId = await dispatch(addReleaseNote(noteData))
                updateCrossLanguageId(crossLanguageId)
            }
            setIsDirty(false)
            onCloseEditor()
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    return (
        <div id="release-note-editor">
            <Form onSubmit={handleSubmit(onSubmit)} className="release-note-form">
                <p className="heading text-center text-uppercase">
                    {editingNote ? t("releasenotes.editor.editreleasenote") : t("releasenotes.editor.newreleasenote")}
                </p>
                <div className="input-container">
                    {t("releasenotes.editor.inputs.version")}
                    <FloatingLabelInput
                        name="version"
                        defaultValue={
                            editingNote?.version || languagesWithVersion.length > 0 ? languagesWithVersion[0].version : ""
                        }
                        innerRef={register(valFuncRequired(t("releasenotes.editor.errors.version")))}
                        className="input"
                    />
                </div>
                <div className="input-container">
                    {t("releasenotes.editor.inputs.publishdate")}
                    <FloatingLabelInput
                        name="createdDate"
                        defaultValue={editingNote?.createdDate ?? ""}
                        innerRef={register(valFuncRequired(t("releasenotes.editor.errors.publishdate")))}
                        className="input"
                    />
                </div>
                <div className="input-container mb-3">
                    {t("releasenotes.editor.inputs.text")}
                    <CKEditor
                        editor={ClassicEditor}
                        data={editingNote?.htmlBody ?? ""}
                        onChange={(event: any, editor: any) => {
                            setIsDirty(true)
                            setEditorHtml(editor.getData())
                        }}
                    ></CKEditor>
                </div>
                <div className="input-container mb-3">
                    Text shown to Swiipe employees
                    <CKEditor
                        editor={ClassicEditor}
                        data={editingNote?.internalHtmlBody ?? ""}
                        onChange={(event: any, editor: any) => {
                            setIsDirty(true)
                            setEditorInternalHtml(editor.getData())
                        }}
                    ></CKEditor>
                </div>
                <ShowErrorMessages<IForm> errors={errors} />
                <div className="buttons">
                    <StandardButton
                        noBorderRadius
                        inverted
                        onClick={(e) => {
                            e.preventDefault()
                            onCloseEditor()
                        }}
                    >
                        {t("releasenotes.editor.buttons.cancel")}
                    </StandardButton>
                    <div className="save-delete">
                        {editingNote && (
                            <StandardButtonWithSpinner
                                noBorderRadius
                                inverted
                                className="delete-btn"
                                onClick={async (setShowSpinner, e) => {
                                    e.preventDefault()

                                    await dispatch(deleteReleaseNote(editingNote.releaseNoteId))
                                    setValue("version", "")
                                    setValue("createdDate", "")
                                }}
                            >
                                {t("releasenotes.editor.buttons.delete")}
                            </StandardButtonWithSpinner>
                        )}
                        <SubmitButton
                            disabled={!formState.isDirty && !isDirty}
                            dark
                            isBlue
                            noBorder
                            noBorderRadius
                            formState={formState}
                            containerClass="delete-button"
                        >
                            {t("releasenotes.editor.buttons.publish")}
                        </SubmitButton>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default ReleaseNoteEditor
