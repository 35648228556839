import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { StoreState } from "../StoreState"

export interface IPluginInfoDataPlatform {
    displayName: string
    id: string
    countries: IPluginInfoDataCountry[]
}

export interface IPluginInfoDataCountry {
    displayName: string
    id: string
    plugins: string[]
}

export interface IWebshopAssetsSettings {
    fastUpdateUntil?: Date
}

export type TPluginAssetName = "checkout" | "thankyou" | "product" | "admin" | "return"
export type TPluginAssetType = "script" | "style"

export type IPluginInfoData = IPluginInfoDataPlatform[]

export type PluginState = Readonly<{
    pluginInfoData?: IPluginInfoData
    assets: { [prop: string]: string }
    settings: { [prop: string]: IWebshopAssetsSettings }
}>
const initialState: PluginState = { assets: {}, settings: {} }

export interface IPluginAssetSelection {
    webshopId: string
    platform: string
    country: string
    plugin: string
    assetName: TPluginAssetName
    assetType: TPluginAssetType
}
function createPluginAssetKey(assetSelection: IPluginAssetSelection) {
    return `${assetSelection.webshopId}_${assetSelection.platform}_${assetSelection.country}_${assetSelection.plugin}_${assetSelection.assetName}_${assetSelection.assetType}`
}

export const pluginReducerActions = {
    addPluginInfoData: (data: IPluginInfoData) => createAction("APP/ADD_PLUGIN_INFO_DATA", { data }),
    setWebshopAssetsSettings: (webshopId: string, data: IWebshopAssetsSettings) =>
        createAction("APP/SET_WEBSHOP_ASSETS_SETTINGS", { webshopId, data }),
    setPluginAsset: (data: string, pluginAssetSelection: IPluginAssetSelection) =>
        createAction("APP/SET_PLUGIN_ASSET", { data, key: createPluginAssetKey(pluginAssetSelection) }),
}

export type PluginActions = ActionsUnion<typeof pluginReducerActions>

export const pluginReducer = (state: PluginState = initialState, action: PluginActions) => {
    switch (action.type) {
        case "APP/ADD_PLUGIN_INFO_DATA": {
            return {
                ...state,
                pluginInfoData: action.data,
            }
        }
        case "APP/SET_PLUGIN_ASSET": {
            return {
                ...state,
                assets: {
                    ...state.assets,
                    [action.key]: action.data,
                },
            }
        }
        case "APP/SET_WEBSHOP_ASSETS_SETTINGS": {
            return {
                ...state,
                settings: {
                    ...state.settings,
                    [action.webshopId]: action.data,
                },
            }
        }
    }

    return state
}

export const pluginSelectors = {
    webshopAssetsSettings: (state: StoreState, webshopId: string) =>
        state.plugin.settings[webshopId] ? state.plugin.settings[webshopId] : undefined,
    pluginInfoData: (state: StoreState) => state.plugin.pluginInfoData,
    pluginAsset: (selection: IPluginAssetSelection) => (state: StoreState) =>
        state.plugin.assets[createPluginAssetKey(selection)],
}
