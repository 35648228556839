export function removeSchemeFromHostName(hostName: string) {
    if (!hostName) {
        return ""
    }
    return hostName.replace(/(^\w+:|^)\/\//, "")
}

export function removeSchemeAndWWWFromHostName(hostName: string) {
    if (!hostName) {
        return ""
    }
    hostName = hostName.replace(/(^\w+:|^)\/\//, "")
    hostName = hostName.startsWith("www.") ? hostName.replace("www.", "") : hostName
    return hostName
}
