import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { IClearhausMerchant } from "../../type/clearhausMerchant/IClearhausMerchant"
import { StoreState } from "../StoreState"

//diamond params: Response, state, extraArgs, action
export const getClearhausMerchantThunk =
    (apiKey: string): ThunkAction<Promise<IClearhausMerchant | undefined>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (isEmptyOrWhitespace(apiKey)) {
            return
        }

        const reqConfig = { headers: { "Clearhaus-Api-Key": apiKey } }
        const endpointConfig = endpoints.Clearhaus.getClearhausMerchantData

        const response = await dispatch(requestThunk<IClearhausMerchant>(endpointConfig, reqConfig))

        return response
    }

function isEmptyOrWhitespace(str: string) {
    return str === null || str.match(/^ *$/) !== null
}
