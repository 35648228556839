import React from "react"
import { useTranslation } from "react-i18next"
import SwiipeLogoImage from "../../../images/swiipe-logo.png"
import "./FormHead.scss"

interface IFormHeadProps {
    title?: string
}

const FormHead = ({ title = "" }: IFormHeadProps) => {
    const { t } = useTranslation()
    return (
        <div className="form-head">
            <img src={SwiipeLogoImage} className="img-center mt-3" alt="swiipe-logo" />
            <h2 className="title text-center header-message mt-2">{title ? title : t("common.title")}</h2>
        </div>
    )
}

export default FormHead
