import React from "react"
import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk, userSelectors } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { ETermsType } from "../../type/terms/ETermsType"
import { StoreState } from "../StoreState"
import { addModalThunk } from "./modalThunks"
import { getTermsTemplateDetailsThunk } from "./termsTemplateThunks"

interface ITermsReleaseDto {
    termsType: ETermsType
    version: string
    revision: number
}

export const releaseTermsThunk = (): ThunkAction<Promise<void>, StoreState, null, AnyAction> => async (dispatch, getState) => {
    const infoResponse = await dispatch(requestThunk<{ toRelease: ITermsReleaseDto[] }>(endpoints.Legal.getTermsReleaseInfo))
    if (infoResponse.toRelease.length === 0) {
        await dispatch(
            addModalThunk({
                type: "info",
                text: "Nothing to release",
            })
        )
        return
    }

    const currentUser = userSelectors.userData(getState())

    const modalResult = await dispatch(
        addModalThunk({
            type: "verify",
            emailToVerify: currentUser?.user.email ?? "",
            text: `There are ${infoResponse.toRelease.length} release${
                infoResponse.toRelease.length === 1 ? "" : "s"
            } to release.`,
            content: (
                <div className="mt-2 mb-2">
                    {infoResponse.toRelease.map((r) => (
                        <div key={r.termsType + "_" + r.version + "_" + r.revision}>
                            {r.termsType} {r.version} ({r.revision}){" "}
                        </div>
                    ))}
                    <div className="mt-2">
                        <b>Type in your email to release</b>
                    </div>
                </div>
            ),
        })
    )

    if (modalResult.type !== "accepted") {
        return
    }

    await dispatch(requestThunk(endpoints.Legal.releaseTerms))
    await dispatch(
        addModalThunk({
            type: "info",
            text: "Successfully released",
        })
    )

    const fetchPromises = infoResponse.toRelease.map((release) => dispatch(getTermsTemplateDetailsThunk(release.termsType, true)))
    await Promise.all(fetchPromises)
}
