import cn from "classnames"
import React, { useEffect, useState } from "react"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Control, useWatch } from "react-hook-form"
import { Input } from "reactstrap"
import { formatShortDateWithFormat, getStringLocaleDateFormat, parseDateShortFormat } from "../../../util/dateUtil"
import { valFuncRequired } from "../../../util/validationUtil"
import FieldsetFormGroup from "../FieldsetFormGroup"
import "./DateInput.scss"

interface IDateInput {
    name: string

    placeholder?: string
    errorMessage?: string
    required?: boolean

    formControl: Control

    minDate?: Date
    maxDate?: Date
    inline?: boolean
    classNameDatePicker?: string
    onChange?: (date: Date) => void
}

const DateInput = ({
    name,
    placeholder,
    errorMessage,
    required,
    formControl,
    minDate,
    maxDate,
    inline,
    classNameDatePicker,
    onChange,
}: IDateInput) => {
    const [dateSelected, setDateSelected] = useState<Date | undefined>(undefined)

    useWatch({ name: name, control: formControl })

    const value = formControl.getValues()[name] as string
    const setDateValue = (date: Date) => {
        formControl.setValue(name, formatShortDateWithFormat(date, "yyyy-MM-dd"))
        formControl.trigger()
        onChange?.(date)
    }

    useEffect(() => {
        const newDate = parseDateShortFormat(value)
        if (dateSelected?.getTime() != newDate?.getTime()) {
            setDateSelected(newDate)
        }
    }, [value])

    return (
        <div id="date-input-container">
            <Input
                className="d-none"
                name={name}
                type="text"
                innerRef={required ? formControl.register(valFuncRequired(errorMessage ?? "")) : formControl.register()}
            />
            <FieldsetFormGroup field className={cn(classNameDatePicker)}>
                <ReactDatePicker
                    placeholderText={placeholder}
                    selected={dateSelected}
                    inline={inline}
                    className={cn("form-control")}
                    onChange={(date) => {
                        if (date) {
                            setDateSelected(date)
                            setDateValue(date)
                        }
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                    dateFormat={getStringLocaleDateFormat()}
                    showYearDropdown
                    showMonthDropdown
                />
            </FieldsetFormGroup>
        </div>
    )
}

export default DateInput
