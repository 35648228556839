import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { StoreState } from "../StoreState"
import { userRelationSelectors } from "../reducers/userRelationReducer"
import { IPartnerDetails } from "./../../type/partner/IPartnerDetails"
import { partnerReducerActions, partnerSelectors } from "./../reducers/partnerReducer"
import { ensureUserRelationsThunk } from "./userRelationThunks"

export const ensurePartnerDataThunk =
    (force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(ensureUserRelationsThunk())

        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation || currentUserRelation.relationType !== "Partner") {
            return
        }

        if (!force && partnerSelectors.partnerDetails(getState())) {
            // Partner data already fetched
            return
        }

        const partnerDetails = await dispatch(
            requestThunk<IPartnerDetails>(endpoints.Core.getPartnerData(currentUserRelation.id))
        )
        dispatch(partnerReducerActions.setPartnerDetails(currentUserRelation.id, partnerDetails))
    }

export const ensureMasterPartnerThunk =
    (force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const userRelations = userRelationSelectors.userRelations(getState())

        if (!userRelations) {
            return
        }

        if (!force && partnerSelectors.partnersContainMasterPartner(getState())) {
            return
        }

        for (const rel of userRelations) {
            if (rel.relationType === "Partner") {
                if (rel.isMasterPartner) {
                    dispatch(partnerReducerActions.setHasMasterPartner(true, rel.id))
                    return
                }
            }
        }
    }
