import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { Form } from "reactstrap"
import { PageContainer, PageWrapper, StandardButton, useSelector } from "swiipe.portal.shared"
import SpinnerContainer from "../../components/buttons/SpinnerContainer"
import SubmitButton from "../../components/buttons/SubmitButton"
import HealthStatusSection, { IHealthStatusSectionData } from "../../components/healthStatus/HealthStatusSection"
import WebshopSelect from "../../components/merchant/WebshopSelect"
import PageTitle from "../../components/page/PageTitle"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { StoreState } from "../../store/StoreState"
import { healthStatusSelectors } from "../../store/reducers/healthStatusReducer"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import { getHealthStatusesThunk, updateHealthStatusesThunk } from "../../store/thunks/healthStatusThunks"
import { setSelectedWebshopIdThunk } from "../../store/thunks/merchantThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { TPayment } from "../../type/TPayment"
import { IHealthStatusDto } from "../../type/healthStatus/IHealthStatusDto"
import { IUserRelationFlattened } from "../../type/user/IUserRelation"
import "./HealthStatusPage.scss"

interface IForm {
    paymentConfigs: {
        [key in TPayment]: IHealthStatusSectionData
    }
    generalConfig: IHealthStatusSectionData
}

interface IHealthStatusPage {}

const HealthStatusPage = ({}: IHealthStatusPage) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }, { requiredType: "Partner" }])

    const merchantDetails = useSelector(merchantSelectors.merchantDetails)

    const userRelation = useSelector(userRelationSelectors.currentUserRelation)
    const isMasterPartner = userRelation?.isMasterPartner ?? false

    const selectedWebshopId = useSelector(merchantSelectors.selectedWebshopId)

    const paymentTypeConfigs = useSelector((state: StoreState) =>
        healthStatusSelectors.paymentTypes(state, isMasterPartner, selectedWebshopId)
    )

    const healthStatuses = useSelector((state: StoreState) =>
        healthStatusSelectors.healthStatuses(state, isMasterPartner, selectedWebshopId)
    )
    const dispatch = useReduxDispatch()
    useEffect(() => {
        if (!userRelation?.isMasterPartner && merchantDetails && !selectedWebshopId) {
            dispatch(setSelectedWebshopIdThunk(merchantDetails?.swMerchant.swMerchantId, merchantDetails?.webshops[0].webshopId))
        }
        if (userRelation?.isMasterPartner || selectedWebshopId) {
            dispatch(getHealthStatusesThunk(false))
        }
    }, [userRelation, selectedWebshopId, merchantDetails])

    if (userRelation?.relationType === "Partner" && !isMasterPartner) {
        return <div>Navigate to a merchant or Swiipe Support partner to use this page</div>
    }

    const showForm = healthStatuses && userRelation && paymentTypeConfigs

    return (
        <PageWrapper wider>
            <PageContainer id="health-status-page">
                <PageTitle title="Health Status" />
                {merchantDetails && <WebshopSelect merchantDetails={merchantDetails} />}
                <SpinnerContainer showSpinner={!showForm}>
                    {!showForm && <div className="health-status-page__loading-content"></div>}
                    {healthStatuses && userRelation && paymentTypeConfigs && (
                        <HealthStatusPageForm
                            paymentTypeConfigs={paymentTypeConfigs}
                            userRelation={userRelation}
                            healthStatuses={healthStatuses}
                            selectedWebshopId={selectedWebshopId}
                        />
                    )}
                </SpinnerContainer>
            </PageContainer>
        </PageWrapper>
    )
}

interface IHealthStatusPageFormProps {
    userRelation: IUserRelationFlattened
    healthStatuses: IHealthStatusDto[]
    selectedWebshopId?: string
    paymentTypeConfigs: {
        name: TPayment
        isPiq: boolean
    }[]
}

const HealthStatusPageForm = ({
    userRelation,
    healthStatuses,
    selectedWebshopId,
    paymentTypeConfigs,
}: IHealthStatusPageFormProps) => {
    const dispatch = useReduxDispatch()

    const { handleSubmit, register, formState, getValues, setValue, reset, errors, watch } = useForm<IForm>()

    const paymentTypes: TPayment[] = paymentTypeConfigs.map((pt) => pt.name)
    const piqPaymentTypes: TPayment[] = paymentTypeConfigs.filter((pt) => pt.isPiq).map((pt) => pt.name)

    const [expandedSections, setExpandedSections] = useState<string[]>([])

    const createNameForInput = (paymentType: TPayment | "general", key: string) => {
        return paymentType === "general" ? "generalConfig." + key : "paymentConfigs[" + paymentType + "]." + key
    }

    const onSubmit: SubmitHandler<IForm> = async (data) => {
        try {
            const mapToDto = (section: IHealthStatusSectionData, isGeneral: boolean, paymentType: TPayment | undefined) => {
                const dto: IHealthStatusDto = {
                    title: section.inheritFromGeneral ? "" : section.title,
                    description: section.inheritFromGeneral ? "" : section.description,
                    enabled: section.inheritFromGeneral ? false : section.enabled,
                    level: section.inheritFromGeneral ? undefined : section.level,
                    overrideGlobal: section.inheritFromGeneral ? false : section.overrideGlobal ?? false,
                    paymentType: isGeneral ? undefined : paymentType,
                    inheritFromGeneral: section.inheritFromGeneral ?? false,
                    showFrom: undefined,
                    showTo: undefined,
                    hidePaymentMethod: section.hidePaymentMethod ?? false,
                    hideSavedPaymentMethods: section.hideSavedPaymentMethods ?? false,
                    useFallback: section.useFallback ?? false,
                    updatedByUserId: "", // Will be set by server
                }
                return dto
            }
            const generelDto = mapToDto(data.generalConfig, true, undefined)
            const paymentTypeDtos = Object.keys(data.paymentConfigs).map((pt) =>
                mapToDto(data.paymentConfigs[pt], false, pt as TPayment)
            )
            await dispatch(updateHealthStatusesThunk([generelDto, ...paymentTypeDtos]))
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    const expandRelevantSections = () => {
        const types: (TPayment | "general")[] = ["general", ...paymentTypes]
        const values = getValues()
        setExpandedSections(
            types.filter((pt) => {
                if (pt === "general") {
                    return true
                }
                const status = values.paymentConfigs[pt]
                return status?.enabled || status?.inheritFromGeneral
            })
        )
    }

    useEffect(() => {
        const types: (TPayment | "general")[] = ["general", ...paymentTypes]
        types.forEach((pt) => {
            const healthStatus = healthStatuses.find((hs) => (pt === "general" ? !hs.paymentType : hs.paymentType === pt))
            const data: IHealthStatusSectionData = {
                title: healthStatus?.title ?? "",
                description: healthStatus?.description ?? "",
                level: healthStatus?.level ?? "Low",
                inheritFromGeneral: healthStatus?.inheritFromGeneral ?? false,
                enabled: healthStatus?.enabled ?? false,
                hidePaymentMethod: healthStatus?.hidePaymentMethod ?? false,
                hideSavedPaymentMethods: healthStatus?.hideSavedPaymentMethods ?? false,
                overrideGlobal: healthStatus?.overrideGlobal ?? false,
                useFallback: healthStatus?.useFallback ?? false,
            }
            Object.keys(data).forEach((key) => setValue(createNameForInput(pt, key), data[key]))
        })
        expandRelevantSections()
    }, [healthStatuses, selectedWebshopId])

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="health-status-page__main-action-container">
                <StandardButton
                    isSmall
                    title="Set PaymentIQ not responding settings"
                    onClick={(e) => {
                        e.preventDefault()
                        setValue(createNameForInput("general", "level"), "High")
                        piqPaymentTypes.forEach((pt) => {
                            const data: IHealthStatusSectionData = {
                                title: "",
                                description: "",
                                level: "Low",
                                inheritFromGeneral: true,
                                enabled: true,
                                hidePaymentMethod: pt !== "CreditCard",
                                hideSavedPaymentMethods: true,
                                overrideGlobal: false,
                                useFallback: pt === "CreditCard",
                            }
                            Object.keys(data).forEach((key) => setValue(createNameForInput(pt, key), data[key]))
                        })
                        expandRelevantSections()
                    }}
                />
                <StandardButton
                    isSmall
                    title="Clear Payment type settings"
                    onClick={(e) => {
                        e.preventDefault()
                        paymentTypes.forEach((pt) => {
                            const data: IHealthStatusSectionData = {
                                title: "",
                                description: "",
                                level: "Low",
                                inheritFromGeneral: false,
                                enabled: false,
                                hidePaymentMethod: false,
                                hideSavedPaymentMethods: false,
                                overrideGlobal: false,
                                useFallback: false,
                            }
                            Object.keys(data).forEach((key) => setValue(createNameForInput(pt, key), data[key]))
                        })
                        expandRelevantSections()
                    }}
                />
                <SubmitButton isSmall containerClass="btn-save" dark noBorder noBorderRadius formState={formState}>
                    Save changes
                </SubmitButton>
            </div>
            <HealthStatusSection
                register={register}
                data={healthStatuses.find((hs) => !hs.paymentType)}
                paymentType="general"
                isGlobal={userRelation.isMasterPartner}
                resolvedShouldInheritFromGeneral={watch(createNameForInput("general", "inheritFromGeneral"))}
                createNameForInput={(key: string) => createNameForInput("general", key)}
                isExpanded={expandedSections.includes("general")}
                expandedDidChange={(isExpanded) => {
                    const withoutSection = expandedSections.filter((es) => es !== "general")
                    setExpandedSections(isExpanded ? [...withoutSection, "general"] : withoutSection)
                }}
            />
            {paymentTypes.map((pt) => (
                <div key={pt} className="health-status-page__payment-section-container">
                    <HealthStatusSection
                        paymentType={pt}
                        data={healthStatuses.find((hs) => hs.paymentType === pt)}
                        isGlobal={userRelation.isMasterPartner}
                        register={register}
                        resolvedShouldInheritFromGeneral={watch(createNameForInput(pt, "inheritFromGeneral"))}
                        createNameForInput={(key: string) => createNameForInput(pt, key)}
                        isExpanded={expandedSections.includes(pt)}
                        expandedDidChange={(isExpanded) => {
                            const withoutSection = expandedSections.filter((es) => es !== pt)
                            setExpandedSections(isExpanded ? [...withoutSection, pt] : withoutSection)
                        }}
                    />
                </div>
            ))}
        </Form>
    )
}

export default HealthStatusPage
