import { map, mount, redirect, route } from "navi"
import React from "react"
import LoginPage from "../pages/LoginPage"
import ApplePaySwiipeCertificatesSetup from "../pages/applePay/ApplePaySwiipeCertificatesSetup"
import PiqConfigGeneratorPage from "../pages/configurations/PiqConfigGeneratorPage"
import CustomerContactsPage from "../pages/customerContacts/CustomerContactsPage"
import SentEmailsPage from "../pages/email/SentEmailsPage"
import HealthStatusPage from "../pages/healthStatus/HealthStatusPage"
import LoginDetailsPage from "../pages/merchant/LoginDetailsPage"
import ManageBillingForMerchantPage from "../pages/merchant/ManageBillingForMerchantPage"
import OrderRecalculationPage from "../pages/merchant/OrderRecalculationPage"
import WebshopApiTesterPage from "../pages/merchant/WebshopApiTesterPage"
import WebshopAssetEditPage from "../pages/merchant/WebshopAssetEditPage"
import GoogleAnalyticsEventOverrides from "../pages/merchant/googleAnalytics/GoogleAnalyticsEventOverrides"
import MerchantDetailsPage from "../pages/merchant/merchantDetailsPage/MerchantDetailsPage"
import MerchantPreOnboardingsTablePage from "../pages/merchantOnboarding/MerchantPreOnboardingsTablePage"
import SwiipeEnsureOnboardingPage from "../pages/paymentsDownFallback/SwiipeEnsureOnboardingPage"
import PlusSellSessionsPage from "../pages/plusSell/PlusSellSessionsPage"
import ReleaseNotesPage from "../pages/releasenotes/ReleaseNotesPage"
import SentSmsesPage from "../pages/sms/SentSmsesPage"
import EmailBaseTemplatePage from "../pages/template/EmailBaseTemplatePage"
import EmailTemplateImagesPage from "../pages/template/EmailTemplateImagesPage"
import EmailTemplatePage from "../pages/template/EmailTemplatePage"
import SmsTemplatePage from "../pages/template/SmsTemplatePage"
import TermsBaseTemplatePage from "../pages/terms/TermsBaseTemplatePage"
import TermsTemplatePage from "../pages/terms/TermsTemplatePage"
import TestRunDashboardPage from "../pages/testResults/TestRunDashboardPage"
import DeleteUserPage from "../pages/user/DeleteUserPage"
import ManageUserRelationsPage from "../pages/user/ManageUserRelationsPage"
import { authService } from "../services/authService"
import { navigationService } from "../services/navigationService"
import { waitForever } from "../util/timeUtil"
import { INavigationContext } from "./INavigationContext"

export const loggedInRoutes = mount<INavigationContext>({
    "/": map<INavigationContext>(async (request, context) => {
        const returnUrl = request.query.returnUrl
        if (returnUrl) {
            return redirect(returnUrl, { exact: false })
        }
        return route({
            view: <MerchantDetailsPage />,
        })
    }),
    "/merchantDetails": route({
        view: <MerchantDetailsPage />,
    }),
    "/sentEmails": route({
        view: <SentEmailsPage />,
    }),
    "/updateEmailTemplate": route({
        view: <EmailTemplatePage />,
    }),
    "/customerContacts": route({
        view: <CustomerContactsPage />,
    }),
    "/updateBaseTemplate": route({
        view: <EmailBaseTemplatePage />,
    }),
    "/emailTemplateImages": route({
        view: <EmailTemplateImagesPage />,
    }),
    "/updateTermsTemplate": route({
        view: <TermsTemplatePage />,
    }),
    "/updateTermsBaseTemplate": route({
        view: <TermsBaseTemplatePage />,
    }),
    "/applePaySwiipeCertificatesSetup": route({
        view: <ApplePaySwiipeCertificatesSetup />,
    }),
    "/manageuserrelations": route({
        view: <ManageUserRelationsPage />,
    }),
    "/deleteUser": route({
        view: <DeleteUserPage />,
    }),
    "/managewebshopassets": route({
        view: <WebshopAssetEditPage />,
    }),
    "/webshopApiTester": route({
        view: <WebshopApiTesterPage />,
    }),
    "/piqConfigGenerator": route({
        view: <PiqConfigGeneratorPage />,
    }),
    "/swEnsureOnboarding": route({
        view: <SwiipeEnsureOnboardingPage />,
    }),
    "/release_notes": route({
        view: <ReleaseNotesPage />,
    }),
    "/updateSmsTemplate": route({
        view: <SmsTemplatePage />,
    }),
    "/sentSmses": route({
        view: <SentSmsesPage />,
    }),
    "/credentials": route({
        view: <LoginDetailsPage />,
    }),
    "/order": route({
        view: <OrderRecalculationPage />,
    }),
    "/manageBilling": route({
        view: <ManageBillingForMerchantPage />,
    }),
    "/testRuns": route({
        view: <TestRunDashboardPage />,
    }),
    "/plusSellSessions": route({
        view: <PlusSellSessionsPage />,
    }),
    "/healthStatus": route({
        view: <HealthStatusPage />,
    }),
    "/merchantOnboardings": route({
        view: <MerchantPreOnboardingsTablePage />,
    }),
    "/gaEventOverrides": route({
        view: <GoogleAnalyticsEventOverrides />,
    }),
})

export const mainRoutes = mount<INavigationContext>({
    "*": map<INavigationContext>(async (request, context) => {
        if (request.params.forceauth === "1") {
            const url = request.originalUrl.split("#")[0].replace("forceauth=1", "forceauth=0")
            await authService.login(url)
            return loggedInRoutes
        }
        if (!authService.getIsLoggedIn()) {
            if (navigationService.tvMode) {
                throw "Accessing logged in routes when in TV-mode not logged in"
            }
            if (authService.getIsWaitingForRedirectToAuth()) {
                await waitForever()
            }
            if (request.url === "/") {
                return redirect("/login")
            }
            if (authService.isAuthChecked() || authService.getIsWaitingForLinkToLoginPage()) {
                authService.setAuthChecked(false)
                authService.setIsWaitingForLinkToLoginPage(true)
                return redirect("/login?returnUrl=" + encodeURIComponent(request.originalUrl), { exact: false })
            } else {
                authService.setAuthChecked(true)
                authService.setIsWaitingForRedirectToAuth(true)
                await authService.login(request.originalUrl.split("#")[0])
            }
        }
        return loggedInRoutes
    }),
    "/login": map<INavigationContext>(async (request, context) => {
        if (authService.getIsLoggedIn()) {
            return redirect(
                // Redirect to the value of the URL's `redirectTo` parameter. If no
                // redirectTo is specified, default to `/resource/favorite-foods/`.
                request.params.redirectTo ? decodeURIComponent(request.params.redirectTo) : "/",
                { exact: false }
            )
        }
        return route({
            title: "Swiipe Admin Portal",
            getView: async (req, context) => {
                return <LoginPage />
            },
        })
    }),
    "/testRunsTv": route({
        view: <TestRunDashboardPage isTvMode />,
    }),
})
