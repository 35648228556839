import React from "react"
import { useTranslation } from "react-i18next"
import { PageWrapper } from "swiipe.portal.shared"
import "./NotFoundPage.scss"

interface INotFoundPageProps {}

const NotFoundPage = ({}: INotFoundPageProps) => {
    const { t } = useTranslation()
    return (
        <PageWrapper>
            <div id="not-found">
                <div id="not-found-container">
                    <div id="not-found-header" className="text-center">
                        <h1>{t("notfound.title")}</h1>
                    </div>
                    <div id="not-found-text" className="text-center">
                        <p>{t("notfound.text")}</p>
                    </div>
                </div>
            </div>
        </PageWrapper>
    )
}

export default NotFoundPage
