import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { TextButton, useSelector } from "swiipe.portal.shared"
import { formatTrackedTime } from "../../services/timeTrackService"
import { StoreState } from "../../store/StoreState"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { trackedTimeSelectors } from "../../store/reducers/trackedTimeReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { deleteTrackedTimeThunk, ensureTrackedTimeThunk } from "../../store/thunks/trackedTimeThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { sortDescending } from "../../util/arrayUtil"
import { formatDate } from "../../util/dateUtil"
import { secondsToZeroPaddedTime } from "../../util/timeUtil"
import ToggleButtonCheckbox from "../form/input/ToggleButtonCheckbox"
import "./TrackedTimeList.scss"

interface ITrackedTimeList {
    selectedWebshopId?: string
}

const TrackedTimeList = ({ selectedWebshopId }: ITrackedTimeList) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)
    const trackings = useSelector((state: StoreState) => trackedTimeSelectors.trackedTime(state, currentUserRelation?.id ?? ""))
    const swMerchant = useSelector(merchantSelectors.merchantDetails)

    useEffect(() => {
        dispatch(ensureTrackedTimeThunk(currentUserRelation?.id ?? "", false))
    }, [currentUserRelation])

    const allTrackings = sortDescending(trackings ?? [], (tr) => tr.startTime)
    const totalSecondsSpentAll = trackings?.reduce((acc, tr) => acc + tr.seconds, 0) ?? 0
    const webshopTrackings = allTrackings.filter((tr) => tr.webshopId === selectedWebshopId)
    const totalSecondsSpentWebshop = webshopTrackings?.reduce((acc, tr) => acc + tr.seconds, 0) ?? 0

    const [showAll, setShowAll] = useState(webshopTrackings.length === 0)

    useEffect(() => {
        setShowAll(webshopTrackings.length === 0)
    }, [selectedWebshopId])

    return (
        <div className="tracked-time-list">
            {totalSecondsSpentWebshop > 0 && (
                <p>
                    Total time spent on selected webshop: <b>{formatTrackedTime(totalSecondsSpentWebshop)}</b>
                </p>
            )}
            <p>
                Total time spent on all webshops: <b>{totalSecondsSpentAll > 0 ? formatTrackedTime(totalSecondsSpentAll) : 0}</b>
            </p>
            <ToggleButtonCheckbox
                checked={showAll}
                className="tracked-time-list__show-all"
                id="tracking-show-all"
                label={t("tracking.showAll")}
                onClick={async () => {
                    setShowAll((prev) => !prev)
                }}
            ></ToggleButtonCheckbox>
            <table>
                <tbody>
                    <tr>
                        <th>Date</th>
                        <th>Time spent</th>
                        <th>Admin</th>
                        <th>Type</th>
                        <th>Comment</th>
                        {showAll && <th>Webshop</th>}
                        <th>Actions</th>
                    </tr>
                    {(showAll ? allTrackings : webshopTrackings).map((tr) => {
                        return (
                            <tr key={tr.id}>
                                <td>{formatDate(new Date(tr.startTime), "dash-international")}</td>
                                <td>{secondsToZeroPaddedTime(tr.seconds)}</td>
                                <td>{tr.lastUpdatedByUserName}</td>
                                <td>{tr.type}</td>
                                <td>{tr.comment}</td>
                                {showAll && (
                                    <td>
                                        {swMerchant?.webshops.find((shop) => shop.webshopId === tr.webshopId ?? "")?.hostName ??
                                            tr.webshopId ??
                                            "All"}
                                    </td>
                                )}
                                <td className="tracked-time-list__actions">
                                    <TextButton
                                        text="Edit"
                                        textDecoration="underline"
                                        className="tracked-time-list__actions-edit"
                                        onClick={async () => {
                                            dispatch(
                                                addModalThunk({
                                                    type: "trackTime",
                                                    organizationId: currentUserRelation?.id ?? "",
                                                    updateExisting: tr,
                                                    selectedWebshopId: tr.webshopId ?? "all",
                                                })
                                            )
                                        }}
                                    />
                                    <TextButton
                                        text="Delete"
                                        textDecoration="underline"
                                        color="red"
                                        className="tracked-time-list__actions-delete"
                                        onClick={async () => {
                                            await dispatch(deleteTrackedTimeThunk(currentUserRelation?.id ?? "", tr.id))
                                        }}
                                    />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <TextButton
                className="tracked-time-list__add-new"
                text={"+ Add new tracking"}
                textDecoration="underline"
                onClick={async () => {
                    dispatch(
                        addModalThunk({
                            type: "trackTime",
                            updateExisting: undefined,
                            organizationId: currentUserRelation?.id ?? "",
                            selectedWebshopId,
                        })
                    )
                }}
            />
        </div>
    )
}

export default TrackedTimeList
