export function getDistinct<T>(arr: T[], selector?: (item: T) => any) {
    const values: T[] = []
    const results: T[] = []
    arr.forEach((elem) => {
        const val = selector ? selector(elem) : elem
        if (values.indexOf(val) < 0) {
            values.push(val)
            results.push(elem)
        }
    })
    return results
}

export function hasValue<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && typeof value !== "undefined"
}

export function sortAscending<T>(arr: T[], selector?: (item: T) => any) {
    return [...arr].sort((i1, i2) => {
        const r1 = selector ? selector(i1) : i1
        const r2 = selector ? selector(i2) : i2
        if (r1 === r2) {
            return 0
        }
        return r1 < r2 ? -1 : 1
    })
}

export function sortDescending<T>(arr: T[], selector?: (item: T) => any) {
    return [...arr].sort((i1, i2) => {
        const r1 = selector ? selector(i1) : i1
        const r2 = selector ? selector(i2) : i2
        if (r1 === r2) {
            return 0
        }
        return r1 < r2 ? 1 : -1
    })
}

export function toDict<T, U extends string, V>(array: T[], getKey: (item: T) => U, getValue: (item: T) => V) {
    const res: { [key in U]: V } = {} as { [key in U]: V }
    array.forEach((i) => {
        res[getKey(i)] = getValue(i)
    })
    return res
}

export function sortByValueOrder<T, V>(array: T[], getValue: (item: T) => V, valueOrder: V[]) {
    return [...array].sort((a, b) => {
        const aIndex = valueOrder.indexOf(getValue(a))
        const bIndex = valueOrder.indexOf(getValue(b))
        if (aIndex === -1) {
            return 1
        } else if (bIndex === -1) {
            return -1
        } else {
            return aIndex - bIndex
        }
    })
}

export function groupBy<T>(arr: T[], selector: (item: T) => string) {
    const groups: { [key: string]: T[] } = {}

    arr.map((item) => {
        const groupKey = selector(item)
        groups[groupKey] = groups[groupKey] ?? []
        groups[groupKey].push(item)
    })

    return groups
}
