import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Alert } from "reactstrap"
import {
    PageContainer,
    PageWrapper,
    SearchBox,
    StandardButtonWithSpinner,
    useSelector,
    userSelectors,
} from "swiipe.portal.shared"
import SpinnerContainer from "../../components/buttons/SpinnerContainer"
import PageTitle from "../../components/page/PageTitle"
import emailTemplateTypes from "../../data/emailTemplateTypes"
import { sentEmailSelectors } from "../../store/reducers/sentEmailReducer"
import { getSentEmailsThunk, resendSentEmailThunk } from "../../store/thunks/emailThunks"
import { addModalThunk } from "../../store/thunks/modalThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { getFormattedDate } from "../../util/dateUtil"
import "./SentEmailsPage.scss"

interface ISentEmailsPage {}

const SentEmailsPage = ({}: ISentEmailsPage) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()
    const initialSearch = useSelector(sentEmailSelectors.search)
    const [searchValue, setSearchValue] = useState(initialSearch)

    useEffect(() => {
        dispatch(getSentEmailsThunk(initialSearch, false))
    }, [])

    useEffect(() => {
        dispatch(getSentEmailsThunk(searchValue, false))
    }, [searchValue])

    const emails = useSelector(sentEmailSelectors.emails)
    const resentEmailIds = useSelector(sentEmailSelectors.resentEmailsIds)
    const userData = useSelector(userSelectors.userData)

    if (!userData) {
        return null
    }

    return (
        <PageWrapper fullWidth>
            <PageContainer id="sentemails-page">
                <PageTitle title={t("sentemails.emails")} />
                <SearchBox
                    className="search"
                    currentValue={searchValue}
                    delayOnChangeMs={1500}
                    placeholder="Search by email"
                    onValueChange={(val) => {
                        setSearchValue(val)
                    }}
                />
                <SpinnerContainer showSpinner={!emails}>
                    {emails && emails.length === 0 && <div className="no-emails">No emails for the current search criteria</div>}
                    <table className="emails-table table-responsive-md">
                        <tbody>
                            <tr>
                                <th>{t("sentemails.created")}</th>
                                <th>{t("sentemails.type")}</th>
                                <th>{t("sentemails.sentto")}</th>
                                <th></th>
                            </tr>
                            {(emails ?? []).map((email, i) => {
                                return (
                                    <tr key={email.emailId + "_" + i}>
                                        <td>{getFormattedDate(email.created, "slashes")}</td>
                                        <td>{emailTemplateTypes.find((t) => t.type === email.type)?.label ?? email.type}</td>
                                        <td className="sent-to">
                                            <span className="name">{email.sentToName}</span>
                                            <span>{email.sentToEmail}</span>
                                            {!!email.sentCcToEmails?.length && (
                                                <span>
                                                    <b>cc: </b>
                                                    {email.sentCcToEmails.map(
                                                        (e, i) => `${e}${i + 1 === email.sentCcToEmails.length ? "" : ", "}`
                                                    )}
                                                </span>
                                            )}
                                            {!!email.sentBccToEmails?.length && (
                                                <span>
                                                    <b>bcc: </b>
                                                    {email.sentBccToEmails.map(
                                                        (e, i) => `${e}${i + 1 === email.sentBccToEmails.length ? "" : ", "}`
                                                    )}
                                                </span>
                                            )}
                                            {email.simulatedOnly && (
                                                <Alert className="alert-danger d-inline-block mt-2 mb-0 p-1">Simulated</Alert>
                                            )}
                                        </td>
                                        <td className="actions">
                                            <StandardButtonWithSpinner
                                                dark
                                                noBorder
                                                className="action-btn"
                                                onClick={async () => {
                                                    await dispatch(
                                                        addModalThunk({
                                                            type: "email",
                                                            title: email.subject,
                                                            body: email.body,
                                                        })
                                                    )
                                                }}
                                            >
                                                Show Email
                                            </StandardButtonWithSpinner>

                                            {resentEmailIds.includes(email.emailId) ? (
                                                <span className="sent-text">{t("sentemails.sent")}</span>
                                            ) : (
                                                <StandardButtonWithSpinner
                                                    dark
                                                    noBorder
                                                    className="action-btn"
                                                    onClick={async () => {
                                                        await dispatch(
                                                            resendSentEmailThunk(email.emailId, userData.user.email || "")
                                                        )
                                                    }}
                                                >
                                                    {t("sentemails.sendtome")}
                                                </StandardButtonWithSpinner>
                                            )}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </SpinnerContainer>
            </PageContainer>
        </PageWrapper>
    )
}

export default SentEmailsPage
