import cn from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "swiipe.portal.shared"
import { ITimeTrackCounterState, getElapsedTimeForTimeTrackInMs } from "../../services/timeTrackService"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import { checkCookieThunk } from "../../store/thunks/loginThunks"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { ensureTrackedTimeThunk } from "../../store/thunks/trackedTimeThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { addHours } from "../../util/dateUtil"
import { padZero } from "../../util/numberUtils"
import "./TrackedTimeCounter.scss"

interface ITrackedTimeCounter {}

const TrackedTimeCounter = ({}: ITrackedTimeCounter) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const [rerender, setRerender] = useState(0)
    const lastRenderTime = useRef<Date | undefined>(undefined)
    const [counterState, setCounterState] = useState<ITimeTrackCounterState | undefined>(undefined)

    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)
    const allRelations = useSelector(userRelationSelectors.userRelationsFlattened)
    const usedRelation = counterState?.organizationId
        ? allRelations?.find((r) => r.id === counterState.organizationId)
        : undefined
    const isStarted =
        counterState?.startTime &&
        (counterState.breaks.length === 0 || counterState.breaks[counterState.breaks.length - 1].type === "start")

    const milliseconds = getElapsedTimeForTimeTrackInMs(counterState)

    const selectedWebshopId = useSelector(merchantSelectors.selectedWebshopId)

    useEffect(() => {
        if (counterState) {
            localStorage.setItem("time-track-counter", JSON.stringify(counterState))
        }
    }, [counterState])

    useEffect(() => {
        dispatch(ensureTrackedTimeThunk(currentUserRelation?.id ?? "", false))
        if (!counterState) {
            const dataStr = localStorage.getItem("time-track-counter")
            if (dataStr) {
                const state: ITimeTrackCounterState = JSON.parse(dataStr)
                // Started within the last 12 hours
                if (state.startTime && new Date(state.startTime) > addHours(new Date(), -12)) {
                    setCounterState(state)
                    setRerender(rerender + 1)
                }
            }
        }
    }, [currentUserRelation])

    useEffect(() => {
        const now = new Date()
        // If rendering took 1030 ms then next render should be 970
        const nextRenderTime = lastRenderTime?.current ? 1000 - (now.getTime() - lastRenderTime?.current.getTime()) - 1000 : 1000
        lastRenderTime.current = now
        setTimeout(
            () => {
                if (isStarted) {
                    setRerender(rerender + 1)
                }
            },
            Math.abs(nextRenderTime) > 1500 || Math.abs(nextRenderTime) < 500 ? 1000 : nextRenderTime
        )
    }, [rerender])

    if (!currentUserRelation) {
        return null
    }

    return (
        <div className="tracked-time-counter">
            <div className="tracked-time-counter__name">{usedRelation?.name ?? currentUserRelation?.name ?? ""}</div>
            <div className="tracked-time-counter__control">
                <div className="tracked-time-counter__control-hours">{padZero(Math.floor(milliseconds / (1000 * 3600)), 2)}</div>
                <div className="tracked-time-counter__control-divider">:</div>
                <div className="tracked-time-counter__control-minutes">
                    {padZero(Math.floor((milliseconds / (1000 * 60)) % 60), 2)}
                </div>
                <div className="tracked-time-counter__control-divider">:</div>
                <div className="tracked-time-counter__control-seconds">{padZero(Math.floor((milliseconds / 1000) % 60), 2)}</div>
                <div className="tracked-time-counter__control-actions">
                    {!isStarted && (
                        <div
                            onClick={() => {
                                if (!counterState?.startTime) {
                                    setCounterState({
                                        organizationId: currentUserRelation?.id ?? "",
                                        startTime: new Date().toISOString(),
                                        breaks: [],
                                    })
                                } else {
                                    setCounterState({
                                        ...counterState,
                                        breaks: [...counterState.breaks, { time: new Date().toISOString(), type: "start" }],
                                    })
                                }
                                setRerender(rerender + 1)
                            }}
                            className="tracked-time-counter__control-play"
                        >
                            <div className="tracked-time-counter__control-play-inner"></div>
                        </div>
                    )}
                    {isStarted && (
                        <div
                            onClick={() => {
                                setCounterState({
                                    ...counterState,
                                    breaks: [...counterState.breaks, { time: new Date().toISOString(), type: "stop" }],
                                })
                            }}
                            className="tracked-time-counter__control-pause"
                        >
                            <div className="tracked-time-counter__control-pause-1"></div>
                            <div className="tracked-time-counter__control-pause-2"></div>
                        </div>
                    )}
                    <div
                        onClick={async () => {
                            if (!counterState) {
                                return
                            }

                            const isLoggedIn = await dispatch(checkCookieThunk())
                            if (!isLoggedIn) {
                                dispatch(
                                    addModalThunk({
                                        type: "info",
                                        title: "Session expired",
                                        text: "Your session has expired. Reload the page, make sure you are logged in, and try again.",
                                    })
                                )
                                return
                            }

                            dispatch(
                                addModalThunk({
                                    type: "trackTime",
                                    organizationId: counterState.organizationId ?? currentUserRelation?.id ?? "",
                                    initWithState: {
                                        ...counterState,
                                        endTime: new Date().toISOString(),
                                    },
                                    selectedWebshopId,
                                })
                            )

                            setCounterState(undefined)
                            localStorage.removeItem("time-track-counter")
                        }}
                        className={cn(
                            "tracked-time-counter__control-stop",
                            counterState?.startTime && "tracked-time-counter__control-stop--show"
                        )}
                    >
                        <div className="tracked-time-counter__control-stop-inner"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrackedTimeCounter
