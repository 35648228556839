import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { EInvoicingMarket, IInvoicingPrice, TCurrency, requestThunk } from "swiipe.portal.shared"
import { mapUserRelationTypeToInvoicingRelationType } from "../../services/invoicingService"
import { mapUserRelationTypeToOrganizationType } from "../../services/userRelationService"
import { IInvoicingOrganization } from "../../type/invoicing/IInvoicingOrganization"
import { StoreState } from "../StoreState"
import { invoicingSelectors } from "../reducers/invoicingReducer"
import { endpoints } from "./../../data/endpoints"
import { EInvoicingRelationType, IInvoicingMerchantConfig } from "./../../type/IInvoicing"
import { invoicingReducerActions } from "./../reducers/invoicingReducer"
import { userRelationSelectors } from "./../reducers/userRelationReducer"

export const setInvoicingMerchantConfigThunk =
    (
        swMerchantId: string,
        merchantConfig: IInvoicingMerchantConfig,
        customPriceIdsToRemove: string[]
    ): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<any>(endpoints.Invoicing.setMerchantConfig, {
                data: {
                    ...merchantConfig,
                    merchantId: swMerchantId,
                    customPriceIdsToRemove,
                },
            })
        )

        await dispatch(getInvoicingMerchantConfigThunk(swMerchantId, true))
    }

export const setEmptyInvoicingMerchantConfigThunk =
    (swMerchantId: string): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        dispatch(
            invoicingReducerActions.setMerchantConfig(swMerchantId, {
                customPrices: [],
                priceOverrides: [],
            })
        )
    }

export const getInvoicingMerchantConfigThunk =
    (swMerchantId: string, force?: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force && invoicingSelectors.merchantConfig(getState(), swMerchantId)) {
            return
        }

        const merchantConfig = await dispatch(
            requestThunk<IInvoicingMerchantConfig>(endpoints.Invoicing.getMerchantConfig, {
                params: {
                    merchantId: swMerchantId,
                    includeCustomPriceHistory: true,
                },
            })
        )
        dispatch(invoicingReducerActions.setMerchantConfig(swMerchantId, merchantConfig))
    }

export const getInvoicingPricesThunk =
    (
        invoicingRelationId: string,
        invoicingRelationType: EInvoicingRelationType,
        force?: boolean
    ): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force && invoicingSelectors.prices(getState(), invoicingRelationId)) {
            return
        }

        if (invoicingRelationType !== "Merchant") {
            return
        }

        const resp = await dispatch(
            requestThunk<{ prices: IInvoicingPrice[]; resolvedPrices: IInvoicingPrice[]; custom: IInvoicingPrice[] }>(
                endpoints.Invoicing.getPrices,
                {
                    params: {
                        invoicingRelationId,
                        invoicingRelationType,
                    },
                }
            )
        )

        dispatch(invoicingReducerActions.setPrices(invoicingRelationId, resp.prices, resp.resolvedPrices, resp.custom))
    }

export const getPublicInvoicingPricesThunk =
    (market: EInvoicingMarket, force?: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force && invoicingSelectors.publicPrices(getState(), market)) {
            return
        }

        const resp = await dispatch(
            requestThunk<{ prices: IInvoicingPrice[]; market?: EInvoicingMarket; currency?: TCurrency }>(
                endpoints.Invoicing.getPublicPrices,
                {
                    params: {
                        market,
                    },
                }
            )
        )

        dispatch(invoicingReducerActions.setPublicPrices(market, resp.prices))
    }

export const fetchInvoicingOrganizationThunk =
    (force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation || currentUserRelation.relationType !== "Merchant") {
            return
        }

        if (!force && invoicingSelectors.invoicingOrganization(getState())) {
            return
        }

        const resp = await dispatch(
            requestThunk<{ organization: IInvoicingOrganization }>(endpoints.Invoicing.getOrganization, {
                params: {
                    isFull: true,
                    organizationId: currentUserRelation.id,
                    organizationType: mapUserRelationTypeToOrganizationType(currentUserRelation.relationType),
                },
            })
        )

        dispatch(invoicingReducerActions.setInvoicingOrganization(currentUserRelation.id, resp.organization))
    }

interface IUpdateInvoicingOrganizationThunk {
    invoiceRequireDraftFlow: boolean
    isNotInvoiceable: boolean
    saleClaimedBy: string
    partnerId: string
    useOldPrices: boolean
}
export const updateInvoicingOrganizationThunk =
    (data: IUpdateInvoicingOrganizationThunk): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation || currentUserRelation.relationType === "User") {
            return
        }

        const invoiceOrganization = invoicingSelectors.invoicingOrganization(getState())
        if (!invoiceOrganization) {
            return
        }
        await dispatch(
            requestThunk<{ organization: IInvoicingOrganization }>(endpoints.Invoicing.patchOrganization, {
                data: {
                    isFull: true,
                    organization: {
                        organizationId: currentUserRelation.id,
                        organizationType: mapUserRelationTypeToOrganizationType(currentUserRelation.relationType),
                        invoiceRequireDraftFlow: data.invoiceRequireDraftFlow,
                        isNotInvoiceable: data.isNotInvoiceable,
                        saleClaimedBy: data.saleClaimedBy,
                        partnerId: data.partnerId,
                        useOldPrices: data.useOldPrices,
                    },
                },
            })
        )

        await dispatch(fetchInvoicingOrganizationThunk(true))
        await dispatch(
            getInvoicingPricesThunk(
                currentUserRelation.id,
                mapUserRelationTypeToInvoicingRelationType(currentUserRelation.relationType),
                true
            )
        )
    }
